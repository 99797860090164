import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { connect } from "react-redux";

import UserRoute from "./userRoute";
import MercahantRoute from "./merchantRoute";

import Landing from "pages/user/landing/Landing";
import Stores from "pages/user/stores/Stores";
import Products from "pages/user/products/Products";

import Dashboard from "pages/merchant/dashboard/Dashboard";
import MerchantProducts from "pages/merchant/products/Products";
import MerchantStores from "pages/merchant/stores/Stores";
import CreateStore from "pages/merchant/createStore/CreateStore";
import CreateProduct from "pages/merchant/createProduct/CreateProduct";
import ViewStore from "pages/merchant/viewStore/ViewStore";
import ViewProduct from "pages/merchant/viewProduct/ViewProduct";
import Profile from "pages/profile/Profile";
//import "../assets/css/app.css";
//import "../assets/css/style.css";

class ThemeOneRoute extends Component {

	componentDidMount(){
		//const script = document.createElement("script");
		//script.src = "/js/app.js";
		//script.async = true;
		//document.body.appendChild(script);
	}

	render() {
		return (
			<BrowserRouter>
						
				<Route
					exact={true}
					path="/business/:id"
					render={(props) => <UserRoute component={Stores} />}
				/>
				

				<Route
					exact={true}
					path="/dashboard"
					render={(props) => <MercahantRoute component={Dashboard} />}
				/>

				<Route
					exact={true}
					path="/merchant-businesses"
					render={(props) => <MercahantRoute component={MerchantStores} />}
				/>

				<Route
					exact={true}
					path="/merchant-business/:businessId"
					render={(props) => <MercahantRoute component={ViewStore} />}
				/>

				<Route
					exact={true}
					path="/create-store"
					render={(props) => <MercahantRoute component={CreateStore} />}
				/>

				<Route
					exact={true}
					path="/edit-store/:uniqueId"
					render={(props) => <MercahantRoute component={CreateStore} />}
				/>

				<Route
					exact={true}
					path="/merchant-products/:businessId"
					render={(props) => <MercahantRoute component={MerchantProducts} />}
				/>

				<Route
					exact={true}
					path="/merchant-product/:businessId/:productId"
					render={(props) => <MercahantRoute component={ViewProduct} />}
				/>

				<Route
					exact={true}
					path="/create-product/:businessId"
					render={(props) => <MercahantRoute component={CreateProduct} />}
				/>

				<Route
					exact={true}
					path="/edit-product/:businessId/:productId"
					render={(props) => <MercahantRoute component={CreateProduct} />}
				/>

				<Route
					exact={true}
					path="/profile"
					render={(props) => <MercahantRoute component={Profile} />}
				/>
			</BrowserRouter>
		);
	}
}

export default connect(null, null)(ThemeOneRoute);
