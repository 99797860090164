import{
    BEFORE_RESEND_EMAIL_VERIFICATION,
    RESEND_EMAIL_VERIFICATION,
    NETWORK_ERROR_RESENDING_EMAIL_VERIFICATION
} from '../types/resend-verification.types';

const initialState = {
    loading: false, 
    status_code: null,
    email: null,
    messages: [],
}

export default function(state = initialState, action){
    switch(action.type){
        case BEFORE_RESEND_EMAIL_VERIFICATION:{
            return Object.assign({}, state, {
                loading: true
            });
        }
        case  RESEND_EMAIL_VERIFICATION:{
            return Object.assign({}, state, {
                loading: false,
                email: action.payload.data.result.email,
                messages: action.payload.data.message,
                status_code: action.payload.status
            });
        }
        case NETWORK_ERROR_RESENDING_EMAIL_VERIFICATION:{
            return Object.assign({}, state, {
                loading: false,
                messages: action.payload.data.message,
                status_code: action.payload.status
            }); 
        }                      
        default:
            return state;           
    }
}
