import {PRODUCT_CATEGORY, BUSINESS_CATEGORY} from "./../types/category.type"

let initialState = {
    productCategory: [],
    businessCategory: []
}

export default function(state = initialState, action){
    switch (action.type){
        case PRODUCT_CATEGORY:
            return {...state, productCategory: action.payload};
        case BUSINESS_CATEGORY:
            return {...state, businessCategory: action.payload};
        default:
            return state;
    }

}