import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import * as Actions from "./actions/createProduct.actions";

import Title from "./components/title";
import FileForm from "./components/fileForm";
import InputForm from "./components/inputForm";
import Loading from "components/loaders/buttonLoader";
import { handleError } from "helpers/error/error";
import UpdateImages from "components/updateImages/updateImages"


class CreateProduct extends Component { 

    state = {
        id:'',
        name : '',
        amount: '',
        quantity: '',
        description:'',
        slot_id: '',
        business_unique_id:'',
        category:'',
        images: []
    }

    componentDidMount(){
        
        let businessId = this.props.match.params.businessId
        this.updateState('business_unique_id', businessId)

        this.props.actions.getBuscategory(businessId)
        this.props.actions.getSlots(businessId)

        let productId = this.props.match.params.productId;
        if(productId !== undefined){
            let product = this.props.actions.getProduct(productId);
            product.then(data=>{
                this.setState({
                    id: data.id,
                    name: data.name,
                    amount: data.amount,
                    quantity: data.quantity,
                    description: data.description,
                    slot_id: data.slot_id,
                    category: data.category_id,
                })
                this.props.actions.setProductImages(data.images, 'product')
            }).catch((error)=>{
                handleError(error);
            })
        }
        
    }

    updateState = (name, value)=>{
        this.setState({[name]: value})
    }

    createProduct(e){
        e.preventDefault();
        let formData = new FormData(e.target);
        formData.append('business_unique_id', this.state.business_unique_id)
        let images = this.state.images
        for(let x = 0; x<images.length; x++){
            formData.append(`images[${x}]`, images[x]);
        }

        if(this.state.id !== ''){
            formData.append('_method', 'PUT');
        }
        this.props.actions.createUpdateProduct(formData, this.state.business_unique_id, this.state.id)   
    }

	render() {
        
        let businessSlots = this.props.slots.businessSlots
        let productCategories = this.props.categories.productCategory;
        let actions = this.props.actions;

		return (
			<>
				<Title businessUniqueId = {this.state.business_unique_id} productId={this.state.id} />

                <form onSubmit={(e)=>this.createProduct(e)}>

                    <div className="grid grid-cols-12 gap-6 mt-5">
                        <div className="intro-y col-span-12 lg:col-span-12">
                            <InputForm slots = {businessSlots} categories ={productCategories} state={this.state} updateState={this.updateState} />

                            {this.state.id !== '' ? 
                                <UpdateImages 
                                    images= {this.props.productImages} 
                                    deleteImage = {actions.deleteProductImage} 
                                    type="product" 
                                    id={this.state.id} 
                                    uploadImage = {actions.uploadProductImages}
                                    loading = {this.props.updateImageLoading}
                                />
                            : 
                                <FileForm name="images" images={this.state.images} updateState={this.updateState} />
                            }

                        </div>
                    </div>

                    <div className="intro-y flex items-center h-10 mt-5">
                
                        <div className="ml-auto flex text-theme-1">
                            {this.props.reducer.loading ?
                                <Loading />
                            :
                                <button className="button text-white bg-theme-1 shadow-md mr-2">
                                   {this.state.id !== '' ? 'Edit ' : 'Create '} Product
                                </button>
                             }
                        </div>
                    </div>

                </form>

			</>
		);
	}
}

const mapStateToProps = (state) => ({ 
    reducer: state.createStoreReducer, 
    categories: state.categoryReducer, 
    slots: state.slotReducer, 
    productImages: state.imageReducer.productImages,
    updateImageLoading: state.imageReducer.loading, 
});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateProduct)); 
