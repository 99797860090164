import React from "react";

export default function description(props) {
	
	return (
		<div className="col-span-12 sm:col-span-6 lg:col-span-6 mt-8 pl-5 pr-5">
										
			<div className="flex">
				<div>
						<div className="text-theme-20 text-lg xl:text-xl font-bold">{props.products.length}</div>
					<div className="text-gray-600">Services</div>
				</div>
				<div className="w-px h-12 border border-r border-dashed border-gray-300 mx-4 xl:mx-6"></div>
				<div>
					<div className="text-gray-600 text-lg xl:text-xl font-medium">{props.business.views_count}</div>
					<div className="text-gray-600">Views</div>
				</div>
			</div>
			
			<div className="flex items-center  border-t border-dashed border-gray-300  mt-5"></div>

			<div className="block font-medium text-base mt-0 pt-2">
				About
			</div> 

			<div className="flex items-center  border-t border-dashed border-gray-300 mt-2"></div>

			<div className="block font-medium text-base mt-0 pt-2">
				<span className="text-theme-20"><i className="fa fa-institution"></i> {props.business.name}</span>
			</div> 

			<div className="text-gray-700 mt-2">
				{props.business.description}
			</div>

			<div className="flex items-center  border-t border-dashed border-gray-300 mt-2"></div>
			
			<div className="block font-medium text-base mt-0 pt-2">
				Contact Details
			</div> 

			<div className="mt-3 mb-5">
				<div className="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-1 box mt-5">
					
					<div className="col-span-12 sm:col-span-6 lg:col-span-6">
						<a href={`/edit-store/${props.business.unique_id}`}>
							<button style={{borderRadius: 0}} className="button mr-2 mb-2 flex w-full items-center justify-center bg-theme-1 text-white"> 
								<i className="mr-2 fa fa-edit"></i> Edit 
							</button> 
						</a>
					</div>

					<div className="col-span-12 sm:col-span-6 lg:col-span-6">
						<button  style={{borderRadius: 0}} className="button mr-2 mb-2 flex w-full items-center justify-center bg-theme-1 text-white">
							<a href={`/delete-store/${props.business.unique_id}`} onClick={()=>window.confirm('Do you wish to proceed')}> 
								<i className="mr-2 fa fa-trash"></i> Delete 
							</a>
						</button> 
					</div>


				</div>			
			</div>

		</div>

	);
}
