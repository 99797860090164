import React from "react";
import { Map, GoogleApiWrapper, Marker, Circle } from 'google-maps-react';


/**
 * 
 * @param {array} props.markers
 */
const GoogleMapMultiple = (props)=>{

    const { markers } = props;
    let {  mapHeightPercent } = props;
    let coordinates = {}

    const radius = 5;
    mapHeightPercent = mapHeightPercent ? mapHeightPercent : '25';

    let lng = 0
    let lat = 0
    if(Array.isArray(markers)){
        markers.map((value)=>{
            lng += value.lng;
            lat += value.lat;
        })
    }
    coordinates.lat = lat/markers.length
    coordinates.lng = lng/markers.length
    
    const center = mapCenter(coordinates);

    const mapStyles = {
		width: '100%',
        height: '100%',
        zIndex: 20,
    };

    const bounds = makeGoogleBounds(coordinates, radius, props.google);

    return(
        <div style={{width: '100%', paddingTop: `${mapHeightPercent}%`, position: 'relative'}} className="intro-y box p-0 mt-2 ">
            <div style={{position: 'absolute', top: '0', left: '0', right: '0', bottom: '0'}}>
                
                <Map
                    google={props.google}
                    
                    style={mapStyles}
                    initialCenter={{
                        lat: center.lat,
                        lng: center.long
                    }}
                    bounds={bounds}
                >
                    {markers.map((value, index)=>{
                        return (<Marker key={`map${index}`} position={{ lat: value.lat, lng: value.lng}} />)
                    }) }

                </Map>
            </div>
        </div>			
    )
}


const radii = (radius) => {
    return radius * 1000;
}

const mapCenter = (coordinates) => {

    if(coordinates){
        if(coordinates.lat !== undefined && coordinates.lng !== undefined){
            return {
                lat: coordinates.lat,
                long: coordinates.lng
            };
        }
    }

    return {
        lat: 6.6018,
        long: 3.3515
    };
}

/**
 * With this function we insert all our boundaries 
 * into the google bounds.extend API to construct
 * the actual map extension object required by 
 * the MAP to render correct zoom and centre.
 * 
 * @param {Object} userLocation 
 * @param {int} radius 
 * @param {Object} google
 */
const makeGoogleBounds = (coordinates, radius, google) => {
    //get all boundaries to consider
    const points = boundries(coordinates, radius);  
    try{
        var bounds = new google.maps.LatLngBounds();
        for (var i = 0; i < points.length; i++) {
            bounds.extend(points[i]);
        }

        return bounds;

    }catch(e){
        console.error('KEN: ERROR CREATING MAP BOUNDRIES', e);
    }
}


/**
 * This function adjusts the boundries 
 * and zoom of the map, based on the 
 * user's location and specified radius.
 * 
 * @param {object} userLocation 
 * @param {int} radius 
 */
const boundries = (coordinates, radius) => {

    let boundries = [];
    if(coordinates == null){
        return boundries;
    }
    const location = {
        lat: coordinates.lat,
        lng: coordinates.lng
    };

    //note 111.321 KM is one degree distance between latitudes

    /* one degree of longitude at this latitude is equals 
     * to the distance below. Longitudes are not equally spaced
     * apart and depends on the current latitude 
     */
    const radians = location.lat * Math.PI/180;
    const cosine = Math.cos(radians);
    const distanceInKM = cosine * 111.321;
    const longitudeDegreeToAddAndSubtract = radius/distanceInKM;

    /* latitudes are equally spaced apart beacuse 
     * they are parallel to each other
    */
    const latitudeDegreesToAddAndSubtract = radius/111.321;

    const westOfUser = {
        lat: location.lat,
        lng: location.lng - longitudeDegreeToAddAndSubtract
    }
    boundries.push(westOfUser);

    const eastOfUser = {
        lat: location.lat,
        lng: location.lng + longitudeDegreeToAddAndSubtract
    }
    boundries.push(eastOfUser);

    const northOfUser = {
        lat: location.lat + latitudeDegreesToAddAndSubtract,
        lng: location.lng
    }
    boundries.push(northOfUser);

    const southOfUser = {
        lat: location.lat - latitudeDegreesToAddAndSubtract,
        lng: location.lng
    }
    boundries.push(southOfUser);

    return boundries;
}

export default GoogleApiWrapper({
	apiKey: 'AIzaSyCpBYUxrE97uVDHHCULEEE6VQObySRIA7I'
  })(GoogleMapMultiple);