import React, { Component } from "react";
import { BrowserRouter, Route} from "react-router-dom";
import { connect } from "react-redux";

import Login from '../pages/theme-four/login/Login';
import Register from '../pages/theme-four/register/Register';
import ForgotPassword from '../pages/theme-four/forgot-password/ForgotPassword';
import PasswordReset from '../pages/theme-four/password-reset/PasswordReset';
import VerifyEmail from '../pages/theme-four/verify-email/VerifyEmail';
import ResendVerification from '../pages/theme-four/resend-verification/ResendVerification';


class ThemeFourRoute extends Component {

	componentDidMount(){
		
	}

	render() {
		return (
			<BrowserRouter>
				<Route exact={true} path="/login" render={(props) => <Login />} />
				<Route exact={true} path="/register" render={(props) => <Register />} />
				<Route exact={true} path="/forgot-password" render={(props) => <ForgotPassword />} />
				<Route exact={true} path="/password-reset" render={(props) => <PasswordReset/>} />
				<Route exact={true} path="/verification/email" render={(props) => <VerifyEmail />} />
				<Route exact={true} path="/verification/email/resend" render={(props) => <ResendVerification />} />
			</BrowserRouter>
		);
	}
}

export default connect(null, null)(ThemeFourRoute);
