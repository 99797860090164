import React, { Component, useImperativeHandle } from 'react';
import {
	geocodeByAddress,
	getLatLng,
} from 'react-places-autocomplete';
import { store } from 'react-notifications-component';
import $ from 'jquery';
import SkyLight from 'react-skylight';
import MediaQuery, { useMediaQuery } from 'react-responsive';

import PopupLogin from '../common/popup-login/PopupLogin';
import PopupRegister from '../common/popup-register/PopupRegister';
import AccountDropdown from '../common/account-dropdown/AccountDropdown';
import { 
    getParameter, 
    onSearchFormUpdated 
} from '../../../helpers/theme-two/search/url-parameters';
import {
    getLocalAddress,
    setLocalAddress,
    clearLocalAddress
} from '../../../helpers/location/LocalAddress';
import { 
    getLocalToken, 
    setLocalToken,
    clearLocalToken
} from '../../../helpers/authentication/token';
import AddressAutoCompleteThemeTwo from '../../../components/googleSearch/addressAutoCompleteThemeTwo';
import { customNotification } from '../../../components/notifications/Notifications';


const CustomSkylight = (props) => {
    const isDesktopOrLaptop = useMediaQuery(
       { minDeviceWidth: 1224 }
    );
    
    const computer = { marginTop: '0', top: '10%' };
    const mobile = {minHeight: '100%', width: '100%', position: 'fixed', top: '0%', left: '0%', marginTop: '0', marginLeft: '0' };
    const modalStyles = isDesktopOrLaptop ? computer : mobile;
   
    return (
        <SkyLight
            dialogStyles={modalStyles}
            closeButtonStyle={{
                cursor: 'pointer',
                position: 'absolute',
                fontSize: '2.1em',
                right: '10px',
                top: '0',
                zIndex: '1'
            }}
            hideOnOverlayClicked 
            ref={props.controlRef}
        >
            {props.children}
        </SkyLight>
    )
  }


class Header extends Component{

    constructor(props){
        super(props);
        const currentAddress = getLocalAddress() !== null ? getLocalAddress().address : '';
		this.state = {
            radius: getParameter('radius', null) == null ? 10 : getParameter('radius', null),
            description: getParameter('description', '') == '' ? '' : getParameter('description', ''),
            address: currentAddress,
            token: getLocalToken(),
            popup: 'login'
        };
        
        //binding these two functions even after ES6 is important, else auto complete wont work.
        this.handleChange = this.handleChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        //this.simpleDialog = React.createRef();
    }


    initializeSearch = (event) => {
        event.preventDefault();
        const { isSearchPage } = this.props;
        const { description, radius } = this.state;

        const payload = [
            { name: 'description', value: description },
            { name: 'radius', value: radius },
        ];

        onSearchFormUpdated(payload, isSearchPage);
    }
    
    switchPopup = (value) => {
        const popup = (['login', 'register', 'fogort-password'].indexOf(value) >= 0 )
        ? value : 'login';

        this.setState({popup});
    }

	handleChange = (e) => {
        e.preventDefault();
		this.setState({[e.target.name]: e.target.value});
    }

	handleChangeAutoComplete = value => {
        this.setState({
            address: value
        });

        //inform the parent component about the address change
        this.onAddressUpdated(getLocalAddress());
    };
    
    radiusOptions = (isMobile) => {
        const distances = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
        const html =(
            <>
                {distances.map(
                    element => (
                    <option value={element}>{isMobile? '': 'Within'} {element} KM</option>
                    )
                )}
            </>
        );
        return html;
    }

    onAddressUpdated = (updatedAddressObject) => {
        const { setHomeComponentState } = this.props;
		this.setState({
			userLocation: updatedAddressObject
        });
        
        //inform Parent COmponents of Header that Address Object has changed
        if(setHomeComponentState){
            setHomeComponentState('userLocation', getLocalAddress());
        }
    }
    
    clearAddress = (event) => {
        event.preventDefault();
        clearLocalAddress();

        const currentAddress = getLocalAddress() !== null ? getLocalAddress().address : '';

        this.setState({
            address: currentAddress
        });
        //inform the parent component about the address change
        this.onAddressUpdated(getLocalAddress());

        customNotification(['Address Cleared Successfully.'], "Request Complete!", "success");
    }


    onLoginSuccessful = (token) => {
        const { setLayoutComponentState } = this.props;
        setLocalToken(token);
        this.setState({token});
        this.simpleDialog.hide();

        setLayoutComponentState('token', token);
    }

    onLogoutSuccessful = () => {
        const { setLayoutComponentState } = this.props;
        clearLocalToken();
        this.setState({token: getLocalToken()});

        setLayoutComponentState('token', getLocalToken());

        customNotification(['You logged out successfully.'], "See you next time.", "success");
    }

    onRegisterSuccessful = (user) => {
        this.setState({popup: 'login'});
    }

	async handleSelect(address) {
		try{
			const results = await geocodeByAddress(address);
			const latLng = await getLatLng(results[0]);
			const formattedAddress = await results[0].formatted_address;
			const { onAddressUpdated } = this.props;
			
			this.setState({ 
				address: formattedAddress 
			});
			
			setLocalAddress(formattedAddress, latLng);
			
			//inform the parent component about the address change
            this.onAddressUpdated(getLocalAddress());
            
            //display notification, todo: abstract to helper function
            customNotification([`To ${formattedAddress} successfully.`], "Address Updated.", "success");

		}catch(e){
			console.error('Error', e);
			//also show notification in error
		}
    };


    render(){

        const { description, radius, address, token, popup } = this.state;
        
        return(
            <header className="header header-10 header-intro-clearance">
   
                <CustomSkylight controlRef={el => this.simpleDialog = el}>
                    {popup == 'login' 
                    ?
                        <PopupLogin onLoginSuccessful={this.onLoginSuccessful} switchPopup={this.switchPopup} />
                    :
                        <PopupRegister onRegisterSuccessful={this.onRegisterSuccessful} switchPopup={this.switchPopup} />
                    }
                </CustomSkylight>

                <div className="header-top">
                    <div className="container pt-1 pb-1">
                        <div className="header-left">
                            <a href="mailto:info@cluster.live"><i className="icon-envelope"></i> info@cluster.live</a>
                        </div>

                        {token === null &&
                            <div className="header-right">
                            
                                <ul className="top-menu">
                                    <li>
                                    
                                        
                                        <a href="#signin-modal">Get an Account</a>
                                    
                                            <ul>
                                                <li className="login">
                                                    <div className="">
                                                        {/* <a onClick={() => this.simpleDialog.show()} style={{cursor: 'pointer'}}><i className="icon-user"></i>Sign in</a> */}
                                                        <a href="/login" style={{cursor: 'pointer', marginRight: '20px'}}>Login</a>
                                                        <a href="/register" style={{cursor: 'pointer'}}>Register</a>
                                                    </div>
                                                </li>
                                            </ul>
                                        
                                        
                                    
                                    </li>
                                </ul>
                                
                            </div>
                        }
                    </div>
                </div>

                <div className="header-middle">
                    <div className="container">
                        <div className="header-left">
                            {/*
                            <button className="mobile-menu-toggler">
                                <span className="sr-only">Toggle mobile menu</span>
                                <i className="icon-bars"></i>
                            </button>
                            */}

                            <a href="/" className="logo">
                                <img src="/themeone/assets/images/logo/logo-black.png" alt="CLuster" width="105" height="25" />
                            </a>
                        </div>

                        <div className="header-center">
                            <div className="header-search header-search-extended header-search-visible header-search-no-radius d-lg-block">
                                <a href="#" className="search-toggle" role="button"><i className="icon-search"></i></a>
                                {getLocalAddress() !== null
                                ?
                                    <form onSubmit={(e) => this.initializeSearch(e)}>
                                        <div className="header-search-wrapper search-wrapper-wide">
                                            <div className="select-custom">
                                                <select id="cat" name="radius" value={radius} onChange={(e) => this.handleChange(e)}>
                                                {this.radiusOptions()}
                                                </select>
                                            </div>
                                            <label for="q" className="sr-only">Search</label>
                                            <input type="search" onChange={(e) => this.handleChange(e)} className="form-control" name="description" value={description} id="q"  placeholder="Search product ..." />
                                            <button className="btn btn-primary" type="submit"><i className="icon-search"></i></button>
                                        </div>
                                    </form>
                                :
                                    <AddressAutoCompleteThemeTwo
                                        containerClassName={`header-search-wrapper search-wrapper-wide`} 
                                        inputClassName={`form-control`}  
                                        address={this.state.address} 
                                        handleChange={this.handleChangeAutoComplete} 
                                        handleSelect={this.handleSelect} 
                                    />
                                } 
                            </div>  
                        </div>
                        

                        <div className="header-right">
                            <div className="header-dropdown-link">

                                {(getLocalAddress() !== null) &&
                                    <div className="dropdown cart-dropdown">
                                        <a style={{cursor: 'pointer'}} className="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static" title={address} aria-label="Manage Address">
                                            <i className="icon-map-marker text-primary"></i>
                                            
                                            <span className="compare-txt">Manage Address</span>
                                        </a>

                                        <div className="dropdown-menu dropdown-menu-right " style={{width: '390px'}}>
                                            <div className="mb-1">
                                                <h4 className="compare-product-title text-primary">Edit Current Address. </h4>
                                            </div>
                                            <AddressAutoCompleteThemeTwo
                                                containerClassName={`header-search-wrapper search-wrapper-wide`} 
                                                inputClassName={`form-control`}  
                                                address={this.state.address} 
                                                handleChange={this.handleChangeAutoComplete} 
                                                handleSelect={this.handleSelect} 
                                            />

                                            <div className="compare-actions">
                                                <button className="btn btn-outline-primary-2" onClick={(e) => this.clearAddress(e)}><span>Clear Address</span><i className="icon-close"></i></button>
                                            </div>
                                        </div>
                                    </div>  
                                } 

                                {token &&
                                    <AccountDropdown onLogoutSuccessful={this.onLogoutSuccessful} />
                                }

                            </div>
                        </div>



                    </div>
                </div>


                {/** Special search bok that appears only on mobile devices. */}
                <div className="mobile-only-search">
                    {getLocalAddress() !== null
                    ?
                        <form onSubmit={(e) => this.initializeSearch(e)} className="mobile-search-clu">    
                            <select id="cat" className="form-control" style={{width:'auto', border: 0}} name="radius" value={radius} onChange={(e) => this.handleChange(e)}>
                                {this.radiusOptions(true)}
                            </select>
                            <label for="mobile-search" className="sr-only">Search</label>
                            <input type="search" style={{border: 0}} onChange={(e) => this.handleChange(e)} className="form-control" name="description"  value={description}  placeholder="Search product ..." />
                            <button className="btn btn-primary" type="submit"><i className="icon-search"></i></button>
                        </form>
                    :
                        <AddressAutoCompleteThemeTwo
                            containerClassName={`header-search-wrapper search-wrapper-wide`} 
                            inputClassName={`form-control mb-0`}  
                            address={this.state.address} 
                            handleChange={this.handleChangeAutoComplete} 
                            handleSelect={this.handleSelect} 
                        />
                    }
                </div>


            </header>

        );
    }

}


export default Header;