import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import * as Actions from "./actions/register.actions";
import FormSection from "./components/formSection";
import Layout from '../common/Layout';
import Jumbotron from '../common/Jumbotron';
import {
	customNotification,
	networkNotification
} from '../../../components/notifications/Notifications';
import { 
    getLocalToken, 
    setLocalToken,
    clearLocalToken
} from '../../../helpers/authentication/token';

class Register extends Component {
	constructor(){
		super();
		this.updateFormState = this.updateFormState.bind(this)
		this.register = this.register.bind(this)
		this.textInput = React.createRef();
	}
	state = {
		firstname: '',
		lastname: '',
		email: '',
		phone:'',
		password: '',
		agreement: false
	}

	updateFormState = (e) => {
		this.setState({[e.target.name] : e.target.value})
	}

	toggleAgreement = (e) => {
		this.setState({
		  agreement: ! this.state.agreement 
		});
	}

	register = async (e) => {
		e.preventDefault();

		const { beforeRegister, register } = this.props.actions;

		if(this.state.agreement){
			await beforeRegister();
			await register(this.state);

			let { status_code, messages, user, token } = this.props.registerReducer;
			networkNotification(messages, status_code);

			if(status_code === 200 && user && token){
				setLocalToken(token);

				//Show redirect count down
				let timeleft = 15;
				let downloadTimer = setInterval(function(){
					this.setState({
						countDown: timeleft
					});	

					if(timeleft <= 0){
						clearInterval(downloadTimer);
						window.location.href = '/account/dashboard';
					} 
					timeleft -= 1;
				}.bind(this), 1000);
			}
		}else{
			alert('You must agree to our privacy policy before continuing.');
		}
	}

	render() {

		const { loading, status_code, user } = this.props.registerReducer;
		const { firstname, lastname, email, phone, password, agreement, countDown } = this.state;

		return (
			<Layout>

				{status_code && user
				?
					<Jumbotron 
						title="Account Creation Successful."
						message={`We are pleased to inform you that your account has been created successfully, You will be automatically redirected to your dashboard in (${countDown}) seconds.  You can immediately go to your dashboard by clicking the button below.`}
						linkTitle="Proceed To Dashboard"
						linkHref="/account/dashboard" 
						displayButton={true}
						buttonClass="success"
					/>
				:
					<div>
						<div className="pt-5 text-center">
							<a href="/">
								<img className="d-block mx-auto mb-4" src="/logo-black.png" alt="" height="45" />
							</a>
							<h1 className="h3 mb-3 font-weight-normal">Register Account</h1>
						</div>

						<div className="row">
							<div className="col-md-12 order-md-1">
								<form className="needs-validation" novalidate>
									<div className="row">
										<div className="col-md-6 mb-3">
											<label for="firstName">First name</label>
											<input type="text" className="form-control" id="firstName" onChange={(e)=> this.updateFormState(e)} name="firstname" placeholder="" value={firstname} disabled={loading} required />
										</div>
										<div className="col-md-6 mb-3">
											<label for="lastName">Last name</label>
											<input type="text" className="form-control" id="lastName" onChange={(e)=> this.updateFormState(e)} name="lastname" placeholder="" value={lastname} disabled={loading} required />
											
										</div>
									</div>

									<div className="row">
										
										<div className="col-md-6 mb-3">
											<label for="email">Email</label>
											<input type="email" className="form-control" id="email" onChange={(e)=> this.updateFormState(e)} name="email"  value={email} disabled={loading} placeholder="you@example.com" />
										</div>

										<div className="col-md-6 mb-3">
											<label for="username">Phone <span className="text-muted">(E.g: +234 801 234 5678)</span></label>
											<PhoneInput
												inputProps={{
													name: 'phone',
													required: true,
													autoFocus: true,
													id: 'phone-input-modified-styles',
													disabled: loading,
													maxlength: 17,
													size: 17
												}}
												isValid={(value, country) => {
													if(value.length > 3 && value.length !== 13){
														return false;
													}else{
														return true;
													}
												  }}
												inputclassName="form-control"
												country={'ng'}
												onlyCountries={['ng']}
												placeholder={`+234 70 123 456 78`}
												value={phone}
												countryCodeEditable={false}
												onChange={(phone)=> this.setState({phone})}
											/>
										</div>
									</div>

									<div className="mb-3">
										<label for="address">Password</label>
										<input type="password" className="form-control" name="password" onChange={(e)=> this.updateFormState(e)}  value={password} placeholder="Password" disabled={loading} required />
									</div>

									<div className="custom-control custom-checkbox">
										<input type="checkbox" className="custom-control-input" id="save-info" checked={agreement} name="agreement" onChange={(e)=> this.toggleAgreement(e)} disabled={loading} required />
										<label className="custom-control-label" for="save-info">I agree to Cluster Software <a target="_blank" href="/privacy-policy">Privacy Policy</a></label>
									</div>

									<hr className="mb-4" />

									<button className="btn btn-danger btn-lg btn-block" type="submit" onClick={(e) => this.register(e)} disabled={loading}>
										{loading?
											<> <i className="fa fa-spinner fa-spin"></i> Registering... </>
										:
											<>Register</>
										}
									</button>

									<div className="text-center">
										<p className="mt-5 mb-3 text-muted">Already have a Cluster.live account? <a href="/login">Login Here</a></p>
									</div>
								</form>
							</div>
						</div>
					</div>
				}
			</Layout>
		);
	}
}

const mapStateToProps = (state) => ({ registerReducer: state.registerReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Register));
