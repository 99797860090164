import React from "react";
import pic200 from "./../images/pic200.jpg";

export default function otherImage() {
	return (
		<a
			href="details2.html"
			data-toggle="modal"
			data-target="#add-item-modal"
			className="intro-y block col-span-12 sm:col-span-4 lg:col-span-3 xxl:col-span-3"
		>
			<div className="box rounded-md p-3 relative zoom-in">
				<div className="flex-none pos-image relative block">
					<div className="pos-image__preview image-fit">
						<img alt="Midone Tailwind HTML Admin Template" src={pic200} />
					</div>
				</div>
			</div>
		</a>
	);
}
