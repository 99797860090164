export function setToken(token){
    sessionStorage.setItem('token', token);
}

export function getToken(){
    if(sessionStorage.getItem('token') ){
        return sessionStorage.getItem('token')
    }else{
        //try getting token
        return null;
    }
}

export function clearToken(){
    sessionStorage.removeItem('token')
}


///////////////////////////////////////////
// Using the Local Storage Mechanisms
///////////////////////////////////////////
export function setLocalToken(token){
    localStorage.setItem('token', token);
}

export function getLocalToken(){
    return localStorage.getItem('token') 
        ? localStorage.getItem('token')
        : null;
}

export function clearLocalToken(){
    localStorage.removeItem('token')
}