import React from "react";
import Slider from "react-slick";
import ClusterLogo from 'assets/images/logo/logoface.png';

export default function(props){
    return(
        <Slider 
            {...{
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1
            }}
        >
            {props.images.length > 0
            ?
                props.images.map(	
                (image, index) => 
                    (<div key={`slider${index}`}>
                        <div style={{width: '100%', paddingTop: '100%', position: 'relative'}}>
                            <div  style={{position: 'absolute', top: '0', left: '0', right: '0', bottom: '0',  backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center', backgroundImage: `url("${image.s3_bucket_url}${image.url}")`, }}>
                            </div>
                        </div>
                    </div>)
                )
            :
                <div>
                    <div style={{width: '100%', paddingTop: '100%', position: 'relative'}}>
                        <div  style={{position: 'absolute', top: '0', left: '0', right: '0', bottom: '0',  backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center', backgroundImage: `url("${ClusterLogo}")`, }}>
                        </div>
                    </div>
                </div>			
            }

        </Slider>

    )
}