import React, { Component } from "react";
import {
	geocodeByAddress,
	getLatLng,
} from 'react-places-autocomplete';
import { store } from 'react-notifications-component';

import {
	setLocalAddress,
	getLocalAddress
} from '../../helpers/location/LocalAddress';
import AddressAutoComplete from "./addressAutoComplete";


class googleSearch extends Component {

	constructor(props){
		super(props);

		const currentAddress = getLocalAddress() !== null ? getLocalAddress().address : '';
		this.state = { 
			address: currentAddress,
			description: '',
			type: '1',
			radius: '1',
			displaySearchForm: true
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
	}

	toggleSearchForm = event => {
		const {displaySearchForm} = this.state;
		this.setState({displaySearchForm: !displaySearchForm});
	}


	handleChange = event => {
		const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
	};

	handleChangeAutoComplete = value => {
        this.setState({
            address: value
        });
	};
	 
	async handleSelect (address) {
		try{
			const results = await geocodeByAddress(address);
			const latLng = await getLatLng(results[0]);
			const formattedAddress = await results[0].formatted_address;
			const { onAddressUpdated } = this.props;
			
			this.setState({ 
				address: formattedAddress 
			});
			
			setLocalAddress(formattedAddress, latLng);
			
			//inform the parent component about the address change
			onAddressUpdated(getLocalAddress());

			//display notification, todo: abstract to helper function
			store.addNotification({
				title: "Success!",
				message: `Address updated to ${formattedAddress} successfully.`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
				  duration: 5000,
				  onScreen: true,
				  showIcon: true
				}
			});

		}catch(e){
			console.error('Error', e);
			//also show notification in error
		}
	};
	
	render(){
		const { worker } = this.props;
		const { displaySearchForm } = this.state;
		return (
			<>
				<div className="intro-y box mt-5 p-3" style={{zIndex: "50"}}>
					
					<div className="p-0" id="inline-form">
						<div className="preview">
							<div className="grid grid-cols-12 gap-1">

								{displaySearchForm &&
								<>
									<AddressAutoComplete 
										containerClassName={`${getLocalAddress() ? 'col-span-12 sm:col-span-12 lg:col-span-2 xxl:col-span-2' : 'col-span-12 sm:col-span-12 lg:col-span-12 xxl:col-span-12' }`} 
										inputClassName={`${getLocalAddress() ? 'input w-full border' : 'input w-full border col-span-12 border-theme-6' }`}  
										address={this.state.address} 
										handleChange={this.handleChangeAutoComplete} 
										handleSelect={this.handleSelect} 
									/>
								
									{getLocalAddress() &&
									
									<>
										<select
											data-placeholder="Select search type"
											className="input w-full border col-span-12 sm:col-span-12 lg:col-span-2 xxl:col-span-2"
											onChange={this.handleChange}
											name="type"
										>
											<option value="1">Products</option>
											<option value="2" >Stores</option>
										</select>

										<select
											data-placeholder="Select search type"
											className="input w-full border col-span-12 sm:col-span-12 lg:col-span-2 xxl:col-span-2"
											onChange={this.handleChange}
											name="radius"
										>
											<option value="1">within 1km</option>
											<option value="2">within 2km</option>
											<option value="3">within 3km</option>
											<option value="4">within 4km</option>
											<option value="5">within 5km</option>
											<option value="10" >within 10Km</option>
											<option value="20" >within 20Km</option>
											<option value="30" >within 30Km</option>
											<option value="40" >within 40Km</option>
											<option value="50" >within 50Km</option>
										</select>

										<input
											type="text"
											className="input w-full border col-span-12 sm:col-span-12 lg:col-span-5 xxl:col-span-5"
											placeholder="Enter product or store name"
											name="description"
											onChange={this.handleChange}
										/>

										<button onClick={() => worker(this.state, true)} className="col-span-12 sm:col-span-12 lg:col-span-1  xxl:col-span-1 button  bg-theme-1 text-white  items-center">
											Search
										</button>

									</>
									}
								</>
								}

								<button onClick={this.toggleSearchForm} className="col-span-12 sm:col-span-12 lg:col-span-0  xxl:col-span-0 button  text-theme-10  items-center lg:hidden md:hidden">
									<i className={displaySearchForm ? `fa fa-chevron-up` : `fa fa-chevron-down` }></i>
								</button>

							</div>
						</div>
						
					</div>
				</div>		
			</>
		
		);
	}	
}

export default googleSearch;
  