import React from "react";
import Breadcrumb from "components/breadcrumb/breadcrumb"

export default function title(props) {
	let title = props.productId !== '' ? 'Edit Product' : 'Create Product';
	let links = props.productId !== '' ? {
		Dashboard: "/dashboard", 
		Products: `/merchant-products/${props.businessUniqueId}`, 
		Product: `/merchant-product/${props.businessUniqueId}/${props.productId}`, 
		[title]:""
		} : 
		{
			Dashboard: "/dashboard", 
			Products: `/merchant-products/${props.businessUniqueId}`, 
			[title]:""
		}
		
	return (
		<>
			<Breadcrumb 
				title = {title}
				links={links} 
			/>
			{/* <div className="intro-y flex items-center mt-8">
				<h2 className="text-lg font-medium mr-auto">
					{props.edit ? 'Edit ' : 'Create '} Product
				</h2>

				<a href={`/merchant-products/${props.businessUniqueId}`} className="ml-auto flex text-theme-1">
					<button className="button text-white bg-theme-1 shadow-md mr-2">
						Products
					</button>
				</a>
			</div> */}
		</>

	);
}
