import React from "react";
import Product from "./product";

export default function products(props) {
	return (
		<div className="col-span-12 lg:col-span-12 xxl:col-span-12">

			<h2 class="intro-y text-lg font-medium mr-auto mt-2">
				Products
			</h2>

			<div className="intro-y grid grid-cols-12 gap-3 sm:gap-6 mt-5">
				{props.products.map(
					(product, index) => (
						<Product key={index} product={product} />
						)
				)}
			</div>
		</div>


	);
}
