import React from "react";
import GoogleMapSingle from "components/googleMap/googleMapSingle";

export default function(props){
    return(
        <div className="col-span-12 sm:col-span-6 lg:col-span-4 mt-0">
							
            <div className="intro-y box p-5 mt-5">
                
                {props.coordinates.lat !== null && props.coordinates.lng !== null
                ?
                    <>					
                        <GoogleMapSingle coordinates={props.coordinates} mapHeightPercent={100} />
                        <div className="mt-4">
                            <div className="flex flex-col lg:flex-row items-center p-0">
                                <div className="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0">
                                    <div className="text-gray-600 text-xs">Need help finding service provider?</div> 
                                </div>

                                <div className="flex mt-4 lg:mt-0">
                                    <a href={`https://www.google.com/maps?saddr=My+Location&daddr=${props.coordinates.lat},${props.coordinates.lng}`} target="_blank" className="button button--sm text-white bg-theme-1 mr-2">Get Directions</a>
                                </div>
                            </div>
                        </div>
                    </>
                :
                    <>					
                        <div className="col-span-12 flex justify-center mt-5 p-20">
                            <i className="fa fa-spin fa-spinner" style={{fontSize: '40px'}}></i>
                        </div>
                        <div className="text-gray-600 text-xs">Initializing maps...</div>
                    </>
                }

            </div>
        </div>
    )
}