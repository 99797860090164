import React from "react";
import ButtonLoader from "components/loaders/buttonLoader"

export default function summaryCard(props) {
	return (
		<div className="col-span-12 sm:col-span-6 xl:col-span-6 intro-y">
			<div className="report-box zoom-in">
				<div className="box p-5">
					<div className="flex">
						<i
							data-feather={props.feather}
							className="report-box__icon text-theme-10"
						></i>
					</div>
					<div className="text-3xl font-bold leading-8 mt-6">
						{props.loading ?
						<ButtonLoader />
						:
						props.number}
					</div>
					<div className="text-base text-gray-600 mt-1">{props.name}</div>
				</div>
			</div>
		</div>
	);
}
