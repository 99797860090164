import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker, Circle, InfoWindow } from 'google-maps-react';
import SkyLight from 'react-skylight';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from './actions/google-map.actions';

import {
    Standard, Silver, Dark, Night, Aubergine, Retro
} from '../../helpers/map/themes';


/**
 * 
 * @param {array} props.markers
 */
class googleMap extends Component{

    constructor (props){
        super(props);

        this.state = {
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
        };
    }

    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
          this.setState({
            showingInfoWindow: false,
            activeMarker: null
          })
        }
    };


    onMarkerClick = async (props, marker, e) => {
        console.log('PROPS', props);
        console.log('MARKER', marker);
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    }


    onMarkerClickDisabled = async (props, marker, e) => {
        /*
        console.log('PROPS', props);
        console.log('MARKER', marker);
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            //showingInfoWindow: true
        });
        */

        this.simpleDialog.show();

        console.log('PROPS', props);
        console.log('MARKER', marker);
        console.log('BUSINESS_ID', props.payload.business_id);
        
        const { beforeFetchQuickView, fetchQuickView} = this.props.actions;

        await beforeFetchQuickView();
        fetchQuickView(props.payload.business_id);
    }
       
    _mapLoaded(mapProps, map) {
        map.setOptions({
            styles: Standard
        })
    }

    render(){
        const { loading, quick_view } = this.props.googleMapReducer;
        const { userLocation, radius } = this.props;
        let { markers, mapHeightPercent } = this.props;

        markers = markers ? markers : [];
        mapHeightPercent = mapHeightPercent ? mapHeightPercent : '25';

        const center = mapCenter(userLocation);

        const userRadius = radii(radius);

        const mapStyles = {
            width: '100%',
            height: '100%',
            zIndex: 20,
        };

        const bounds = makeGoogleBounds(userLocation, radius, this.props.google);
        //<div style={{width: '100%', paddingTop: `${mapHeightPercent}%`, position: 'relative'}} className="intro-y box p-0 mt-5 sm:mt-5">

        return(
            <div style={{width: '100%'}} className="intro-y box p-0 mt-0 sm:mt-5">
                <div style={{position: 'absolute', top: '0', left: '0', right: '0', bottom: '0'}}>
                    
                    <Map
                        google={this.props.google}
                        onClick={this.onMapClicked}
                        style={mapStyles}
                        initialCenter={{
                            lat: center.lat,
                            lng: center.long
                        }}
                        bounds={bounds}
                        onReady={(mapProps, map) => this._mapLoaded(mapProps, map)}
                    >

                        {userLocation &&
                            markers.map(
                                marker => 
                                    (<Marker key={marker.id} 
                                        id={marker.id} 
                                        payload={marker} 
                                        onClick={this.onMarkerClick} 
                                        name={marker.name} 
                                        position={{ lat: marker.lat, lng: marker.long}} 
                                    />)
                            )
                        }

                        {/*        
                        <SkyLight
                            hideOnOverlayClicked 
                            ref={ref => this.simpleDialog = ref}
                        >
                            {loading 
                            ?

                                <div className="p-8 text-center">
                                    <span className="slider-loader"></span> Loading Quick View...
                                </div>
                            :
                                <>
                                {quick_view &&
                                    <div className="row m-3">
                                        <div className="col-md-6">
                                            <img style={{width: '100%'}} src={`https://maps.googleapis.com/maps/api/streetview?location=${quick_view.lat},${quick_view.long}&size=456x456&key=AIzaSyCpBYUxrE97uVDHHCULEEE6VQObySRIA7I`} />
                                        </div>
                                        
                                        <div className="col-md-6">
                                            <div className="product-details">
                                                <h2 className="product-title mb-0" style={{backgroundColor: '#c00'}}>{quick_view.name}</h2> 

                                                <p style={{backgroundColor: '#ffc'}}>About: {quick_view.description}</p> 
                                                <p style={{backgroundColor: '#ff6'}}>Address: {quick_view.address}</p>

                                                <img src={quick_view.source.icon} />

                                                <p>{quick_view.products_count} Items</p>
                                                
                                                <div className="ratings-container">
                                                    <span className="ratings-text"><i className="fa fa-eye" style={{color:'#fcb941', fontWeight: 'bold'}}></i> ( {quick_view.views_count} Views )</span>
                                                </div>
                                                <a className="btn btn-primary" href={`/provider/${quick_view.business_id}`}   style={{borderRadius: 0}}>
                                                    View Full Details
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                }
                                </>
                            }
                        </SkyLight>
                        */}
                        
                        <InfoWindow
                            marker={this.state.activeMarker}
                            visible={this.state.showingInfoWindow}>
                                <div className="m-2">
                                    {this.state.selectedPlace && this.state.selectedPlace.payload &&
                                        <a className="text-primary" href={`/provider/${this.state.selectedPlace.payload.business? this.state.selectedPlace.payload.business.public_url :  this.state.selectedPlace.payload.public_url}`}   style={{borderRadius: 0}}>
                                            {this.state.selectedPlace.payload.business ? this.state.selectedPlace.payload.business.name : this.state.selectedPlace.payload.name}
                                        </a>
                                    }
                                </div>
                        </InfoWindow>
                        

                        {(userLocation && userLocation.lat_lng.lat !== undefined && userLocation.lat_lng.lng !== undefined) &&

                           <Circle
                                radius={userRadius}
                                center={{
                                    lat: center.lat,
                                    lng: center.long
                                }}
                                //onMouseover={() => console.log('mouseover')}
                                //onClick={() => console.log('click')}
                                //onMouseout={() => console.log('mouseout')}
                                strokeColor='transparent'
                                strokeOpacity={0}
                                strokeWeight={5}
                                fillColor='#FF0000'
                                fillOpacity={0.2}
                            />
                            
                        }
                        
                    </Map>
                </div>
            </div>			
        )
    }
}


const radii = (radius) => {
    return radius * 1000;
}

const mapCenter = (userLocation) => {

    if(userLocation && userLocation.lat_lng !== undefined){
        if(userLocation.lat_lng.lat !== undefined && userLocation.lat_lng.lng !== undefined){
            return {
                lat: userLocation.lat_lng.lat,
                long: userLocation.lat_lng.lng
            };
        }
    }

    return {
        lat: 6.6018,
        long: 3.3515
    };
}

/**
 * With this function we insert all our boundaries 
 * into the google bounds.extend API to construct
 * the actual map extension object required by 
 * the MAP to render correct zoom and centre.
 * 
 * @param {Object} userLocation 
 * @param {int} radius 
 * @param {Object} google
 */
const makeGoogleBounds = (userLocation, radius, google) => {
    //get all boundaries to consider
    const points = boundries(userLocation, radius);  
    try{
        var bounds = new google.maps.LatLngBounds();
        for (var i = 0; i < points.length; i++) {
            bounds.extend(points[i]);
        }

        return bounds;

    }catch(e){
        console.error('KEN: ERROR CREATING MAP BOUNDRIES', e);
    }
}


/**
 * This function adjusts the boundries 
 * and zoom of the map, based on the 
 * user's location and specified radius.
 * 
 * @param {object} userLocation 
 * @param {int} radius 
 */
const boundries = (userLocation, radius) => {

    let boundries = [];
    if(userLocation == null){
            return boundries;
    }
    const location = {
        lat: userLocation.lat_lng.lat,
        lng: userLocation.lat_lng.lng
    };

    //note 111.321 KM is one degree distance between latitudes

    /* one degree of longitude at this latitude is equals 
     * to the distance below. Longitudes are not equally spaced
     * apart and depends on the current latitude 
     */
    const radians = location.lat * Math.PI/180;
    const cosine = Math.cos(radians);
    const distanceInKM = cosine * 111.321;
    const longitudeDegreeToAddAndSubtract = radius/distanceInKM;

    /* latitudes are equally spaced apart beacuse 
     * they are parallel to each other
    */
    const latitudeDegreesToAddAndSubtract = radius/111.321;

    const westOfUser = {
        lat: location.lat,
        lng: location.lng - longitudeDegreeToAddAndSubtract
    }
    boundries.push(westOfUser);

    const eastOfUser = {
        lat: location.lat,
        lng: location.lng + longitudeDegreeToAddAndSubtract
    }
    boundries.push(eastOfUser);

    const northOfUser = {
        lat: location.lat + latitudeDegreesToAddAndSubtract,
        lng: location.lng
    }
    boundries.push(northOfUser);

    const southOfUser = {
        lat: location.lat - latitudeDegreesToAddAndSubtract,
        lng: location.lng
    }
    boundries.push(southOfUser);

    return boundries;
}

const mapStateToProps = (state) => ({ googleMapReducer: state.googleMapReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({
	apiKey: 'AIzaSyCpBYUxrE97uVDHHCULEEE6VQObySRIA7I'
  })(googleMap));