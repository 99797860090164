import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import * as Actions from "./actions/verify-email.actions";
import {
	customNotification
} from '../../../components/notifications/Notifications';
import Layout from '../common/Layout';
import Jumbotron from '../common/Jumbotron';


class VerifyEmail extends Component {
	
	componentDidMount = async() => {

		const { actions } = this.props;

		let search = window.location.search;
		let params = new URLSearchParams(search);
		const subject = params.get('subject');

		await actions.beforePerformVerification();
		await actions.performVerification({
			subject: subject
		});

		let { verifyEmailReducer } = this.props;
		let { messages, status_code} = verifyEmailReducer;

		if(status_code === 200){
			customNotification(
				messages, 
				'Completed.', 
				'success'
			);
		}else{
			customNotification(
				[
					'Email could not be verified, invalid verification link.',
					'Please follow instructions below to resolve.'
				], 
				'Something went wrong', 
				'danger'
			);
		}

	}

	flattenMessages = (messages) => {
		const block = (<div>
			{
				messages.map(element => 
					<p>{element}</p> 
				)
			}
		</div>);

		return block;
	}
	
	render() {

		const { verifyEmailReducer } = this.props;
		const { email, messages, status_code, loading} = verifyEmailReducer;

		return (
			<Layout>
					
				{loading 
				?
					<div className="text-center mt-5 py-5">
						<i className="fa fa-spinner fa-spin" style={{fontSize: 30}}></i>
					</div>
				:
					<>
						{status_code === 200
						?
							<Jumbotron 
								title="Verified!"
								message={this.flattenMessages(messages)}
								linkTitle="Proceed to Login"
								linkHref="/login" 
								displayButton={true}
								buttonClass="success"
							/>
						:
							<Jumbotron 
								title="Error!"
								message={`Your E-Mail could not be verified, because the verification link is invalid. Please re-request E-Mail verification by clicking the link below.`}
								linkTitle="Re-request E-Mail Verification"
								linkHref="/verification/email/resend" 
								displayButton={true}
								buttonClass="danger"
							/>
						}
						
					</>
				}

			</Layout>
		);
	}
}

const mapStateToProps = (state) => ({ verifyEmailReducer: state.verifyEmailReducer});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerifyEmail));
