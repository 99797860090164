import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from 'react-helmet';


class ThemeOneWrapper extends Component {

	constructor(){
		super();
		
		const styleLinkApp = document.createElement("link");
		styleLinkApp.href = "/themeone/assets/css/app.css";
		styleLinkApp.rel = "stylesheet";
		styleLinkApp.type = "text/css";
		//styleLinkApp.async = true;
		document.head.appendChild(styleLinkApp);

		const styleLink = document.createElement("link");
		styleLink.href = "/themeone/assets/css/style.css";
		styleLink.rel = "stylesheet";
		styleLink.type = "text/css";
		//styleLink.async = true;
		document.head.appendChild(styleLink);

		const script = document.createElement("script");
		script.src = "/themeone/assets/js/app.js";
		script.async = true;
		document.body.appendChild(script);
	}

	componentDidMount(){

	}

	render() {
		//<Helmet>
		//	<title>Cluster Software</title>
		//	<link rel="stylesheet" type="text/css" href="/themeone/assets/css/app.css" />
		//	<link rel="stylesheet" type="text/css" href="/themeone/assets/css/style.css" />
		//</Helmet>
		
		return (
			<>
				{this.props.children}
			</>
		);
	}
}

export default connect(null, null)(ThemeOneWrapper);
