import React, { Component } from 'react';
import ContentLoader, { Facebook, Instagram } from 'react-content-loader';

import Cluster from '../../../../../assets/images/logo/logoface.png';

class LoadingCollection extends Component{

    render(){

        const items = [1,2,3,4,5,6,7,8,9,10,11,12];
        
        return(
            <>
                <div className="row carousel-equal-height">

                    {items.map(
                        item => (
                        <div className="col-12 col-md-2 col-lg-2">
                            <div className="product text-center">

                                
                                
                                <figure className="product-media" style={{backgroundColor: '#ffffff'}}>
                                    <div className="">
                                        <ContentLoader style={{width: '100%', height:'290px'}}>
                                            <rect x="0" y="0" rx="4" ry="4"  height="200" style={{width: '100%'}} />
                                            <rect x="0" y="210" rx="4" ry="4"  height="10" style={{width: '100%'}} />
                                            <rect x="0" y="230" rx="4" ry="4"  height="10" style={{width: '100%'}} />
                                            <rect x="0" y="250" rx="4" ry="4"  height="10" style={{width: '75%'}} />
                                            <rect x="0" y="270" rx="4" ry="4"  height="10" style={{width: '50%'}} />
                                        </ContentLoader>  
                                    
                                    </div>
                                </figure>


                 
                            </div>  
                        </div>
                        )
                    )} 

                </div>

            </>
        );
    }

}

export default LoadingCollection;