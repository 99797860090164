import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import * as Actions from "./actions/dashboard.actions";

import Title from "./components/title";
import SummaryCard from "./components/summaryCard";
import StoreLocation from "./components/storeLocation";
import Stores from "./components/stores";
import CircularLoader from "components/loaders/circularLoader"

import GoogleMapMultiple from "components/googleMap/googleMapMultiple";

class Dashboard extends Component {

	componentDidMount(){
		this.props.actions.getBusinesses();
		this.props.actions.getDashboardStat();
	}

	render() {
		let reducer = this.props.reducer;
		let cordinates = []
		if(reducer.businesses.length > 0){
			reducer.businesses.map((value)=>{
				cordinates.push({lat: value.lat, lng: value.long})
			})
		}
		
		return (
			<div className="grid grid-cols-12 gap-6">
				<div className="col-span-12 xxl:col-span-9 grid grid-cols-12 gap-6">
					<div className="col-span-12 mt-8">
						<Title />
						<div className="grid grid-cols-12 gap-6 mt-5">
							<SummaryCard 
								name="Businesses" 
								number={
									reducer.dashboardStat.businesses !== undefined ? reducer.dashboardStat.businesses : 0 
								} 
								feather="shopping-cart" 
								loading={reducer.dashboardStatLoading}
							/>

							<SummaryCard 
								name="Products" 
								number={
									reducer.dashboardStat.products !== undefined ? reducer.dashboardStat.products : 0 
								} 
								feather="credit-card" 
								loading={reducer.dashboardStatLoading}
							/>

						</div>

						{/* <div className="col-span-12 grid grid-cols-12 gap-6 mt-8">
							<SummaryCard2 name="Stores" description="2 active" />

							<SummaryCard2 name="Products" description="4 active" />
						</div> */}
					</div>

					{/* <StoreLocation /> */}

					
					{cordinates.length > 0 ?
						<div className="col-span-12 xl:col-span-8 mt-6">
							<div className="intro-y box p-5 mt-12 sm:mt-5">
								<GoogleMapMultiple markers={cordinates} mapHeightPercent={50} />
							</div>
						</div>
					: ''}
							
						
											
					{reducer.businessLoading ?
						<CircularLoader /> 
						:
						<Stores businesses = {this.props.reducer.businesses}/>
					}
					
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({ reducer: state.dashboardReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
