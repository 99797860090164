import React from 'react'

export default function(props){
    return(
        <div className="intro-y box lg:mt-5" style={{display: props.displayPassword}}>
            <div className="flex items-center p-5 border-b border-gray-200">
                <h2 className="font-medium text-base mr-auto">
                    Change password
                </h2>
            </div>
            <div className="p-5">
                <div className="grid grid-cols-12 gap-5">
                    <div className="col-span-12 xl:col-span-12">
                        <form onSubmit={(e)=>props.updatePassword(e)} method="post">
                            <div>
                                <label>Old Password</label>
                                <input 
                                    name="password"
                                    type="password" 
                                    className="input w-full border bg-gray-100 cursor-not-allowed mt-2" 
                                    placeholder="Enter password" 
                                    value={props.passwordInfo.password} 
                                    onChange={(e)=>props.updateState(e, 'password')}
                                />
                                <span className="input-error">{props.passwordInfoError.password}</span>
                            </div>

                            <div className="mt-3">
                                <label>New Password</label>
                                <input 
                                    name="newPassword"
                                    type="password" 
                                    className="input w-full border mt-2" 
                                    placeholder="Enter new password" 
                                    value={props.passwordInfo.newPassword} 
                                    onChange={(e)=>props.updateState(e, 'newPassword')}
                                />
                                 <span className="input-error">{props.passwordInfoError.newPassword}</span>
                            </div>
                        
                            <div className="mt-3">
                                <label>Confirm Password</label>
                                <input 
                                    name="confirmPassword"
                                    type="password" 
                                    className="input w-full border mt-2" 
                                    placeholder="Confirm password" 
                                    value={props.passwordInfo.confirmPassword} 
                                    onChange={(e)=>props.updateState(e, 'password')}
                                />
                                 <span className="input-error">{props.passwordInfoError.confirmPassword}</span>
                            </div>

                            <div className="flex justify-end mt-4">
                                {
                                props.loading ?
                                 <button className="button w-20 bg-theme-1 text-white ml-auto">
                                    <span className="fa fa-spinner fa-spin"></span>
                                </button>:
                                <button type="submit" className="button w-20 bg-theme-1 text-white ml-auto">Save</button>
                                }
                            </div>
                        </form>
                    </div>
                    
                </div>
                
            </div>
        </div>
    )
}