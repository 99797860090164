import axios from 'axios';
import { endpointV2 } from '../../../../../config';

import {
    BEFORE_REGISTER,
    REGISTER,   
    NETWORK_ERROR_REGISTERING
} from '../types/popup-register.types';
import { setLocalToken } from '../../../../../helpers/authentication/token';
import { popAlert } from '../../../../../helpers/alert/popUpAlert';
import { encryptUserData } from '../../../../../helpers/encryption/userEncrypt';


export const beforeRegister = () => (
    { type: BEFORE_REGISTER }
)

export const register = (body) => (
    async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpointV2}user/register`,
                data: body,
                headers: {
                    "content-type": 'application/json',
                    "Accept" : 'application/json',
                },
            });

            dispatch({ type: REGISTER, payload: response });

        }catch(e){
            dispatch({ type: NETWORK_ERROR_REGISTERING, payload: e.response});
        }
    }
)