import axios from "axios";
import {
    BEFORE_FETCH_STORE,
    FETCH_STORE,
    NETWORK_ERROR_FETCHING_STORE
} from "../types/provider.types";
import {
    endpoint
} from "config";


export const beforeFetchStore  = () => (
    { type: BEFORE_FETCH_STORE}
)

export const fetchStore = (data) => (
    async (dispatch) => {
        try{
            const response = await axios({
                method: 'get',
                url: `${endpoint}market/business/${data.id}/items?page=${data.page}`,
                headers: {
                    "content-type": "application/json",
                    "Accept" : "application/json",
                }
            });
            dispatch({ type: FETCH_STORE, payload: response });

        }catch(e){
            dispatch({ type: NETWORK_ERROR_FETCHING_STORE, payload: e.response });
        }
    }
);
