import React from "react";
import logo from "assets/images/logo/logoface.png"

export default function mobileMenu() {
	return (
		<div className="mobile-menu md:hidden">
			
			<div className="mobile-menu-bar">
				<a href="/" className="flex mr-auto">
					<img
						alt="Cluster logo"
						className="w-6 h-6"
						src={logo}
					/>
				</a>
				<a href="#toggle" id="mobile-menu-toggler">
					<i
						data-feather="bar-chart-2"
						className="w-8 h-8 text-white transform -rotate-90"
					></i>
				</a>
			</div>
			<ul className="border-t border-theme-24 py-5 hidden">
				<li>
					<a href="index2.html" className="menu">
						<div className="menu__icon">
							<i data-feather="home"></i>
						</div>
						<div className="menu__title"> Home </div>
					</a>
				</li>
			</ul>
		</div>
	);
}
