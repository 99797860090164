import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Slider from "react-slick";

import * as Actions from "./actions/stores.actions";
import GoogleSearch from "components/googleSearch/googleSearch";
import GoogleMapSingle from "components/googleMap/googleMapSingle";
import ProductCard from '../../../components/commonCards/ProductCard';
import Description from "./components/description";
import Products from "./components/products";
import StoreImages from "./components/storeImages";
import OtherImages from "./components/otherImages";
import ClusterLogo from '../../../assets/images/logo/logoface.png';

class Stores extends Component {
	
	componentDidMount = async() => {
		const id = this.props.match.params.id;
		const { beforeFetchStore, fetchStore } = this.props.actions;

		await beforeFetchStore();
		fetchStore(id)
	}
	
	render() {
					
		let {business, store, products, loading, coordinates} = this.props.storeReducer;

		return (
			<div className="content">

				<div className="grid grid-cols-12 gap-6">
				{loading || !business
				?
					<div className="col-span-12 flex justify-center mt-5 pt-20">
						<i className="fa fa-spin fa-spinner" style={{fontSize: '40px'}}></i>
					</div>
				:
					<div className="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6">

						<div className="col-span-12 lg:col-span-12 mt-8">
							<>
								<a href="http://" className="ml-auto text-theme-1 truncate">Search</a> 
								&nbsp;
								&#8594;
								&nbsp;
								<a href="http://" className="ml-auto text-theme-1 truncate">Product</a>  
								&nbsp;
								&#8594;
								&nbsp;
								<a href="http://" className="ml-auto text-theme-1 truncate">Store</a> 
							</>
						</div>

						<div className="col-span-12 lg:col-span-12">
							<div className="intro-y box px-5 pt-5 mt-5">
								<div className="flex flex-col lg:flex-row border-b border-gray-200 pb-5 -mx-5">
									<div className="flex flex-1 px-5 items-center justify-center lg:justify-start">
										<div className="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative">
											<img alt="Midone Tailwind HTML Admin Template" className="rounded-full" src={ClusterLogo} />
										</div>
										<div className="ml-5">
											<div className="w-full  font-medium text-lg">{business.name}</div>
											<div className="text-gray-600">Service Provider</div>
										</div>
									</div>
									<div className="flex mt-6 lg:mt-0 items-center lg:items-start flex-1 flex-col justify-center text-gray-600 px-5 border-l border-r border-gray-200 border-t lg:border-t-0 pt-5 lg:pt-0">
										<div className="truncate sm:whitespace-normal flex items-center"> <i data-feather="mail" className="w-4 h-4 mr-2 fa fa-facebook"></i> Facebook </div>
										<div className="truncate sm:whitespace-normal flex items-center mt-3"> <i data-feather="instagram" className="w-4 h-4 mr-2 fa fa-instagram"></i> Instagram  </div>
										<div className="truncate sm:whitespace-normal flex items-center mt-3"> <i data-feather="twitter" className="w-4 h-4 mr-2 fa fa-twitter"></i> Twitter </div>
									</div>
									<div className="mt-6 lg:mt-0 flex-1 flex items-center justify-center px-5 border-t lg:border-0 border-gray-200 pt-5 lg:pt-0">
										
										<div className="text-center rounded-md w-20 py-3">
											<div className="font-semibold text-theme-1 text-lg">{business.views_count}</div>
											<div className="text-gray-600">Views</div>
										</div>
										
										<div className="text-center rounded-md w-40 py-3">
											<div className="font-semibold text-theme-1 text-lg">{products.length}</div>
											<div className="text-gray-600">Available Services</div>
										</div>

									</div>
								</div>
								<div className="nav-tabs flex flex-col sm:flex-row justify-center lg:justify-start">
									<a data-toggle="tab" data-target="#profile" href="javascript:;" className={`py-4 sm:mr-8 flex items-center active ${business.active === 1 ? 'text-theme-1' : 'text-gray-600' }`}> <i className="w-4 h-4 mr-2 fa fa-star" data-feather="user"></i> Verified </a>
								</div>
							</div>
						</div>

						<div className="col-span-12 lg:col-span-8 mt-0">
							<div className="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6 box mt-5">

									<div className="col-span-12 sm:col-span-6 lg:col-span-6">
										<div className="intro-y sm:p-0 lg:p-2 pl-10 pr-10 mt-12 sm:mt-5 mb-10">

											<Slider 
												{...{
													dots: true,
													infinite: true,
													speed: 500,
													slidesToShow: 1,
													slidesToScroll: 1
												}}
											>
												{business.images.map(	
												image => 
													(<div>
														<div style={{width: '100%', paddingTop: '100%', position: 'relative'}}>
															<div  style={{position: 'absolute', top: '0', left: '0', right: '0', bottom: '0',  backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center', backgroundImage: `url("${image.s3_bucket_url}${image.url}")`, }}>
															</div>
														</div>
													</div>)
												)}

											</Slider>

										</div>
									</div>


									<div className="col-span-12 sm:col-span-6 lg:col-span-6 mt-8 pl-5 pr-5">

										<div className="block font-medium text-base mt-0 pt-2">
											<span className="text-theme-20"><i className="fa fa-institution"></i> {business.name}</span>
										</div> 

										<div className="text-gray-700 mt-2">
											{business.description}
										</div>

										<div className="flex items-center  border-t border-dashed border-gray-300 mt-2"></div>
										
										<div className="block font-medium text-base mt-0 pt-2">
											Contact Details
										</div> 

										<div className="mt-3 mb-5">
											<div className="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-1 box mt-5">
												
												<div className="col-span-12 sm:col-span-12 lg:col-span-12">
													<button onClick={()=> alert('phone-number')} style={{borderRadius: 0}} className="button mr-2 mb-2 flex w-full items-center justify-center bg-theme-1 text-white"> <i className="mr-2 fa fa-phone"></i> Reveal Telephone </button> 
												</div>

											</div>			
										</div>

									</div>

							</div>
						</div>


						<div className="col-span-12 sm:col-span-6 lg:col-span-4 mt-0">
							
							<div className="intro-y box p-5 mt-5">
								
								{coordinates.lat !== null && coordinates.lng !== null
								?
									<>					
										<GoogleMapSingle coordinates={coordinates} mapHeightPercent={100} />
										<div className="mt-4">
											<div className="flex flex-col lg:flex-row items-center p-0">
												<div className="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0">
													<div className="text-gray-600 text-xs">Need help finding service provider?</div> 
												</div>

												<div className="flex mt-4 lg:mt-0">
													<a href={`https://www.google.com/maps?saddr=My+Location&daddr=${coordinates.lat},${coordinates.lng}`} target="_blank" className="button button--sm text-white bg-theme-1 mr-2">Get Directions</a>
												</div>
											</div>
										</div>
									</>
								:
									<>					
										<div className="col-span-12 flex justify-center mt-5 p-20">
											<i className="fa fa-spin fa-spinner" style={{fontSize: '40px'}}></i>
										</div>
										<div className="text-gray-600 text-xs">Initializing maps...</div>
									</>
								}

							</div>
						</div>

					
						
						<div className="col-span-12 lg:col-span-12 xxl:col-span-12">

							<h2 class="intro-y text-lg font-medium mr-auto mt-2">
								Service Items
							</h2>

							<div className="intro-y grid grid-cols-12 gap-3 sm:gap-6 mt-5">
								{products.map(
									product => (
										<ProductCard product={product} />
									)
								)}
							</div>
						</div>



					</div>
				}
				</div>

			</div>
		);
		
	}
}

const mapStateToProps = (state) => ({ storeReducer: state.storeReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Stores));
