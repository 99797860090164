import React from "react";

export default function storeLocation() {
	return (
		<div className="col-span-12 xl:col-span-8 mt-6">
			<div className="intro-y block sm:flex items-center h-10">
				<h2 className="text-lg font-medium truncate mr-5">My Stores</h2>
				<div className="sm:ml-auto mt-3 sm:mt-0 relative text-gray-700">
					<i
						data-feather="map-pin"
						className="w-4 h-4 z-10 absolute my-auto inset-y-0 ml-3 left-0"
					></i>
					<input
						type="text"
						className="input w-full sm:w-40 box pl-10"
						placeholder="Filter by city"
					/>
				</div>
			</div>
			<div className="intro-y box p-5 mt-12 sm:mt-5">
				<div>2 Official stores, click the marker to see location details.</div>
				<div
					className="report-maps mt-5 bg-gray-200 rounded-md"
					data-center="-6.2425342, 106.8626478"
					data-sources="/dist/json/location.json"
				></div>
			</div>
		</div>
	);
}
