import axios from 'axios';
import {PRODUCT_CATEGORY, BUSINESS_CATEGORY} from '../types/category.type';
import {handleError} from 'helpers/error/error';
import {getToken} from "helpers/authentication/token";
import {popAlert} from "helpers/alert/popUpAlert";

import {endpoint} from 'config';

export const fetchCategory = () => (
    (dispatch) => {
        
            let token = getToken();
            axios({
                method: 'get',
                url: `${endpoint}fetch/categories`,
                headers: {
                    "content-type": "application/json",
                    "Accept" : "application/json",
                    "Authorization": `Bearer ${token}`
                },
            }).then((data)=>{
                let responseData = data.data
                
                if(responseData.status === 'success'){
                    dispatch({type:BUSINESS_CATEGORY, payload:responseData.result})
                }else{
                    handleError(data)
                }
                
            }).catch((error)=>{
                handleError(error)
            })
    }
);

export const fetchSubcategory = (id) => (
    (dispatch) => {
            let token = getToken();
            axios({
                method: 'get',
                url: `${endpoint}fetch/subcategories/${id}`,
                headers: {
                    "content-type": "application/json",
                    "Accept" : "application/json",
                    "Authorization": `Bearer ${token}`
                },
            }).then((data)=>{
                let responseData = data.data
                
                if(responseData.status === 'success'){
                    dispatch({type:PRODUCT_CATEGORY, payload:responseData.result})
                }else{
                    popAlert("danger", "Error", responseData.message)
                }
                
            }).catch((error)=>{
                handleError(error)
            })
    }
);
export const fetchBuscategory = (id) => (
    (dispatch) => {
            let token = getToken();
            axios({
                method: 'get',
                url: `${endpoint}fetch/buscategories/${id}`,
                headers: {
                    "content-type": "application/json",
                    "Accept" : "application/json",
                    "Authorization": `Bearer ${token}`
                },
            }).then((data)=>{
                let responseData = data.data
                
                if(responseData.status === 'success'){
                    dispatch({type:PRODUCT_CATEGORY, payload:responseData.result})
                }else{
                    popAlert("danger", "Error", responseData.message)
                }
                
            }).catch((error)=>{
                handleError(error)
            })
    }
);