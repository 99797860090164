import {
    EDIT_BUSINESS, 
    EDIT_BUSINESS_LOADING,
    EDIT_BUSINESS_ERROR, 
    
    EDIT_BUSINESS_CATEGORY, 
    EDIT_BUSINESS_CATEGORY_LOADING,
    EDIT_BUSINESS_CATEGORY_ERROR,

    EDIT_GET_BUSINESS,
    EDIT_GET_BUSINESS_LOADING,
    EDIT_GET_BUSINESS_ERROR,

    EDIT_BUSINESS_IMAGES,
    EDIT_BUSINESS_ADD_IMAGE_LOADING,
    EDIT_BUSINESS_ADD_IMAGE_ERROR
} from "./../types/edit-business.type"

let initialState = {
    editBusinessCategoryLoading: false,
    editBusinessCategory: [],

    editBusinessLoading: false,
    editBusiness: [],

    editGetBusinessLoading: false,
    editGetBusiness: {},

    editBusinessImages: [],
    editBusinessImageLoading: false
}

export default function(state = initialState, action ){
    let payload = action.payload;
    switch (action.type){
        case EDIT_BUSINESS:
            return {...state, editBusinessLoading: false, editBusiness: payload};
        case EDIT_BUSINESS_LOADING:
            return {...state, editBusinessLoading: true};
        case EDIT_BUSINESS_ERROR:
            return {...state, editBusinessLoading: false};
       
        case EDIT_BUSINESS_CATEGORY:
            return {...state, editBusinessCategory: payload, editBusinessCategoryLoading: false}
        case EDIT_BUSINESS_CATEGORY_LOADING:
            return {...state, editBusinessCategoryLoading: true}
        case EDIT_BUSINESS_CATEGORY_ERROR:
            return {...state, editBusinessCategoryLoading: false}

        case EDIT_GET_BUSINESS:
            return {...state, editGetBusiness: payload, editGetBusinessLoading: false}
        case EDIT_GET_BUSINESS_LOADING:
            return {...state, editGetBusinessLoading: false}
        case EDIT_GET_BUSINESS_ERROR:
            return {...state, editGetBusinessLoading: false}
        
        case EDIT_BUSINESS_IMAGES:
            return {...state, editBusinessImageLoading: false, editBusinessImages: payload}
        case EDIT_BUSINESS_ADD_IMAGE_LOADING:
            return {...state, editBusinessImageLoading: true}  
        case EDIT_BUSINESS_ADD_IMAGE_ERROR:
            return {...state, editBusinessImageLoading: false}      

        default:
            return state
    }
}