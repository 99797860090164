import {clearToken} from "helpers/authentication/token";
import {popAlert} from "helpers/alert/popUpAlert";

export const handleError = (error)=>{
    let errorResponseData;
    let status;
    if(error.response !== undefined){
        errorResponseData = error.response.data 
        status =  error.response.status
    }else if(error.data !== undefined){
        errorResponseData =  error.data; 
        status = error.status
    }else{
        checkMessage(error.message);
        return; 
    }
    checkStatus(status)
    checkMessage(errorResponseData.message)       
}

function checkMessage(message){
    if(message !== undefined){
        popAlert("danger", "Error", message);  
    }       
}

function checkStatus(status){
    if(status === 401){
        clearToken();
        window.location.href = "/login"
    }
}