import {
    BEFORE_LOGIN,
    LOGIN,
    NETWORK_ERROR_LOGGING_IN
} from '../types/popup-login.types';

const initialState = {
    loading: false,
    messages: [],
    status_code: null,
    token: null
}

export default function(state = initialState, action){
    switch(action.type){
        case BEFORE_LOGIN: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case LOGIN: {
            console.log('SUCCESS HERE', action.payload);
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message,
                token: action.payload.data.result.token
            }); 
        }
        case NETWORK_ERROR_LOGGING_IN: {
            console.log('LOG HERE', action.payload);
            return Object.assign({}, state, {
                loading: false,
                messages: action.payload.data.message,
                status_code: action.payload.status,
            });
        }
        default: {
            return state;    
        }     
    }
}