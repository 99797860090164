import React from 'react';
import ContentLoader, { Facebook, Instagram } from 'react-content-loader';

export const ContentLoaderColor = "#eaecf4";

export const TwinBubbles = () => {

    return(
        <div className="col-sm-12">
            <div className="card p-5">
                <div className="d-flex justify-content-center p-5">
                    <div class="spinner-grow text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    &nbsp;
                    <div class="spinner-grow text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    );
}


export const BusinessProductsLoading = () => {

    return(

        <>
            <div className="col-sm-12 mb-3">
                <ContentLoader backgroundColor={ContentLoaderColor} style={{width: '100%', height:'200px'}}>
                    <rect x="0" y="0" rx="4" ry="4"  height="200" style={{width: '100%'}} />
                </ContentLoader>  
            </div>

            {[1,2,3,4,5,6].map(
                block =>
                <div className="col-sm-6 mb-3">
                    <ContentLoader backgroundColor={ContentLoaderColor} style={{width: '100%', height:'200px'}}>
                        <rect x="0" y="0" rx="4" ry="4"  height="150" style={{width: '100%'}} />
                        <rect x="0" y="160" rx="4" ry="4"  height="15" style={{width: '100%'}} />
                        <rect x="0" y="185" rx="4" ry="4"  height="15" style={{width: '90%'}} />
                    </ContentLoader>  
                </div>
            )}

            <div className="col-sm-6 mb-5">
                <ContentLoader backgroundColor={ContentLoaderColor} style={{width: '50%', height:'40px'}}>
                    <rect x="0" y="0" rx="4" ry="4"  height="40" style={{width: '100%'}} />
                </ContentLoader>  
            </div>
        </>

    );
}



export const BusinessesLoading = () => {

    return(

        <>
            {[1,2,3,4,5,6].map(
                block =>
                <div className="col-sm-6 mb-3">
                    <ContentLoader backgroundColor={ContentLoaderColor} style={{width: '100%', height:'170px'}}>
                        <rect x="0" y="0" rx="4" ry="4"  height="120" style={{width: '100%'}} />
                        <rect x="0" y="130" rx="4" ry="4"  height="15" style={{width: '100%'}} />
                        <rect x="0" y="155" rx="4" ry="4"  height="15" style={{width: '90%'}} />
                    </ContentLoader>  
                </div>
            )}

            <div className="col-sm-6 mb-5">
                <ContentLoader backgroundColor={ContentLoaderColor} style={{width: '50%', height:'40px'}}>
                    <rect x="0" y="0" rx="4" ry="4"  height="40" style={{width: '100%'}} />
                </ContentLoader>  
                
            </div>

        </>

    );
}


export const DashboardLoading = () => {

    return(

        <>
            <div className="col-sm-12 mb-3">
                <ContentLoader backgroundColor={ContentLoaderColor} style={{width: '100%', height:'200px'}}>
                    <rect x="0" y="0" rx="4" ry="4"  height="200" style={{width: '100%'}} />
                </ContentLoader>  
            </div>
        </>
    )
}

export const EditProductLoading = () => {

    return(

        <div className="col-sm-12">
            
            <div className="card">
                <div className="card-body">
                    <div className="row">

                        <div className="col-sm-6 mb-3">
                            <ContentLoader backgroundColor={ContentLoaderColor} style={{width: '100%', height:'270px'}}>
                                <rect x="0" y="0" rx="4" ry="4"  height="20" style={{width: '18%'}} />
                                <rect x="0" y="30" rx="4" ry="4"  height="40" style={{width: '100%'}} />

                                <rect x="0" y="100" rx="4" ry="4"  height="20" style={{width: '18%'}} />
                                <rect x="0" y="130" rx="4" ry="4"  height="40" style={{width: '100%'}} />

                                <rect x="0" y="200" rx="4" ry="4"  height="20" style={{width: '18%'}} />
                                <rect x="0" y="230" rx="4" ry="4"  height="40" style={{width: '100%'}} />
                            </ContentLoader>  
                        </div>
                        
                        <div className="col-sm-6 mb-2">
                            <ContentLoader backgroundColor={ContentLoaderColor} style={{width: '100%', height:'270px'}}>
                                <rect x="0" y="0" rx="4" ry="4"  height="20" style={{width: '25%'}} />
                                <rect x="0" y="30" rx="4" ry="4"  height="240" style={{width: '100%'}} />
                            </ContentLoader>  
                        </div>

                        <div className="col-sm-3"></div>
                        <div className="col-sm-3"></div>
                        <div className="col-sm-3"></div>

                        <div className="col-sm-3">
                            <ContentLoader backgroundColor={ContentLoaderColor} style={{width: '100%', height:'45px'}}>
                                <rect x="50%" y="0" rx="4" ry="4"  height="45" style={{width: '50%'}} />
                            </ContentLoader> 
                        </div>

                    </div>
                </div>
            </div>  

        </div>

    );
}




export const ImageThumbnailLoading = () => {
    return(
        <>
            {[1,2,3,4,5,6,7,8].map(
                (block, index) =>
                <div key={`imgloading${index}`} className="col-md-3 mt-4">
                    <ContentLoader backgroundColor={ContentLoaderColor} style={{width: '100%', height:'150px'}}>
                        <rect x="0" y="0" rx="4" ry="4"  height="150" style={{width: '100%'}} />
                        <rect x="-1" y="-1" rx="4" ry="4"  height="28" style={{width: '28px', borderRadius:'50%'}} />
                    </ContentLoader>  
                </div>
            )}
        </>

    );
}



export const SettingsLoading = () => {

    return(

        <div className="col-sm-12">
            
            <div className="card">
                <div className="card-body">
                    <div className="row">

                        <div className="col-sm-12 mb-3">
                            <ContentLoader backgroundColor={ContentLoaderColor} style={{width: '100%', height:'270px'}}>
                                <rect x="0" y="0" rx="4" ry="4"  height="20" style={{width: '11%'}} />
                                <rect x="0" y="30" rx="4" ry="4"  height="40" style={{width: '100%'}} />

                                <rect x="0" y="100" rx="4" ry="4"  height="20" style={{width: '11%'}} />
                                <rect x="0" y="130" rx="4" ry="4"  height="40" style={{width: '100%'}} />

                                <rect x="0" y="200" rx="4" ry="4"  height="20" style={{width: '11%'}} />
                                <rect x="0" y="230" rx="4" ry="4"  height="40" style={{width: '100%'}} />
                            </ContentLoader>  
                        </div>
                        
                        <div className="col-sm-3"></div>
                        <div className="col-sm-3"></div>
                        <div className="col-sm-3"></div>

                        <div className="col-sm-3">
                            <ContentLoader backgroundColor={ContentLoaderColor} style={{width: '100%', height:'45px'}}>
                                <rect x="50%" y="0" rx="4" ry="4"  height="45" style={{width: '50%'}} />
                            </ContentLoader> 
                        </div>

                    </div>
                </div>
            </div>  

        </div>

    );
}