import axios from 'axios';
import { endpointV2 } from '../../../../../config';

import {
    BEFORE_LOGOUT,
    LOGOUT,
    NETWORK_ERROR_LOGGING_OUT
} from '../types/account-dropdown.types';

export const beforeLogout = () => (
    { type: BEFORE_LOGOUT }
)

export const logout = (body) => (
    async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpointV2}user/logout`,
                data: body,
                headers: {
                    "content-type": 'application/json',
                    "Accept" : 'application/json',
                },
            });

            dispatch({ type: LOGOUT, payload: response });

        }catch(e){
            dispatch({ type: NETWORK_ERROR_LOGGING_OUT, payload: e.response});
        }
    }
)