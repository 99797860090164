import React from "react";
import Store from "./store";
import ErrorBlock from "components/error/errorBlock";

export default function stores(props) {
	let stores = props.businesses;
	return (
		<div className="col-span-12 xl:col-span-4 mt-6">
			<div className="intro-y flex items-center h-10">
				<h2 className="text-lg font-medium truncate mr-5">Businesses</h2>
			</div>
			{stores.length > 0 ?
			
				<div className="mt-5">
					{stores.map((value) => {
						return <Store key={value.id} {...value}/>;
					})}

					<a
						href="/merchant-businesses"
						className="intro-y w-full block text-center rounded-md py-4 border border-dotted border-theme-15 text-theme-16"
					>
						View All Businesses
					</a>
				</div>
			:
				<ErrorBlock message="No store found" />
			}
		</div>
	);
}
