import {DASHBOARD_LOADING, DASHBOARD_DATA, DASHBOARD_ERROR, DASHBOARD_GRAPH, DASHBOARD_GRAPH_LOADING, DASHBOARD_GRAPH_ERROR, DASHBOARD_BUSINESSES} from "./../types/dashboard.type"

const initialState = {
    dashboardLoading : false,
    dashboardData : {},
    dashboardErrorMessage : '',
    dashboardGraphLoading: false,
    dashboardGraph: {},
    dashboardGraphTitle: 'Views Per Business',
    dashboardGraphErrorMessage: '',
    dashboardBusinesses : []
}


export default function dashboardReducer(state = initialState, action){
    switch(action.type){
        case DASHBOARD_LOADING:
            return {...state, dashboardLoading: true}
        case DASHBOARD_DATA:
            return {...state, dashboardData: action.payload.result, dashboardLoading: false}
        case DASHBOARD_ERROR:
            return {...state, dashboardErrorMessage: action.payload, dashboardLoading: false}
        case DASHBOARD_GRAPH_LOADING:
            return {...state, dashboardGraphLoading: true}
        case DASHBOARD_GRAPH:
            return {...state, dashboardGraph:action.payload.graphData, dashboardGraphTitle: action.payload.graphTitle, dashboardGraphLoading: false} 
        case DASHBOARD_GRAPH_ERROR:
            return {...state, dashboardGraphErrorMessage:action.payload, dashboardGraphLoading: false} 
        case DASHBOARD_BUSINESSES:
            return {...state, dashboardBusinesses: action.payload}   
        default:
            return state
    }
}