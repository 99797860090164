import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { store } from 'react-notifications-component';
import MediaQuery from 'react-responsive';

import { customNotification } from '../../../../components/notifications/Notifications';
import { getLocalToken, setLocalToken } from '../../../../helpers/authentication/token';

import * as Actions from './actions/popup-login.actions';


class PopupLogin extends Component{

    constructor(props){
        super(props);

        this.state = {
            email: '',
            password: '',
            token: getLocalToken()
        }
    }

    componentDidMount = () => {

    }

    attemptLogin = async (event) => {
        event.preventDefault();
        const { email, password } = this.state;
        const { beforeLogin, login } = this.props.actions;

        await beforeLogin();
        await login({ email, password });

        /* Give feedback to user */
        const { popupLoginReducer, onLoginSuccessful } = this.props;
        const { token, messages } = popupLoginReducer;
        if(token){
            customNotification(messages, "Welcome", "success");
            onLoginSuccessful(token);
            /* Set the token and trigger a state change. */
            //inform the parent component about the change
        }else{
            customNotification(messages, "Just a moment!", "danger");
        }
    }

    handleChange = (event) => {
        event.preventDefault();
        this.setState(
            { [event.target.name]: event.target.value }
        );
    }

    render = () => {

        const { email, password } = this.state;
        const { messages, loading } = this.props.popupLoginReducer;
        const { switchPopup } = this.props;

        return (
                   
            
            <div className="form-tab">
                <ul className="nav nav-pills nav-fill nav-border-anim" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="signin-tab" data-toggle="tab" href="#signin" role="tab" aria-controls="signin" aria-selected="true">Sign In</a>
                    </li>
                    
                </ul>
                <div className="tab-content" id="tab-content-5">
                    <div className="tab-pane fade show active" id="signin" role="tabpanel" aria-labelledby="signin-tab">
                        <form onSubmit={this.attemptLogin}>
                            <div className="form-group">
                                <label for="singin-email">Email address *</label>
                                <input disabled={loading} onChange={this.handleChange} value={email} type="text" className="form-control" id="singin-email" name="email" required />
                            </div>

                            <div className="form-group">
                                <label for="singin-password">Password *</label>
                                <input disabled={loading} onChange={this.handleChange} value={password} type="password" className="form-control" id="singin-password" name="password" required />
                            </div>

                            <div className="form-footer">
                                <button disabled={loading} type="submit" className="btn btn-primary">
                                    { loading && <i className="fa fa-spinner fa-spin"></i> }
                                    <span>LOG IN</span>
                                    <i className="icon-long-arrow-right"></i>
                                </button>

                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="signin-remember" />
                                    <label className="custom-control-label" for="signin-remember">Remember Me</label>
                                </div>

                                <a href="/forgot-password" className="forgot-link">Forgot Your Password?</a>
                            </div>
                        </form>
                        <div className="form-choice">
                            <p className="text-center">or don't you have an account?</p>
                            <div className="row">
                                <div className="col-sm-12">
                                    <button onClick={() => switchPopup('register')} className="btn btn-block btn-outline-primary">
                                        Create new account
                                    </button>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            

        );
    }
}

const mapStateToProps = (state) => ({ popupLoginReducer: state.popupLoginReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PopupLogin);