import React from "react";
import PlacesAutocomplete from "react-places-autocomplete"

export default function googleAddress(props){
    //props must have addresss, handleChange and handleSelect
    const { containerClassName, inputClassName } = props;

    return (
        <PlacesAutocomplete
            value={props.address}
            onChange={props.handleChange}
            onSelect={props.handleSelect}
            
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div className={containerClassName ? containerClassName : "col-span-4"}>
                
                <input
                    {...getInputProps({
                        placeholder: 'Enter your address...',
                        className: inputClassName ? inputClassName: 'input w-full border',
                    })}
                    
                    value={props.address}
                />
                

                <div style={{position: 'fixed', width: '100%', backgroundColor: '#fff', padding:"0"}}>
                    {loading && <i data-loading-icon="oval" className="w-8 h-8"></i>}
                
                    {suggestions.map((suggestion) => {
                        
                        const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                        // inline style for demonstration purpose
                        const style = suggestion.active
                        ? { fontWeight: 'bold', cursor: 'pointer' }
                        : { fontWeight: 'normal', cursor: 'pointer' };
                        
                        return (
                            <div {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                })}
                            >
                                <span>{suggestion.description}</span>
                            </div>
                        );
                    })}
                
                </div>

            </div>
            )}
        </PlacesAutocomplete>
    )
				
}