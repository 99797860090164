import {
    BEFORE_FETCH_DASHBOARD_EDIT_PRODUCT,
    FETCH_DASHBOARD_EDIT_PRODUCT,
    NETWORK_ERROR_FETCHING_DASHBOARD_EDIT_PRODUCT,

    BEFORE_UPDATE_DASHBOARD_PRODUCT,
    UPDATE_DASHBOARD_PRODUCT,
    NETWORK_ERROR_UPDATING_DASHBOARD_PRODUCT,

    BEFORE_UPDATE_DASHBOARD_PRODUCT_IMAGES,
    UPDATE_DASHBOARD_PRODUCT_IMAGES,
    NETWORK_ERROR_UPDATING_DASHBOARD_PRODUCT_IMAGES,
} from '../types/edit-product.types';

const initialState = {
    loading: false,
    loading_update: false,
    loading_image_upload: false,
    product: null,
    images: [],
    status_code: null,
    messages: [],
};

export default function(state = initialState, action){
    switch(action.type){
        case BEFORE_FETCH_DASHBOARD_EDIT_PRODUCT: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case BEFORE_UPDATE_DASHBOARD_PRODUCT: {
            return Object.assign({}, state, {
                loading_update: true
            });
        }
        case FETCH_DASHBOARD_EDIT_PRODUCT: 
        {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message,
                product: action.payload.data.result.product,
                images: action.payload.data.result.product.images
            }); 
        }
        case UPDATE_DASHBOARD_PRODUCT: {
            return Object.assign({}, state, {
                loading_update: false,
                status_code: action.payload.status,
                messages: action.payload.data.message,
                product: action.payload.data.result.product,
                images: action.payload.data.result.product.images
            }); 
        }
        case NETWORK_ERROR_FETCHING_DASHBOARD_EDIT_PRODUCT: {
            return Object.assign({}, state, {
                loading: false,
                messages: action.payload.data.message,
                status_code: action.payload.status,
            });
        }
        case NETWORK_ERROR_UPDATING_DASHBOARD_PRODUCT: {
            return Object.assign({}, state, {
                loading_update: false,
                messages: action.payload.data.message,
                status_code: action.payload.status,
            });
        }
        case BEFORE_UPDATE_DASHBOARD_PRODUCT_IMAGES: {
            return Object.assign({}, state, {
                loading_image_upload: true,
            });
        }
        case UPDATE_DASHBOARD_PRODUCT_IMAGES: {
            return Object.assign({}, state, {
                loading_image_upload: false,
                messages: action.payload.data.message,
                status_code: action.payload.status,
                product: action.payload.data.result.product,
                images: action.payload.data.result.product.images,
            });
        }
        case NETWORK_ERROR_UPDATING_DASHBOARD_PRODUCT_IMAGES: {
            return Object.assign({}, state, {
                loading_image_upload: false,
                messages: action.payload.data.message,
                status_code: action.payload.status,
            });
        }
        default: {
            return state;    
        }     
    }
}