import {
    DASHBOARD_LOADING, 
    DASHBOARD_DATA, 
    DASHBOARD_ERROR, 
    DASHBOARD_GRAPH, 
    DASHBOARD_GRAPH_LOADING, 
    DASHBOARD_GRAPH_ERROR, 
    DASHBOARD_BUSINESSES
} from "./../types/dashboard.type"
import { getRequest } from "helpers/httpRequest/request";


export function getData(){
    return async (dispatch)=>{
        dispatch({type: DASHBOARD_LOADING})
        let data = await getRequest('v1', 'user/fetch/dashboard')
        dispatch({type: DASHBOARD_DATA, payload: data})

        // .then((data)=>{
        //     dispatch({type: DASHBOARD_DATA, payload: data})
        // }).catch((error)=>{
        //     dispatch({type: DASHBOARD_ERROR, payload: error.message})
        // })
    }

}


export function getChartData(business_id){
    return (dispatch)=>{
        dispatch({type: DASHBOARD_GRAPH_LOADING})
        getRequest('v2', `fetch/dashboard/business-views/${business_id}`)
        .then((data)=>{
            dispatch({type: DASHBOARD_GRAPH, payload: {graphData: data.result.graphData, graphTitle: data.result.graphTitle} })
        })
        .catch((error)=>{
            dispatch({type: DASHBOARD_GRAPH_ERROR, payload: error.message})
        })
    }
}

export function getListOfBusinesses(){
    return (dispatch)=>{
        getRequest('v1', 'user/fetch/business')
        .then((data)=>{
            dispatch({type: DASHBOARD_BUSINESSES, payload: data.result})

        }).catch((error)=>{

        })

    }
}