import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import * as Actions from './actions/password-reset.actions';
// import AuthBackgroundSection from '../../components/auth/AuthBackgroundSection';
import {
	networkNotification
} from '../../../components/notifications/Notifications';
import PasswordForm from './components/PasswordForm';
import InvalidPasswordResetLink from './components/InvalidPasswordResetLink';
import Layout from '../common/Layout';
import Jumbotron from '../common/Jumbotron';


class PasswordReset extends Component {
	constructor(){
		super();
		this.state = {
            password : '',
            confirm_password : ''
		}
		
    }
    
    componentDidMount = async() => {
        const { actions } = this.props;

		let search = window.location.search;
		let params = new URLSearchParams(search);
        const subject = params.get('subject');
        const threshold = params.get('threshold');

        await actions.beforeValidateToken();
        await actions.validateToken({subject, threshold});
	}


	handleChange = (e) => {
        e.preventDefault();
		this.setState({[e.target.name]: e.target.value});
    }
    

	performPasswordReset = async (e) => {
        e.preventDefault();

        const { actions } = this.props;
        let { passwordResetReducer } = this.props;
        let { token, reset_email } = passwordResetReducer;
        
        const data = {
            ...this.state,
            subject: token, 
            email: reset_email
        };

        await actions.beforeResetPassword();
        await actions.resetPassword(data);

        this.successNotification();
    }
    
    successNotification = () => {
        let { passwordResetReducer } = this.props;
        let { messages, status_code} = passwordResetReducer;

		if(status_code === 200){
            networkNotification(messages, status_code);
		}else{
			networkNotification(messages, status_code);
		}
    }
	
	render() {
        
        let { passwordResetReducer } = this.props;
        let { status_code, loading, validation_loading, token, reset_email } = passwordResetReducer;
        const { password, confirm_password } = this.state;

		return (
           
            <Layout>
                <div className="text-center py-5">
                    
                    <a href="/">
                        <img className="mb-4" src="/logo-black.png" alt="" height="45" />
                    </a>
                        
                    {validation_loading
                    ?
                        <div className="text-center mt-3">
                            <i className="fa fa-spinner fa-spin" style={{fontSize: 30}}></i>
                        </div>
                    :
                        <>
                            {token
                            ?
                                <PasswordForm password={password} confirm_password={confirm_password} reset_email={reset_email} status_code={status_code} loading={loading} handleChange={this.handleChange} performPasswordReset={this.performPasswordReset} />   
                            :
                                <Jumbotron 
                                    title="Link expired or invalid."
                                    message={`Password reset link has expired or is invalid, please request  a new one.`}
                                    linkTitle="Request a new password reset link."
                                    linkHref="/forgot-password" 
                                    displayButton={true}
                                />
                            }
                        </> 
                    }
                </div>
            </Layout>
           
		);
	}
}

const mapStateToProps = (state) => ({ passwordResetReducer: state.passwordResetReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PasswordReset));
