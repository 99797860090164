import {
    BEFORE_SET_IMAGES_TO_REDUCER,
    SET_IMAGES_TO_REDUCER,
    BEFORE_DELETE_WIDGET_THUMBNAIL_IMAGE,
    DELETE_WIDGET_THUMBNAIL_IMAGE,
    NETWORK_ERROR_DELETING_WIDGET_THUMBNAIL_IMAGE,
} from '../types/image-thumbnails.types';

const initialState = {
    loading_init: false,
    loading: false,
    status_code: null,
    messages: [],
    images: []
};

const addLoaderToObjects = (objects) => {
    objects.forEach(function(object){
        object['loading'] = false;
    });
    return objects;
}

export default function(state = initialState, action){
    switch(action.type){
        case BEFORE_SET_IMAGES_TO_REDUCER:{
            return Object.assign({}, state, {
                loading_init: true
            });
        }
        case SET_IMAGES_TO_REDUCER: {
            return Object.assign({}, state, {
                loading_init: false,
                images: addLoaderToObjects(action.payload.images)
            });
        }
        case BEFORE_DELETE_WIDGET_THUMBNAIL_IMAGE:{
            const index = state.images.findIndex(
                image => image.id === action.payload.image_id);  
            return Object.assign({}, state, {
                images: [
                    ...state.images.slice(0, index),
                    {
                        ...state.images[index],
                        loading: true        
                    },
                    ...state.images.slice(index + 1)
                ]
            }); 
        }
        case DELETE_WIDGET_THUMBNAIL_IMAGE: {
            return Object.assign({}, state, {
                status_code: action.payload.status,
                messages: action.payload.data.message,
                images: addLoaderToObjects(action.payload.data.result.images)
            }); 
        }
        case NETWORK_ERROR_DELETING_WIDGET_THUMBNAIL_IMAGE: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message
            });
        }
        default: {
            return state;    
        }     
    }
}