import React from "react";
import clusterLogo from "./../../assets/images/logo/logoface.png";
import profileImage from "./../../assets/images/profile/profile.jpg";
import {getToken} from "helpers/authentication/token"


export default function topBar() {
	return (
		<div className="border-b border-theme-24 -mt-10 md:-mt-5 -mx-3 sm:-mx-8 px-3 sm:px-8 pt-3 md:pt-0 mb-10">
			<div className="top-bar-boxed flex items-center">
				<a href="/" className="-intro-x hidden md:flex">
					<img
						alt="Cluster logo"
						className="w-6"
						src={clusterLogo}
					/>
					<span className="text-white text-lg ml-3">
						<span className="font-medium">Cluster</span>
					</span>
				</a>
				<div className="-intro-x breadcrumb breadcrumb--light mr-auto">
					<a href="/" className="">
						Home
					</a>
					<i data-feather="chevron-right" className="breadcrumb__icon"></i>
					{getToken() ?
						<a href="/dashboard" className="breadcrumb--active">
							Dashboard
						</a>
					: 
						<>
							<a href="/login" className="breadcrumb--active">
							Login
							</a>

							<i data-feather="chevron-right" className="breadcrumb__icon"></i>

							<a href="/register" className="breadcrumb--active">
							Register
							</a>
						</>
					}
				</div>

				{getToken() ?
				<div className="intro-x dropdown w-8 h-8 relative">
					<div className="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110">
						<img alt="User name" src={profileImage} />
					</div>
					<div className="dropdown-box mt-10 absolute w-56 top-0 right-0 z-20">
						<div className="dropdown-box__content box bg-theme-38 text-white">
							<div className="p-4 border-b border-theme-40">
								<div className="font-medium">Name</div>
								{/* <div className="text-xs text-theme-41">DevOps Engineer</div> */}
							</div>
							<div className="p-2">
								<a
									href="/profile"
									className="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 rounded-md"
								>
									<i data-feather="user" className="w-4 h-4 mr-2"></i> Profile
								</a>
								
								<a
									href="/password-reset"
									className="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 rounded-md"
								>
									<i data-feather="lock" className="w-4 h-4 mr-2"></i> Reset
									Password
								</a>
								
							</div>
							<div className="p-2 border-t border-theme-40">
								<a
									href="/logout"
									className="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 rounded-md"
								>
									{/* <i data-feather="toggle-right" className="w-4 h-4 mr-2"></i> */}
									Logout
								</a>
							</div>
						</div>
					</div>
				</div>
				: ''
				}
			</div>
		</div>
	);
}
