import React from "react";
import Breadcrumb from "components/breadcrumb/breadcrumb"

export default function title() {
	return (
		<>
		<Breadcrumb title="Business" links={{Dashboard: "/dashboard", Busineses: ""}} />
		
		<div className="intro-y flex items-center h-10">
			<a href="/create-store" className="ml-auto flex text-theme-1">
				<button className="button text-white bg-theme-1 shadow-md mr-2">
					Create Business
				</button>
			</a>
		</div>
		</>
	);
}
