import React from "react";

import logoImage from "assets/images/logo/logoface.png";

export default function sideNav() {
	return (
		<nav className="side-nav">
			<a href="/" className="intro-x flex items-center pl-5 pt-4">
				<img
					alt="Midone Tailwind HTML Admin Template"
					className="w-6"
					src={logoImage}
				/>
				<span className="hidden xl:block text-white text-lg ml-3">Cluster</span>
			</a>
			<div className="side-nav__devider my-6"></div>
			<ul>
				<li>
					<a href="/dashboard" className="side-menu side-menu--active">
						<div className="side-menu__icon">
							<i data-feather="home"></i>
						</div>
						<div className="side-menu__title"> Dashboard </div>
					</a>
				</li>
				<li>
					<a href="/merchant-businesses" className="side-menu">
						<div className="side-menu__icon">
							<i data-feather="hard-drive"></i>
						</div>
						<div className="side-menu__title"> Stores </div>
					</a>
				</li>
				
			</ul>
		</nav>
	);
}
