import React, {Component} from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import {DropzoneArea} from 'material-ui-dropzone';
import * as Actions from './actions/create-product.actions';
import { getLocalToken } from '../../../helpers/authentication/token';
import Layout from '../common/Layout';
import {
	customNotification,
	networkNotification
} from '../../../components/notifications/Notifications';

class CreateProduct extends Component{

    constructor(props){
        super(props);

        this.state = {
            name: '',
            amount: '',
            quantity: '',
            description: '',
            files: []
        };
    }

    createItem = async(event) => {
        event.preventDefault();
        const { actions } = this.props;
        const { beforeCreateProduct, createProduct } = actions;
        const businessId = this.props.match.params.business_id;

        let formData = new FormData();

        //Append images to the Formdata
        this.state.files.forEach((element, i) => {
            formData.append('images[]', element)
        });

        const { name, quantity, description, amount } = this.state;

        formData.append('name', name);
        formData.append('quantity', quantity);
        formData.append('description', description);
        formData.append('amount', amount);

        const data = {
            pid: businessId,
            formData: formData
        };

        await beforeCreateProduct();
        await createProduct(getLocalToken(), data);

        let { dashboardCreateProductReducer } = this.props;
        let { status_code, messages, product } = dashboardCreateProductReducer;
        networkNotification(messages, status_code);

        if(status_code == 200){
            setTimeout(function() {
                console.log('Timeout Function Business Id: ',businessId);
                window.location.href = `/account/dashboard/businesses/view/${businessId}`;
            }, 2000);
        }
    }

    handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    handleImageChange = (files) => {
        this.setState({
          files: files
        });
    }
    
    render = () => {

        let { loading, status_code, product } = this.props.dashboardCreateProductReducer;
        const { name, amount, quantity, description } = this.state;

        const businessId = this.props.match.params.business_id;

        return(
            <Layout>

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/account/dashboard">Dashboard</a></li>
                        <li className="breadcrumb-item"><a href="/account/dashboard/businesses">Businesses</a></li>
                        <li className="breadcrumb-item"><a href={`/account/dashboard/businesses/view/${businessId}`}>Go Back</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Create Item</li>
                    </ol>
                </nav>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">


                                <form onSubmit={this.createItem}>
                                    <div className="row">

                                        <div className="col-sm-12 col-md-6">
                                            <div className="form-group">
                                                <label for="exampleFormControlInput1">Name</label>
                                                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" disabled={loading} onChange={this.handleChange} name="name" value={name} />
                                            </div>
                                            <div className="form-group">
                                                <label for="exampleFormControlSelect1">Quantity</label>
                                                <select className="form-control" id="exampleFormControlSelect1" disabled={loading} onChange={this.handleChange} name="quantity" value={quantity}>
                                                    <option>0</option>
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label for="exampleFormControlSelect2">Price</label>
                                                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" disabled={loading} onChange={this.handleChange} name="amount" value={amount} />
                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-6">    
                                            <div className="form-group">
                                                <label for="exampleFormControlTextarea1">Description</label>
                                                <textarea className="form-control" id="exampleFormControlTextarea1" rows="8" disabled={loading} onChange={this.handleChange} name="description" value={description} />
                                            </div>
                                        </div>

                                        <div className="col-md-12 mb-3">    
                                            <DropzoneArea 
                                                onChange={this.handleImageChange.bind(this)}  
                                            />
                                        </div>

                                        <div className="col-sm-12 col-md-12"> 
                                            <button className="btn btn-primary my-2 my-sm-0 pull-right" type="submit" disabled={loading}>
                                                {loading
                                                ?
                                                    <>
                                                        <span className="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                                                        <span className="visually-hidden"> Creating...</span>
                                                    </>
                                                :
                                                    <>
                                                        <i className="fa fa-pen"></i> Create Item 
                                                    </>
                                                }
                                            </button>
                                        </div>
                                    </div>        
                                </form>

                                
                               
                                   
                            </div>
                        </div>
                    </div>
                </div>

                
   
            </Layout>
        );
    }
}

const mapStateToProps = (state) => ({ dashboardCreateProductReducer: state.dashboardCreateProductReducer});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateProduct));