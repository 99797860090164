import React from "react";

export default function productPagination() {
	return (
		<div className="intro-y flex flex-wrap sm:flex-row sm:flex-no-wrap items-center mt-3">
			<ul className="pagination">
				<li>
					<a className="pagination__link" href="#one">
						<i className="w-4 h-4" data-feather="chevrons-left"></i>
					</a>
				</li>
				<li>
					<a className="pagination__link" href="#one">
						<i className="w-4 h-4" data-feather="chevron-left"></i>
					</a>
				</li>
				<li>
					<a className="pagination__link" href="#one">
						...
					</a>
				</li>
				<li>
					<a className="pagination__link" href="#one">
						1
					</a>
				</li>
				<li>
					<a className="pagination__link pagination__link--active" href="#one">
						2
					</a>
				</li>
				<li>
					<a className="pagination__link" href="#one">
						3
					</a>
				</li>
				<li>
					<a className="pagination__link" href="#one">
						...
					</a>
				</li>
				<li>
					<a className="pagination__link" href="#one">
						<i className="w-4 h-4" data-feather="chevron-right"></i>
					</a>
				</li>
				<li>
					<a className="pagination__link" href="#one">
						<i className="w-4 h-4" data-feather="chevrons-right"></i>
					</a>
				</li>
			</ul>
			<select className="w-20 input box mt-3 sm:mt-0">
				<option>10</option>
				<option>25</option>
				<option>35</option>
				<option>50</option>
			</select>
		</div>
	);
}
