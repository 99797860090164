import React from "react";

export default function summaryCards(props) {
	return (
		<div className="col-span-12 sm:col-span-6 xxl:col-span-3 intro-y">
			<div className="mini-report-chart box p-5 zoom-in">
				<div className="flex items-center">
					<div className="w-2/4 flex-none">
						<div className="text-lg font-medium truncate">{props.name}</div>
						<div className="text-gray-600 mt-1">{props.description} </div>
					</div>
				</div>
			</div>
		</div>
	);
}
