import {STARTLOADING, STOPLOADING, PRODUCTS, PRODUCT_DASHBOARD} from "./../types/products.types";
import axios from "axios";
import {endpoint} from "config";
import {getToken} from "helpers/authentication/token";
import {handleError} from "helpers/error/error"


export function getProductDashboard(businessUniqueId){
    return (dispatch) =>{
        let token = getToken();
        axios.get(`${endpoint}user/fetch/product-dashboard/${businessUniqueId}`, {headers:{Authorization: `Bearer ${token}`}})
        .then((data)=>{
            let responseData = data.data;
            if(responseData.status === 'success'){
                dispatch({type: PRODUCT_DASHBOARD, payload: responseData.result});
            }
        }).catch((error)=>{
            handleError(error)
        })
    }
}

export function getProducts(businessUniqueId){
    return (dispatch)=>{
        dispatch({type: STARTLOADING})
        let token = getToken();
        // axios.get(`${endpoint}fetch/products/${businessUniqueId}`, {headers:{Authorization: `Bearer ${token}`}})
        axios.get(`${endpoint}market/business/${businessUniqueId}/items`, {headers:{Authorization: `Bearer ${token}`}})
        .then((data)=>{
            dispatch({type: STOPLOADING})
            let responseData = data.data;
            console.log(responseData)
            if(responseData.status === 'success'){
                dispatch({type: PRODUCTS, payload: responseData.result.products});
            }
        }).catch((error)=>{
            dispatch({type: STOPLOADING})
            handleError(error)
        })
    }
}

export function deleteProduct(businessUniqueId, productId){
    return(dispatch)=>{
        let confirm = window.confirm('Do you wish to continue')
        if(confirm){
            dispatch({type: STARTLOADING})
            let token = getToken();
            axios.get(`${endpoint}delete/product/${productId}`, {headers:{Authorization: `Bearer ${token}`}})
            .then((data)=>{ 
                let responseData = data.data;
                if(responseData.status === 'success'){
                    getProductDashboard(businessUniqueId);
                }
                dispatch({type: STOPLOADING})
            }).catch((error)=>{
                dispatch({type: STOPLOADING})
                handleError(error)
            })
        }

    }
}