import React , { Component } from 'react';
import { 
    getLocalToken, 
    setLocalToken,
    clearLocalToken
} from '../../../helpers/authentication/token';


class Layout extends Component {

    routes = ['/login', '/register', '/forgot-password'];

    constructor(props){
        super(props);

        this.state = {
            shouldRedirect:  this.shouldRedirect()
        }
    }
    

    componentDidMount(){
        this.removeOtherStyles();

        
        if(this.shouldRedirect()){
            window.location.href = '/account/dashboard';
        }
    }

    removeOtherStyles = () => {
        //here we remove all the unwanted styles from the equation
        
        /*
        const themeOneStyles = [1];
		themeOneStyles.forEach(function(style){
			document.getElementById(`theme-one-${style}`).remove();
        })
        */

		const themeTwostyles = [1, 2, 3, 4, 5, 6, 7, 8];
		themeTwostyles.forEach(function(style){
			document.getElementById(`theme-two-${style}`).remove();
        })
        
        const themeThreestyles = [1, 2];
		themeThreestyles.forEach(function(style){
			document.getElementById(`theme-three-${style}`).remove();
        })
    }

    shouldRedirect = () => {
        return getLocalToken() && this.routes.includes(window.location.pathname)
    }


    links = () => {
        const links = [
            {title: 'Login', href: '/login'},
            {title: 'Register', href: '/register'},
            {title: 'Forgot Password', href: '/forgot-password'},
            {title: 'Verification', href: '/verification/email/resend'},
        ];

        return links;
    }

    render = () => {

        const pathName = window.location.pathname;

        return(
            <>
                {!this.state.shouldRedirect &&
                <>
                    <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
                        <a className="navbar-brand" href="/"><img src="/logo192.png" alt="" height="25" /></a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        
                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            
                            <ul className="navbar-nav mr-auto">
                                {
                                    this.links().map(
                                        link => (
                                            <li className={`nav-item ${pathName == link.href ? 'active' : ''}`}>
                                                <a className="nav-link" href={link.href}>{link.title}</a>
                                            </li>  
                                        )
                                    )
                                }
                            </ul>    

                        </div>

                    </nav>

                    <main className="container mt-5">
                        {this.props.children}
                    </main>
                </>
                }
            </>
        );
    }
}

export default Layout;