import axios from "axios";
import {endpoint} from "config";
import {getToken} from "helpers/authentication/token";
import {MPRODUCT} from "./../types/viewProduct.type"

export function getProduct(id){
    return (dispatch)=>{
        let token = getToken();
        axios.get(`${endpoint}user/market/product/${id}`, {headers:{"Authorization": `Bearer ${token}`}})
        .then((response)=>{
            let data = response.data;
            if(data.status === 'success'){
                let cordinates = [];
                let resultData = data.result;
            
                cordinates.push({lat: resultData.lat, lng: resultData.long})
                
                dispatch({type: MPRODUCT, payload: {product:resultData, cordinates: cordinates} })
            }else{
                window.location.href = "/";
            }

        }).catch((error)=>{
            // window.location.href = "/";
        })
    }
    
}