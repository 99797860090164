import axios from "axios"
import {endpoint} from "config";
import {
    USER_BUSINESSES,
    START_BUSINESSES_LOADING,
    STOP_BUSINESSES_LOADING, 
    USER_DASHBOARD_STAT,
    START_DASHBOARD_LOADING,
    STOP_DASHBOARD_LOADING
} from "./../types/dashboard.types";
import {getToken} from "helpers/authentication/token";
import {handleError} from "helpers/error/error"

export function getBusinesses(){
    return (dispatch)=>{
        dispatch({type: START_BUSINESSES_LOADING})
        let token = getToken();
        axios.get(`${endpoint}user/fetch/business`, {headers:{"Authorization": `Bearer ${token}`}})
        .then((data)=>{
            dispatch({type: STOP_BUSINESSES_LOADING})
            let dataResponse = data.data;
            if(dataResponse.status === 'success'){
                dispatch({type: USER_BUSINESSES, payload: dataResponse.result});
            }else{
                handleError(dataResponse)
            }

        }).catch((error)=>{
            dispatch({type: STOP_BUSINESSES_LOADING})
            handleError(error)
        })
    }
}

export function getDashboardStat(){
    return (dispatch)=>{
        dispatch({type: START_DASHBOARD_LOADING})
        let token = getToken();
        axios.get(`${endpoint}user/fetch/dashboard`, {headers:{"Authorization": `Bearer ${token}`}})
        .then((data)=>{
            dispatch({type: STOP_DASHBOARD_LOADING})
            let dataResponse = data.data;
            if(dataResponse.status === 'success'){
                dispatch({type: USER_DASHBOARD_STAT, payload: dataResponse.result});
            }else{
                handleError(dataResponse)
            }
        }).catch((error)=>{
            dispatch({type: STOP_DASHBOARD_LOADING})
            handleError(error)
        })
    }

}
