import React, { Component } from "react";
import { BrowserRouter, Route} from "react-router-dom";
import { connect } from "react-redux";

import Dashboard from '../pages/theme-three/dashboard/Dashboard';
import Businesses from '../pages/theme-three/businesses/Businesses';
import CreateBusiness from '../pages/theme-three/create-business/CreateBusiness';
import ViewBusiness from '../pages/theme-three/view-business/ViewBusiness';
import EditBusiness from '../pages/theme-three/edit-business/EditBusiness';

import CreateProduct from '../pages/theme-three/create-product/CreateProduct';
import EditProduct from '../pages/theme-three/edit-product/EditProduct';
import Settings from '../pages/theme-three/settings/Settings';


class ThemeThreeRoute extends Component {

	componentDidMount(){
		
	}

	render() {
		return (
			<BrowserRouter>

				<Route exact={true} path="/account/dashboard" render={(props) => <Dashboard />} />

				<Route exact={true} path="/account/dashboard/businesses" render={(props) => <Businesses />} />

				<Route exact={true} path="/account/dashboard/business/create" render={(props) => <CreateBusiness />} />

				<Route exact={true} path="/account/dashboard/businesses/view/:business_id" render={(props) => <ViewBusiness />} />

				<Route exact={true} path="/account/dashboard/businesses/edit/:business_id" render={(props) => <EditBusiness {...props} />} />

				<Route exact={true} path="/account/dashboard/businesses/view/:business_id/product/create" render={(props) => <CreateProduct />} />

				<Route exact={true} path="/account/dashboard/businesses/view/:business_id/product/edit/:product_id" render={(props) => <EditProduct />} />

				<Route exact={true} path="/account/dashboard/settings" render={(props) => <Settings />} />

			</BrowserRouter>
		);
	}
}

export default connect(null, null)(ThemeThreeRoute);
