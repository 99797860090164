import axios from "axios";
import {STARTLOADING, STOPLOADING, SHOWALERT, DISMISSALERT} from "./../types/createStore.types";
import {endpoint} from "config"
import {getToken} from "helpers/authentication/token";
import {popAlert} from "helpers/alert/popUpAlert";
import {handleError} from "helpers/error/error";
import {fetchCategory} from "helpers/category/actions/category.action";

import {deleteImage, uploadImage, setImages} from "helpers/images/actions/updateImage.action"

export function dismissAlert(){
    return {type: DISMISSALERT};
}

export function showAlert(status, message){
    return {type: SHOWALERT, payload:{status: status, message: message}};
}

export function startLoading(){
    return {type: STARTLOADING}
}

export function stopLoading(){
    return {type: STOPLOADING}
}

export const getCategory = fetchCategory;

export function createUpdateStore(data, update){
    return (dispatch)=>{
        let token = getToken();
        dispatch(startLoading())

        let url = update !== '' ? `${endpoint}update/business/${update}` : `${endpoint}create/business`;
        let successMessage = update ? 'Business updated successfully' : 'Business created successfully';
        
        axios.post(url, data, {headers:{"Authorization": `Bearer ${token}`}})
        .then((response)=>{

            dispatch(stopLoading())
            let responseData = response.data
            if(responseData.status === 'success'){
                popAlert("success", "Success", successMessage)
                window.location.href = "/merchant-businesses"; 
            }else{
                let message = responseData.message;
                popAlert("danger", "Error", message) 
            }

        }).catch((error)=>{
            dispatch(stopLoading())
            handleError(error)
            
        })
    }
}

export function  getStore(uniqueId){
    return async ()=>{
        try{
            let token = getToken();
            const business = await axios.get(`${endpoint}user/fetch/business/${uniqueId}`, {headers:{"Authorization": `Bearer ${token}`}})
            let businessData = business.data;
            if(businessData.status === 'success'){
                return businessData.result;
            }else{
                handleError(business)
            }
        }catch(error){
            handleError(error)
        }
    }
}

export const deleteBusinessImage = deleteImage;

export const uploadBusinessImages = uploadImage;

export const setBusinessImages = setImages;