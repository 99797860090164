import React from "react";
import Store from "./store";

export default function products(props) {
	return (
		<div className="intro-y box lg:mt-5">
			<div className="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200">
				<h2 className="font-medium text-base mr-auto">Stores</h2>
			</div>
			<div className="p-5">
				<Store product={props.product} />
			</div>
		</div>
	);
}
