import{
    BEFORE_SEND_PASSWORD_RESET_LINK,
    SEND_PASSWORD_RESET_LINK,
    NETWORK_ERROR_SENDING_PASSWORD_RESET_LINK
} from '../types/forgot-password.types';

const initialState = {
    loading: false, 
    status_code: null,
    email: null,
    messages: [],
}

export default function(state = initialState, action){
    switch(action.type){
        case BEFORE_SEND_PASSWORD_RESET_LINK:{
            return Object.assign({}, state, {
                loading: true
            });
        }
        case  SEND_PASSWORD_RESET_LINK:{
            return Object.assign({}, state, {
                loading: false,
                email: action.payload.data.result.email,
                messages: action.payload.data.message,
                status_code: action.payload.status
            });
        }
        case NETWORK_ERROR_SENDING_PASSWORD_RESET_LINK:{
            return Object.assign({}, state, {
                loading: false,
                messages: action.payload.data.message,
                status_code: action.payload.status
            }); 
        }                      
        default:
            return state;           
    }
}
