import React from 'react';

const BlogSection = () => {

    return(
        <div className="blog-posts bg-light pt-4 pb-7">
            <div className="container">
                <h2 className="title">From Our Blog</h2>

                <div className="owl-carousel owl-simple" data-toggle="owl"
                    data-owl-options='{
                                "nav": false,
                                "dots": true,
                                "items": 3,
                                "margin": 20,
                                "loop": false,
                                "responsive": {
                                    "0": {
                                        "items":1
                                    },
                                    "600": {
                                        "items":2
                                    },
                                    "992": {
                                        "items":3
                                    },
                                    "1280": {
                                        "items":4,
                                        "nav": true,
                                        "dots": false
                                    }
                                }
                            }'>
                    <article className="entry">
                        <figure className="entry-media">
                            <a href="single.html">
                                <img src="/themetwo/assets/images/demos/demo-13/blog/post-1.jpg" alt="image desc" />
                            </a>
                        </figure>

                        <div className="entry-body">
                            <div className="entry-meta">
                                <a href="#">Nov 22, 2018</a>, 0 Comments
                            </div>

                            <h3 className="entry-title">
                                <a href="single.html">Sed adipiscing ornare.</a>
                            </h3>

                            <div className="entry-content">
                                <p>Lorem ipsum dolor consectetuer adipiscing elit. Phasellus hendrerit. Pelletesque aliquet nibh ...</p>
                                <a href="single.html" className="read-more">Read More</a>
                            </div>
                        </div>
                    </article>

                    <article className="entry">
                        <figure className="entry-media">
                            <a href="single.html">
                                <img src="/themetwo/assets/images/demos/demo-13/blog/post-2.jpg" alt="image desc" />
                            </a>
                        </figure>

                        <div className="entry-body">
                            <div className="entry-meta">
                                <a href="#">Dec 12, 2018</a>, 0 Comments
                            </div>

                            <h3 className="entry-title">
                                <a href="single.html">Vivamus vestibulum ntulla.</a>
                            </h3>

                            <div className="entry-content">
                                <p>Phasellus hendrerit. Pelletesque aliquet nibh necurna In nisi neque, aliquet vel, dapibus id ... </p>
                                <a href="single.html" className="read-more">Read More</a>
                            </div>
                        </div>
                    </article>

                    <article className="entry">
                        <figure className="entry-media">
                            <a href="single.html">
                                <img src="/themetwo/assets/images/demos/demo-13/blog/post-3.jpg" alt="image desc" />
                            </a>
                        </figure>

                        <div className="entry-body">
                            <div className="entry-meta">
                                <a href="#">Dec 19, 2018</a>, 2 Comments
                            </div>

                            <h3 className="entry-title">
                                <a href="single.html">Praesent placerat risus.</a>
                            </h3>

                            <div className="entry-content">
                                <p>Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc ...</p>
                                <a href="single.html" className="read-more">Read More</a>
                            </div>
                        </div>
                    </article>

                    <article className="entry">
                        <figure className="entry-media">
                            <a href="single.html">
                                <img src="/themetwo/assets/images/demos/demo-13/blog/post-4.jpg" alt="image desc" />
                            </a>
                        </figure>

                        <div className="entry-body">
                            <div className="entry-meta">
                                <a href="#">Dec 19, 2018</a>, 2 Comments
                            </div>

                            <h3 className="entry-title">
                                <a href="single.html">Fusce pellentesque suscipit.</a>
                            </h3>

                            <div className="entry-content">
                                <p>Sed egestas, ante et vulputate volutpat, eros pede semper est, vitae luctus metus libero augue. </p>
                                <a href="single.html" className="read-more">Read More</a>
                            </div>
                        </div>
                    </article>

                    <article className="entry">
                        <figure className="entry-media">
                            <a href="single.html">
                                <img src="/themetwo/assets/images/demos/demo-13/blog/post-1.jpg" alt="image desc" />
                            </a>
                        </figure>

                        <div className="entry-body">
                            <div className="entry-meta">
                                <a href="#">Nov 22, 2018</a>, 0 Comments
                            </div>

                            <h3 className="entry-title">
                                <a href="single.html">Sed adipiscing ornare.</a>
                            </h3>

                            <div className="entry-content">
                                <p>Lorem ipsum dolor consectetuer adipiscing elit. Phasellus hendrerit. Pelletesque aliquet nibh ...</p>
                                <a href="single.html" className="read-more">Read More</a>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </div>

    );
}

export default BlogSection;