import { 
    BEFORE_REGISTER_NEW_USER,
    REGISTER_NEW_USER,
    NETWORK_ERROR_REGISTERING_USER
} from '../types/register.types';

let initialState = {
    loading: false,
    messages: [],
    status_code: null,  
    user: null,
    token: null,
}

export default function(state = initialState, action){
    switch(action.type){
        case BEFORE_REGISTER_NEW_USER: { 
            return Object.assign({}, state, {
                loading: true
            });
        }
        case REGISTER_NEW_USER: {
            return Object.assign({}, state, {
                loading: false,
                messages: action.payload.data.message,
                status_code: action.payload.status,  
                user: action.payload.data.result.user,
                token: action.payload.data.result.token
            });
        }
        case NETWORK_ERROR_REGISTERING_USER: {
            return Object.assign({}, state, {
                loading: false,
                messages: action.payload.data.message,
                status_code: action.payload.status
            }); 
        }  
        default:
            return state;
    }
}