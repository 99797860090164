import React , { Component } from 'react';

import Header from './Header';
import SideNav from './SideNav';
import Footer from './Footer';

import { 
    getLocalToken, 
    setLocalToken,
    clearLocalToken
} from '../../../helpers/authentication/token';


class Layout extends Component {

    constructor(props){
        super(props);

        this.state = {
            shouldRedirect:  this.shouldRedirect()
        }
    }

    componentDidMount(){
        this.removeOtherStyles();

        if(this.shouldRedirect()){
            window.location.href = '/login';
        }
    }

    removeOtherStyles = () => {
        //here we remove all the unwanted styles from the equation
        
        /*
            const themeOneStyles = [1];
            themeOneStyles.forEach(function(style){
                document.getElementById(`theme-one-${style}`).remove();
            })
        */

		const themeTwostyles = [1, 2, 3, 4, 5, 6, 7, 8];
		themeTwostyles.forEach(function(style){
			document.getElementById(`theme-two-${style}`).remove();
        })
        
        const themeFourstyles = [1, 2];
		themeFourstyles.forEach(function(style){
			document.getElementById(`theme-four-${style}`).remove();
        })
    }

    shouldRedirect = () => {
        return !getLocalToken();
    }

    render = () => {
        return(
            
            <>
                {!this.state.shouldRedirect &&
                <div id="wrapper">
            
                    <SideNav />

                    <div id="content-wrapper" className="d-flex flex-column">
            
                        <div id="content">
            
                            <Header />

                            <div className="container-fluid">

                                {this.props.children}
            
                            </div>

                        </div>

                        <Footer />

                    </div>
                </div>
                }
            </>
        );
    }
}

export default Layout;