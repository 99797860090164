export const BEFORE_FETCH_DASHBOARD_BUSINESS_PRODUCTS = 'BEFORE_FETCH_DASHBOARD_BUSINESS_PRODUCTS';
export const FETCH_DASHBOARD_BUSINESS_PRODUCTS = 'FETCH_DASHBOARD_BUSINESS_PRODUCTS';
export const NETWORK_ERROR_FETCHING_DASHBOARD_BUSINESS_PRODUCTS = 'NETWORK_ERROR_FETCHING_DASHBOARD_BUSINESS_PRODUCTS';


export const BEFORE_DELETE_BUSINESS_PRODUCT = 'BEFORE_DELETE_BUSINESS_PRODUCT';
export const DELETE_BUSINESS_PRODUCT = 'DELETE_BUSINESS_PRODUCT';
export const NETWORK_ERROR_DELETING_BUSINESS_PRODUCT = 'NETWORK_ERROR_DELETING_BUSINESS_PRODUCT';

export const DELETE_BUSINESS_LOADING = 'DELETE_BUSINESS_LOADING'
export const DELETE_BUSINESS = 'DELETE_BUSINESS'
export const DELETE_BUSINESS_ERROR = 'DELETE_BUSINESS_ERROR'

