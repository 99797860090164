import axios from 'axios';
import {BUSINESS_SLOT} from '../types/slot.type';
import {handleError} from 'helpers/error/error';
import {getToken} from "helpers/authentication/token";
import {popAlert} from "helpers/alert/popUpAlert";

import {endpoint} from 'config';

export const fetchSlots = (businessId) => (
    (dispatch) => {
        let token = getToken();
        axios({
            method: 'get',
            url: `${endpoint}business/slots/${businessId}`,
            headers: {
                "content-type": "application/json",
                "Accept" : "application/json",
                "Authorization": `Bearer ${token}`
            },
        }).then((data)=>{
            let responseData = data.data
            if(responseData.status === 'success'){
                dispatch({type:BUSINESS_SLOT, payload:responseData.result})
            }else{
                popAlert("danger", "Error", responseData.message)
            }
            
        }).catch((error)=>{
            handleError(error)
        })
    }
);

