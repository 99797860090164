import React from "react";
import defaultImage from "assets/images/profile/profile.jpg";

export default function stores(props) {
	let image = props.images.length > 0 ? props.images[0].s3_bucket_url+props.images[0].url : defaultImage
	return (
		<div className="intro-y">
			<div className="box px-4 py-4 mb-3 flex items-center zoom-in">
				<div className="w-10 h-10 flex-none image-fit rounded-md overflow-hidden">
					<img alt={props.name} src={image} />
				</div>
				<div className="ml-4 mr-auto">
					<a href={`/merchant-business/${props.unique_id}`}>
						<div className="font-medium">{props.name}</div>
					</a>

					<div className="text-gray-600 text-xs">{props.created_at}</div>
				</div>
			</div>
		</div>
	);
}
