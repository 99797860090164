import React from "react";
import PlacesAutocomplete from "react-places-autocomplete"

export default function googleAddress(props){
    //props must have addresss, handleChange and handleSelect
    return (
        <PlacesAutocomplete
            value={props.address}
            onChange={(address)=>props.handleChange("address", address)}
            onSelect={(address)=>props.handleSelect(address, props)}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div className="sm:mr-auto sm:mt-0 relative text-gray-700 ">
                
                <input
                {...getInputProps({
                    placeholder: 'Enter your address ...',
                    className: 'input w-full sm:w-80 box pl-2',
                })}
                name="address"
                value={props.address}
                />
                <div style={{position: 'fixed', width: '100%', backgroundColor: '#fff', padding:"0"}}>
                {loading && <i data-loading-icon="oval" className="w-8 h-8"></i>}
                
                {suggestions.map((suggestion) => {
                    
                    const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                    // inline style for demonstration purpose
                    const style = suggestion.active
                    ? { fontWeight: 'bold', cursor: 'pointer' }
                    : { fontWeight: 'normal', cursor: 'pointer' };
                    
                    return (
                        <div {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                            })}
                        >
                            <span>{suggestion.description}</span>
                        </div>
                    );
                })}
                
                </div>
            </div>
            )}
        </PlacesAutocomplete>
    )
				
}