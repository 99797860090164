import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import * as Actions from "./actions/createStore.actions";
import Title from "./components/title";
import FileForm from "./components/fileForm";
import InputForm from "./components/inputForm";
import Loading from "components/loaders/buttonLoader";
import UpdateImages from "components/updateImages/updateImages"


class CreateStores extends Component {
    
    state = {
        id: '',
        uniqueId: '',
        name: '',
        user_id: '',
        lat: '', 
        long: '', 
        description:'',
        address:'',
        doc1: '', 
        category_id: '', location_type: '', device_id:'',
        images: [], 
        loading: false
    }

    componentDidMount(){
        this.props.actions.getCategory()
        if(this.props.match.params.uniqueId !== undefined){
            let uniqueId = this.props.match.params.uniqueId;
            const businessData = this.props.actions.getStore(uniqueId)
            businessData.then((data)=>{
                this.props.actions.setBusinessImages(data.images, 'business')
                this.setState({
                    id: data.id,
                    uniqueId: data.unique_id,
                    name: data.name, 
                    long: data.long, 
                    lat: data.lat,
                    description:data.description,
                    category_id: data.category_id,
                    address: data.address === null ? '' : data.address
                })
            })
        }
    }

    updateState = (name, value)=>{
        this.setState({[name]: value})
    }

    createStore(e){
        e.preventDefault();
        let formData = new FormData(e.target);
        let images = this.state.images
        for(let x = 0; x<images.length; x++){
            formData.append(`images[${x}]`, images[x]);
        }

        if(this.state.uniqueId !== ''){
            formData.append('_method', 'PUT');
        }
        this.props.actions.createUpdateStore(formData, this.state.uniqueId)
          
    }

	render() {
        let reducer = this.props.createStoreReducer;
        let actions = this.props.actions;
        return (
			<>
				<Title id={this.state.id} />

                <form onSubmit={(e)=>this.createStore(e)}>
                    <div className="grid grid-cols-12 gap-6 mt-5">
                        <div className="intro-y col-span-12 lg:col-span-12">
                            <InputForm categories ={this.props.categories.businessCategory} state={this.state} updateState={this.updateState}/>

                            {this.state.id !== '' ? 
                                <UpdateImages 
                                    images= {this.props.businessImages} 
                                    deleteImage = {actions.deleteBusinessImage} 
                                    type="business" 
                                    id={this.state.id} 
                                    uploadImage = {actions.uploadBusinessImages}
                                    loading = {this.props.updateImageLoading}
                                />
                            : 
                                <FileForm name="images" images={this.state.images} updateState={this.updateState} />
                            }

                        </div>
                    </div>

                    <div className="intro-y flex items-center h-10 mt-5">
                
                        <div className="ml-auto flex text-theme-1">
                            {reducer.loading ?
                                <Loading />
                            :
                                <button type="submit" className="button text-white bg-theme-1 shadow-md mr-2">
                                    Create Store
                                </button>
                            }
                        </div>
                    </div>
                </form>

			</>
		);
	}
}

const mapStateToProps = (state) => ({ 
    createStoreReducer: state.createStoreReducer, 
    categories: state.categoryReducer, 
    businessImages: state.imageReducer.businessImages,
    updateImageLoading: state.imageReducer.loading, 
});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateStores));
