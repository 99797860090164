import {STARTLOADING, STOPLOADING, PRODUCTS, PRODUCT_DASHBOARD} from "./../types/products.types";

const initialState = {
    loading:false,
    products: [],  
    totalProducts: 0,
    totalProductViews: 0 
}

export default function(state = initialState, action){
    switch (action.type){
        case STARTLOADING:
            return {...state, loading: true};
        case STOPLOADING:
            return {...state, loading: false};
        case PRODUCTS:
            return {...state, products: action.payload}
        case PRODUCT_DASHBOARD:
            return {...state, ...action.payload}
        default:
            return state;
    }
}