import {
    BEFORE_ITEMS_SEARCH,
    SEARCH_ITEMS,
    NETWORK_ERROR_SEARCHING_ITEMS
} from '../types/home.types';

const initialState = {
    loading: false,
    items: [],
    markers: [],
    route: 'product',
    messages: [],
    status_code: null,
    pagination: null
};

export default function (state = initialState, action){
    switch(action.type){
        case BEFORE_ITEMS_SEARCH: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case SEARCH_ITEMS: {
            return Object.assign({}, state, {
                loading: false,
                items: action.payload.data.result.items.data,
                route: action.payload.data.result.route,
                markers: action.payload.data.result.markers,
                status_code: action.payload.status,
                messages: action.payload.data.message,
                pagination: action.payload.data.result.items
            });
        }
        case NETWORK_ERROR_SEARCHING_ITEMS: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message
            });
        }
        default:
            return state;
    }
}