import {BUSINESS_SLOT} from "./../types/slot.type"

let initialState = {
    businessSlots: []
}

export default function(state = initialState, action){
    switch (action.type){
        case BUSINESS_SLOT:
            return {...state, businessSlots: action.payload};
        default:
            return state;
    }

}