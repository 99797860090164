import React from "react";
import GoogleAddress from "./googleAddress";
import {
	geocodeByAddress,
	getLatLng,
} from 'react-places-autocomplete';

 
async function handleSelect (address, props) {
	try{
		const results = await geocodeByAddress(address);
		const latLng = await getLatLng(results[0]);
		// const formattedAddress = await results[0].formatted_address;
		window.document.getElementById("storeLat").value = latLng.lat;
		window.document.getElementById("storeLng").value = latLng.lng;
		props.handleChange("lat", latLng.lat);
		props.handleChange("long", latLng.lng);
		props.handleChange("address", address);
	}catch(e){
		console.error('Error', e);
		//also show notification in error
	}
}

export default function inputForm(props) {
	return (
		
		<div className="intro-y box">
			
			<div className="p-5" id="input">
					<div>
						<label>Name</label>
						<input 
							onChange={(e)=>props.updateState("name", e.target.value)} 
							name="name" value={props.state.name} 
							type="text" className="input w-full border mt-2" placeholder="Input text"
						/>
					</div>
					<div className="mt-3">
						<label>Description</label>
						<input 
							onChange={(e)=>props.updateState("description", e.target.value)} 
							name="description" value={props.state.description} 
							type="text" className="input w-full border mt-2" placeholder="Rounded" 
						/>
					</div>

					<div className="mt-3">
						<label>Category</label>
						<select 
							name="category_id" 
							onChange={(e)=>props.updateState("category_id", e.target.value)} 
							value = {props.state.category} 
							className="input w-full border mt-2" placeholder="Select Category" >
								<option>Select Category</option>
								{props.categories.length > 0 ? 
									props.categories.map((value)=>{ 
										return <option key={`cat${value.id}`} value={value.id} >{value.name}</option> 
									})
								 : ''}
							</select>
						
					</div>
					<div className="mt-3">
						<label>Address</label>
						<div className="input w-full border mt-2" style={{padding: 0}}>
						<GoogleAddress 
							address={props.state.address} 
							handleChange={props.updateState} 
							handleSelect={handleSelect}
						/>
						</div>
						<input id="storeLat" name="lat" type="hidden" />
						<input id="storeLng" name="long" type="hidden" />
					</div>
													
			</div>

		</div>
	);
}
