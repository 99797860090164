import React, {Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from "./actions/editBusiness.action";
import AddressAutoComplete from "./components/googleAutoAddress";
import {
	geocodeByAddress,
	getLatLng,
} from 'react-places-autocomplete';

import Layout from '../common/Layout';
import ImageThumbnails from '../common/widgets/image-thumbnails/ImageThumbnails';
import { networkNotification } from 'components/notifications/Notifications';
import { ImageThumbnailLoading } from '../common/widgets/Loaders';

import {DropzoneArea} from 'material-ui-dropzone';


class EditBusiness extends Component{

    constructor(props){
        super(props)

        this.state = {
            id:'',
            unique_id:'',
            name:'',
            category_id:'',
            address:'',
            description:'',
            images: [],
            lat:'',
            long:''
        }

        this.handleSelect = this.handleSelect.bind(this);
        this.setAddress = this.setAddress.bind(this);
    }
    
    componentDidMount(){
        this.props.actions.getBusinessCategory();
        let businessId = this.props.match.params.business_id;
        this.props.actions.fetchBusiness(businessId).then(
            (result)=>{
                this.setState({ 
                    id: businessId,
                    unique_id: result.unique_id,
                    name: result.name,
                    category_id: result.category_id || 5,
                    address: result.address || '',
                    description: result.description || '',
                    images: result.images,
                    lat: result.lat,
                    long: result.long})
        }).catch((error)=>{
            networkNotification([error.message], 'Error', 'danger')
        })
        this.props.actions.getBusinessCategory()
    }

    setFormValue(event){
        this.setState({[event.target.name]: event.target.value})
    }

    setAddress(data){
        this.setState({address: data})
    }

    handleSelect  = async function (address, props) {
        try{
            const results = await geocodeByAddress(address);
            const latLng = await getLatLng(results[0]);
            const formattedAddress = await results[0].formatted_address;
            this.setState({lat: latLng.lat, long: latLng.lng, address: formattedAddress})
          
        }catch(e){
            console.error('Error', e);
        }
    }

    editBusiness(event){
        event.preventDefault();
        let formData= new FormData()
    
        formData.append('name', this.state.name)
        formData.append('category_id', this.state.category_id)
        formData.append('address', this.state.address)
        formData.append('description', this.state.description)
        this.state.images.forEach((element, i) => {
            formData.append('images[]', element)
        });
        formData.append('lat', this.state.lat)
        formData.append('long', this.state.long)
        this.props.actions.editBusiness(this.state.unique_id, formData)   
    }


    onRemove = (id) => {
        this.props.actions.deleteImage(id)
    };  
    

    handleImageChange = (files) => {
        console.log(files)
        this.setState({
            images: files
        });
    }


    updateImages = async() => {
        console.log('upload images', this.state.images)

        let formData = new FormData();
        formData.append('type', 2);
        formData.append('id', this.state.id)
        this.state.images.forEach((element, i) => {
            formData.append('images[]', element)
        });
        
        this.props.actions.addImage(this.state.id, formData)

    }

    getUpdateFromImagesThumbnail = (images) => {
        console.log('update images', images)
        this.setState({images: []});
        this.props.actions.updateImages(images)
    }


    
    render = () => {
        let reducer = this.props.reducers
        let {editBusinessImageLoading, editBusinessImages} = reducer;
        
        return(
            <Layout>
                
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/account/dashboard">Dashboard</a></li>
                        <li className="breadcrumb-item"><a href="/account/dashboard/businesses">Businesses</a></li>
                        <li className="breadcrumb-item"><a href={`/account/dashboard/businesses/view/${this.props.match.params.business_id}`}>Business Name</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Edit</li>
                    </ol>
                </nav>

 

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">

                                           
                                <form onSubmit={(event)=>this.editBusiness(event)}>
                                    <div className="row"> 

                                        <div className="col-sm-12 col-md-6">
                                            <div className="form-group">
                                                <label >Business Name</label>
                                                <input type="text" className="form-control" id="businessName" placeholder="Enter business name" name="name" onChange={(event)=>this.setFormValue(event)} value={this.state.name} />
                                            </div>
                                            <div className="form-group">
                                                <label >Business Category</label>
                                                <select className="form-control" id="businessEdit" name="category_id" onChange={(event)=>this.setFormValue(event)} value={this.state.category_id}>
                                                    {reducer.editBusinessCategory.length > 0 ? 
                                                        reducer.editBusinessCategory.map((data)=>{
                                                            return (<option key={`cat${data.id}`} value={data.id} >{data.name}</option>)
                                                        })
                                                        : 
                                                        <option>No options found</option>
                                                    }
                                               
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label >Business Address</label>
                                                
                                                <AddressAutoComplete 
                                                    address={this.state.address} 
                                                    handleChange={this.setAddress} 
                                                    handleSelect={this.handleSelect}
                                                    // containerClassName = "form-control"
                                                    inputClassName = "form-control"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-6">    
                                            <div className="form-group">
                                                <label >Business Description</label>
                                                <textarea className="form-control" id="exampleFormControlTextarea1" rows="8" name="description" onChange={(event)=>this.setFormValue(event)} value={this.state.description}></textarea>
                                            </div>
                                        </div>

                                       
                                        <div className="col-sm-12 col-md-12"> 
                                            <button className="btn btn-primary my-2 my-sm-0 pull-right" >
                                                 {reducer.editBusinessLoading ? 'Loading...' : <><i className="fas fa-fw fa-pen"></i>  Edit Business </> } 
                                            </button>
                                        </div>
                                    </div>
                                </form>



                                <div className="row">
                                    <div className="col-md-12 mt-5 mb-5">
                                        <div className="card">
                                            <div class="card-header">
                                                <b>Manage Item Photos</b>
                                            </div>
                                            <div className="card-body pb-5">

                                                <div className="row">
                                                    <div className="ccol-md-6 col-sm-6 mt-5">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                { 
                                                                    <>
                                                                        <DropzoneArea 
                                                                            filesLimit={6}
                                                                            onChange={this.handleImageChange.bind(this)} 
                                                                        />
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="mt-3">
                                                            <button className="btn btn-primary" onClick={this.updateImages} disabled={editBusinessImageLoading || this.state.images.length < 1}>
                                                                
                                                                {editBusinessImageLoading
                                                                    ?
                                                                        <>
                                                                            <span className="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                                                                            <span className="visually-hidden"> Uploading...</span>
                                                                        </>
                                                                    :
                                                                        <>
                                                                            <i className="fa fa-send"></i> Upload Selected Photos
                                                                        </>
                                                                }
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 col-sm-6 mt-5">
                                                        {!editBusinessImageLoading
                                                            ?
                                                            <ImageThumbnails images={editBusinessImages} key={(Date.now())} getUpdateFromImagesThumbnail={this.getUpdateFromImagesThumbnail}/>
                                                            :
                                                            <div className="card">
                                                                <div className="card-body pb-5">
                                                                    <div className="row">
                                                                        <ImageThumbnailLoading />
                                                                    </div>
                                                                </div>
                                                            </div>        
                                                        }
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                        
                                </div>        
                               
                                   
                            </div>
                        </div>
                    </div>
                </div>

                
   
            </Layout>
        );
    }
}

const mapStateToProps = function(state){
    return {reducers: state.editBusinessReducer}
}

const mapActionsToProps = function(dispatch){
    return {actions: bindActionCreators(actions, dispatch) }
}

export default connect(mapStateToProps, mapActionsToProps)(EditBusiness);