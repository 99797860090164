import React from 'react';


export const Paginator = (props) => {

    const { pagination, sort, paginate, page } = props;
    const { current_page, last_page, } = pagination;

    return(
        <ul className="pagination">
            <li>
                <button onClick={() => paginate(current_page, last_page, 'previous')} className={`pagination__link ${current_page <= 1 ? '' : 'pagination__link--active'}`} disabled={current_page <= 1 ? true : false}>&#8592; Prev</button>
            </li>
            
            <li> 
                <select defaultValue={page} name="page" onChange={sort}  className="input input--lg box w-full lg:w-auto mt-0 ml-auto mr-2">
                    
                    {
                        pagesArray(last_page).map(
                            pageNum => (
                                <option key={pageNum} value={pageNum}>{pageNum}</option>
                            )
                        )
                    }
                
                </select>
            </li>
            <li>
                <button onClick={() => paginate(current_page, last_page, 'next')} className={`ml-auto flex pagination__link ${current_page >= last_page ? '' : 'ml-auto flex pagination__link--active'}`} disabled={current_page >= last_page ? true : false}>Next &#8594;</button>
            </li>
        </ul>
    );
}


const pagesArray = (count) => {
    let array = [];
    for(var i = 1; i <= count; i ++){
        array.push(i);	
    }
    return array;
}