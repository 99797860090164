import React from "react";

export default function(props){
    return(
        <div className="col-span-12 xl:col-span-4 mt-6">
            <div className="intro-y col-span-12 lg:col-span-12">
                <div className="box rounded-md p-6 relative zoom-in block font-medium text-center truncate ">
                        {props.message}
                </div>
            </div>
        </div>
    )
}