import axios from 'axios';
import { endpointV2 } from '../../../../../config';

import {
    BEFORE_LOGIN,
    LOGIN,
    NETWORK_ERROR_LOGGING_IN
} from '../types/popup-login.types';
import { setLocalToken } from '../../../../../helpers/authentication/token';
import { popAlert } from '../../../../../helpers/alert/popUpAlert';
import { encryptUserData } from '../../../../../helpers/encryption/userEncrypt';


export const beforeLogin = () => (
    { type: BEFORE_LOGIN }
)

export const login = (body) => (
    async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpointV2}user/login`,
                data: body,
                headers: {
                    "content-type": 'application/json',
                    "Accept" : 'application/json',
                },
            });

            dispatch({ type: LOGIN, payload: response });

        }catch(e){
            console.log('FAILED RESPONSE', e);
            dispatch({ type: NETWORK_ERROR_LOGGING_IN, payload: e.response});
        }
    }
)