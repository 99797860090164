import{
    BEFORE_VALIDATE_TOKEN,
    VALIDATE_TOKEN,
    NETWORK_ERROR_VALIDATING_TOKEN,

    BEFORE_RESET_PASSWORD,
    RESET_PASSWORD,
    NETWORK_ERROR_RESETTING_PASSWORD
} from '../types/password-reset.types';

const initialState = {

    validation_loading: false,
    validation_status_code: null,
    token: null,
    reset_email: null,
    validation_messages: [],

    loading: false, 
    status_code: null,
    email: null,
    messages: [],
}

export default function(state = initialState, action){
    switch(action.type){
        case BEFORE_VALIDATE_TOKEN:{
            return Object.assign({}, state, {
                validation_loading: true
            });
        }
        case VALIDATE_TOKEN:{
            return Object.assign({}, state, {
                validation_loading: false,
                token: action.payload.data.result.token,
                reset_email: action.payload.data.result.reset_email,
                validation_messages: action.payload.data.message,
                validation_status_code: action.payload.status
            });
        }
        case NETWORK_ERROR_VALIDATING_TOKEN:{
            return Object.assign({}, state, {
                validation_loading: false,
                validation_messages: action.payload.data.message,
                validation_status_code: action.payload.status
            });
        }
        case BEFORE_RESET_PASSWORD:{
            return Object.assign({}, state, {
                loading: true
            });
        }
        case  RESET_PASSWORD:{
            return Object.assign({}, state, {
                loading: false,
                email: action.payload.data.result.email,
                messages: action.payload.data.message,
                status_code: action.payload.status
            });
        }
        case NETWORK_ERROR_RESETTING_PASSWORD:{
            return Object.assign({}, state, {
                loading: false,
                messages: action.payload.data.message,
                status_code: action.payload.status
            }); 
        }                      
        default:
            return state;           
    }
}
