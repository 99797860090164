import { networkNotification, customNotification } from "components/notifications/Notifications";
import { getRequest, postRequest, deleteRequest, putRequest } from "helpers/httpRequest/request";
import {
    EDIT_BUSINESS,
    EDIT_BUSINESS_LOADING, 
    EDIT_BUSINESS_ERROR, 
    EDIT_GET_BUSINESS,
    EDIT_GET_BUSINESS_LOADING,
    EDIT_GET_BUSINESS_ERROR,
    EDIT_BUSINESS_CATEGORY, 
    EDIT_BUSINESS_CATEGORY_LOADING, 
    EDIT_BUSINESS_CATEGORY_ERROR,

    EDIT_BUSINESS_IMAGES,

    EDIT_BUSINESS_ADD_IMAGE_LOADING,
    EDIT_BUSINESS_ADD_IMAGE_ERROR,

    EDIT_BUSINESS_DELETE_IMAGE_LOADING,
    EDIT_BUSINESS_DELETE_IMAGE_ERROR,
} from "../types/edit-business.type"

export const fetchBusiness = (id, setState)=>{
    return (dispatch)=>{
       return new Promise((resolve, reject)=>{
            dispatch({type:EDIT_GET_BUSINESS_LOADING})
            getRequest('v2', `fetch/business/${id}`).then((data)=>{
                let result = data.result
                dispatch({type: EDIT_GET_BUSINESS, payload: result})
                dispatch({type: EDIT_BUSINESS_IMAGES, payload: result.images})
                resolve(result)   
            }).catch((error)=>{
                dispatch({type: EDIT_GET_BUSINESS_ERROR})
                reject(error)
            })

        })
        
    }
}

export function getBusinessCategory(){
    return (dispatch)=>{
        dispatch({type: EDIT_BUSINESS_CATEGORY_LOADING});
        getRequest('v1', 'fetch/categories').then((data)=>{
            dispatch({type: EDIT_BUSINESS_CATEGORY, payload: data.result})
        }).catch((error)=>{
            dispatch({type: EDIT_BUSINESS_CATEGORY_ERROR})
        })
    }
}


export function editBusiness(unique_id, data){
    return (dispatch)=>{
        dispatch({type: EDIT_BUSINESS_LOADING});
        postRequest('v1', `update/business/${unique_id}`, data).then((data)=>{
            if(data.status == 'success'){
                dispatch({type: EDIT_BUSINESS, payload: data.result})
                customNotification([data.message], 'Edit Business', 'success')
                // window.location.href = "account/dashboard/businesses/view/"+data.result.pid

            }else{
                dispatch({type: EDIT_BUSINESS_ERROR})
                customNotification([data.message], 'Error', 'danger') 
            }
        }).catch((error)=>{
            dispatch({type: EDIT_BUSINESS_ERROR})
            // networkNotification([error.message], error.status);
            customNotification([data.message], 'Error', 'danger')

        })
    }
}

export function addImage(id, data){
    return (dispatch)=>{
        dispatch({type: EDIT_BUSINESS_ADD_IMAGE_LOADING})

        postRequest('v2', `images/business/${id}`, data).then((response)=>{
            dispatch({type: EDIT_BUSINESS_IMAGES, payload: response.result.business.images})
            // customNotification(data.message, 'Edit Business', 'success')
            // window.location.href = "account/dashboard/businesses/view/"+data.result.pid

        }).catch((error)=>{
            dispatch({type: EDIT_BUSINESS_ADD_IMAGE_ERROR})
            // networkNotification([error.message], error.status);
            customNotification([data.message], 'Error', 'danger')

        })
    }
}


export function updateImages(data){
    return (dispatch) =>{
        dispatch({type: EDIT_BUSINESS_IMAGES, payload: data})
    }
}


export function deleteImage(id){
    return (dispatch)=>{
        dispatch({type: EDIT_BUSINESS_DELETE_IMAGE_LOADING})

        deleteRequest('v2', `delete/images/${id}`).then((data)=>{
            console.log(data)
            dispatch({type: EDIT_BUSINESS_IMAGES, payload: data.result.images})
            customNotification(data.message, 'Delete Image', 'success')
            // window.location.href = "account/dashboard/businesses/view/"+data.result.pid

        }).catch((error)=>{
            dispatch({type: EDIT_BUSINESS_DELETE_IMAGE_ERROR})
            // networkNotification([error.message], error.status);
            customNotification([error.message], 'Error', 'danger')

        })
    }
}
