import axios from 'axios';

import{
    BEFORE_SEND_PASSWORD_RESET_LINK,
    SEND_PASSWORD_RESET_LINK,
    NETWORK_ERROR_SENDING_PASSWORD_RESET_LINK
} from '../types/forgot-password.types';

import {
    endpoint
} from '../../../../config';



export const beforeSendPasswordResetLink = () => (
    { type: BEFORE_SEND_PASSWORD_RESET_LINK }
)

export const sendPasswordResetLink = (data) => (
    async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpoint}user/forgot-password/reset-link`,
                headers: {
                    "content-type": "application/json",
                    "Accept" : "application/json",
                },
                data
            });
            dispatch({ type: SEND_PASSWORD_RESET_LINK, payload: response });
        }catch(e){
            dispatch({ type: NETWORK_ERROR_SENDING_PASSWORD_RESET_LINK, payload: e.response });
        }
    }
)