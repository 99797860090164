import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import * as Actions from "./actions/landing.actions";
import GoogleSearch from "components/googleSearch/googleSearch";
import GoogleMap from "components/googleMap/googleMap";
import SearchResults from "./components/searchResults";
import {
	getLocalAddress
} from '../../../helpers/location/LocalAddress';
import {
	customNotification,
	networkNotification
} from '../../../components/notifications/Notifications';

import {
	Paginator
} from '../../../components/paginator/Paginator';

import HorizontalLoader from '../../../assets/images/loaders/horizontal.gif';


class Landing extends Component {

	constructor(props){
		super(props);
		this.search = this.search.bind(this);

		this.state = {
			formData: null,
			sort: 1,
			page: 1,
			radius: 1,
			userLocation: getLocalAddress()
		}
	}

	async componentDidMount(){
		this.search({type: 1, radius: 30});
	}

	async search(data, resetPage = false){
		const { actions } = this.props;
		const { sort, page } = this.state;

		if(getLocalAddress()){
			await actions.beforeSearch();
			const payload = {
				lat: getLocalAddress().lat_lng.lat,
				long: getLocalAddress().lat_lng.lng,
				description: data.description,
				type: data.type,
				radius: data.radius
			};

			this.setState({
				formData: payload,
				radius: data.radius
			});

			if(sort){
				payload['sort'] = sort;
			}

			if(page){
				const newPage = resetPage ? 1 : page;
				payload['page'] = newPage;

				this.setState({
					page: newPage
				});
			}

			await actions.search(payload);

			let { landingReducer } = this.props;
			let { messages, status_code } = landingReducer;

			if(status_code !== 200){
			 networkNotification(messages, status_code);
			}

		}else{
			customNotification(
				['Please insert address before proceeding'], 
				'Something went wrong', 
				'danger'
			);
		}
	}

	onAddressUpdated = (updatedAddressObject) => {
		this.setState({
			userLocation: updatedAddressObject
		});
	}

	searchType = (text) => {
		this.setState({
			description: text
		});
	}

	sort = async(event) => {
		event.preventDefault();
		const name = event.target.name;
        const value = event.target.value;
		
		await this.setState({
            [name]: value
		});
		
		const { formData } = this.state;

		if(formData !== null){
			this.search(formData);
		}
	}

	paginate = async(currentPage, lastPage, action) => {
		
		let { page } = this.state;

		if(action === "next"){
			page = currentPage < lastPage ? currentPage + 1 : lastPage;
		}else{
			page = currentPage > 1 ? currentPage - 1 : 1;
		}

		await this.setState({
            page: page
		});

		const { formData } = this.state;

		if(formData !== null){
			this.search(formData);
		}
	}


	render() {

		const { landingReducer } = this.props;
		const { items, route, status_code, loading, pagination } = landingReducer;
		const { page, radius, userLocation } = this.state;

		return (
			<div className="content">
				<div className="grid grid-cols-12 gap-6">
					<div className="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6">
						<div className="col-span-12 xl:col-span-12 mt-2">
							


							<GoogleSearch worker={this.search} onAddressUpdated={this.onAddressUpdated} />
							
							{!getLocalAddress() &&
								<div class="text-theme-6 col-span-12 mt-2 p-8">Cluster will need your address to determine the region to find products and services...</div>
							}

							{loading &&
								<div><center><img alt='' src={HorizontalLoader }  style={{height:80}}/></center></div>
							}
							<GoogleMap markers={items} radius={radius} userLocation={userLocation} mapHeightPercent={100} />
						
						
						
						
						
						</div>
					</div>
				</div>

				{items.length === 0
				?
				
				<div className="pos intro-y grid grid-cols-12 gap-5 mt-5">
					<div className="intro-y col-span-12 lg:col-span-12">
						<div className="box rounded-md p-20 relative zoom-in block font-medium text-center">
							{status_code === 200 ? 'No results found for your search. ' : 'Use search above to find businesses and services near you.'}
						</div>
					</div>
				</div>
				:
				<SearchResults items={items} route={route} sort={this.sort} Paginator={<Paginator pagination={pagination} page={page} sort={this.sort} paginate={this.paginate}/>} />
				}

				{(pagination && items.length > 0) &&
					<div className="intro-y flex flex-wrap  sm:flex-row sm:flex-no-wrap items-center mt-6">
						<Paginator pagination={pagination} page={page} sort={this.sort} paginate={this.paginate}/>
					</div>
				}

			</div>
				
		);
	}
}

const mapStateToProps = (state) => ({ landingReducer: state.landingReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Landing));
