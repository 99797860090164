export const BEFORE_FETCH_DASHBOARD_EDIT_PRODUCT = 'BEFORE_FETCH_DASHBOARD_EDIT_PRODUCT';
export const FETCH_DASHBOARD_EDIT_PRODUCT = 'FETCH_DASHBOARD_EDIT_PRODUCT';
export const NETWORK_ERROR_FETCHING_DASHBOARD_EDIT_PRODUCT = 'NETWORK_ERROR_FETCHING_DASHBOARD_EDIT_PRODUCT';

export const BEFORE_UPDATE_DASHBOARD_PRODUCT = 'BEFORE_UPDATE_DASHBOARD_PRODUCT';
export const UPDATE_DASHBOARD_PRODUCT = 'UPDATE_DASHBOARD_PRODUCT';
export const NETWORK_ERROR_UPDATING_DASHBOARD_PRODUCT = 'NETWORK_ERROR_UPDATING_DASHBOARD_PRODUCT';


export const BEFORE_UPDATE_DASHBOARD_PRODUCT_IMAGES = 'BEFORE_UPDATE_DASHBOARD_PRODUCT_IMAGES';
export const UPDATE_DASHBOARD_PRODUCT_IMAGES = 'UPDATE_DASHBOARD_PRODUCT_IMAGES';
export const NETWORK_ERROR_UPDATING_DASHBOARD_PRODUCT_IMAGES = 'NETWORK_ERROR_UPDATING_DASHBOARD_PRODUCT_IMAGES';