import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Slider from "react-slick";
import ReactHtmlParser from 'react-html-parser';
import { Carousel } from 'react-responsive-carousel';
import {Helmet} from "react-helmet";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


import * as Actions from './actions/product.actions';
import ContentLoader, { Facebook, Instagram } from 'react-content-loader';
import ClusterLogo from '../../../assets/images/logo/logoface.png';
import {
	getLocalAddress
} from '../../../helpers/location/LocalAddress';

import ProductsCollection from '../common/widgets/products/ProductsCollection';
import LoadingCollection from '../common/widgets/products/LoadingCollection';

import Layout from '../common/Layout';
import GoogleMap from "components/googleMap/googleMap";


class Product extends Component{

    componentDidMount = async() => {
		const { beforeFetchProduct, fetchProduct } = this.props.actions;
		
		await beforeFetchProduct();
		fetchProduct(this.props.match.params.id);
    }

    render(){

        const route = 'product';
        const { 
            product, status_code, coordinates, loading,  
            related_products, previous_product, next_product
        } = this.props.productReducer;

        console.log('CO-ORDS',coordinates);

        const userAddress = getLocalAddress() ? getLocalAddress().address : 'My Location';
        
        return(
            <Layout isSearchPage={false}> 

                {(status_code == 200 && !loading && product) &&
                    <Helmet>
                        <title>{product.name}</title>
                    </Helmet>
                }

                <main className="main">
                    <nav aria-label="breadcrumb" className="breadcrumb-nav border-0 mb-0">
                        <div className="container d-flex align-items-center">
                            {(status_code == 200 && !loading && product)
                            ?
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item"><a href={`/provider/${product.business.public_url}`}>Provider</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{product.name.substring(0,25)}...</li>
                            </ol>
                            :
                            <ContentLoader style={{width: '35%', height:'26px'}}>
                                <rect x="0" y="3" rx="4" ry="4"  height="20" style={{width: '100%'}} />
                            </ContentLoader>  
                            }

                            <nav className="product-pager ml-auto" aria-label="Product">
                                {(status_code == 200 && !loading) &&
                                    <>
                                        {previous_product !== null
                                        ?
                                        <a className="product-pager-link product-pager-prev" href={`/${route}/${previous_product.id}`} aria-label="Previous" tabindex="-1">
                                            <i className="icon-angle-left"></i>
                                            <span>Prev</span>
                                        </a>
                                        :
                                        <span className="product-pager-link product-pager-prev" aria-label="Previous" tabindex="-1">
                                            <i className="icon-angle-left"></i>
                                            <span>Prev</span>
                                        </span>
                                        }

                                        {next_product !== null ?
                                        <a className="product-pager-link product-pager-next" href={`/${route}/${next_product.id}`} aria-label="Next" tabindex="-1">
                                            <span>Next</span>
                                            <i className="icon-angle-right"></i>
                                        </a>
                                        :
                                        <span className="product-pager-link product-pager-next"  aria-label="Next" tabindex="-1">
                                            <span>Next</span>
                                            <i className="icon-angle-right"></i>
                                        </span>
                                        }
                                    </>
                                }
                            </nav>
                        </div>
                    </nav>


                    <div className="page-content">
                        <div className="container">
                            <div className="product-details-top">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="product-gallery product-gallery-vertical">
                                            
                                            <Carousel>
                                                {(status_code == 200 && !loading)
                                                ?
                                                    (product.images.length > 0)
                                                    ?
                                                        (product.images.map(	
                                                            image => 
                                                                (
                                                                    <div className="container-res" style={{backgroundImage: `url(${(image.s3_bucket_url && image.url) ? image.s3_bucket_url+image.url : ClusterLogo})`, backgroundPosition: 'center', backgroundSize:'contain'}}>
                                                                        <img src={`${(image.s3_bucket_url && image.url) ? image.s3_bucket_url+image.url : ClusterLogo}`} alt="Image Sample" />
                                                                    </div>
                                                                )
                                                            )
                                                        )
                                                    :
                                                        (
                                                            <div className="container-res" style={{backgroundImage: `url(${ClusterLogo})`, backgroundPosition: 'center', backgroundSize:'contain'}}>
                                                                <img src={`${ClusterLogo}`} alt="Image Sample" />
                                                            </div> 
                                                        )
                                                :
                                                    <div className="container-res" style={{backgroundColor: '#ebebeb'}}>
                                                        <ContentLoader showThumbs={false}>
                                                            <rect x="0" y="17" rx="4" ry="4" width="300" height="13" />
                                                            <rect x="0" y="40" rx="3" ry="3" width="250" height="10" />
                                                        </ContentLoader>
                                                        <span className="slider-loader"></span>
                                                    </div>        
                                                }
                                                
                                            </Carousel>
                                                                                        
                                        </div>
                                    </div>

                                    <div className="col-md-7">
                                        <div className="product-details">
                                            <h1 className="product-title">
                                                {(status_code == 200 && !loading)
                                                ? 
                                                    <>{product.name}</>
                                                :
                                                    <ContentLoader style={{width: '100%', height:'26px'}}>
                                                        <rect x="0" y="3" rx="4" ry="4"  height="20" style={{width: '100%'}} />
                                                    </ContentLoader>   
                                                }
                                            </h1>

                                            <div className="row">  

                                                <div className="col-md-6">

                                                    <div className="ratings-container">
                                                        
                                                        {(status_code == 200 && !loading && product)
                                                        ? 
                                                            <span className="ratings-text" href="#product-review-link" id="review-link"><i className="fa fa-eye" style={{color:'#fcb941', fontWeight: 'bold'}}></i>  ( {product.views_count} Views )</span>
                                                        :
                                                            <ContentLoader style={{width: '100%', height:'30px'}}>
                                                                <rect x="0" y="3" rx="4" ry="4" height="24" style={{width: '50%'}}/>
                                                            </ContentLoader>   
                                                        }
                                                    </div>

                                                    <div className="product-price">
                                                        {(status_code == 200 && !loading)
                                                        ? 
                                                            <>₦{(product.amount ?  product.amount : 0).toLocaleString()}</>
                                                        :
                                                            <ContentLoader style={{width: '100%', height:'30px'}}>
                                                                <rect x="0" y="3" rx="4" ry="4" height="24" style={{width: '50%'}}/>
                                                            </ContentLoader>   
                                                        }
                                                    </div>

                                                    <div className="product-content">
                                                        {(status_code == 200 && !loading) &&
                                                            <a href={product.business.back_link} target="_blank" title={`Visit store on ${product.source.name}`}>
                                                                <img src={product.source.icon}/>
                                                            </a>
                                                        }
                                                        <p>

                                                        {(status_code == 200 && !loading)
                                                        ? 
                                                            <>{product.business.name}</>
                                                        :
                                                            <ContentLoader style={{width: '100%', height:'22px'}}>
                                                                <rect x="0" y="3" rx="4" ry="4" height="16" style={{width: '70%'}} />
                                                            </ContentLoader>   
                                                        } 

                                                        </p>
                                                    </div>


                                                    <div className="product-details-action">
                                                        {(status_code == 200 && !loading)
                                                        ? 
                                                            <a href={`/provider/${product.business.public_url}`} className="btn btn-outline-primary"><span>View Service Provider</span> <i className="icon-long-arrow-right"></i> </a>
                                                        :
                                                            <ContentLoader style={{width: '100%', height:'46px'}}>
                                                                <rect x="0" y="3" rx="4" ry="4" height="40" style={{width: '70%'}} />
                                                            </ContentLoader>   
                                                        } 
                                                        
                                                    </div>


                                                    <div className="product-details-action">
                                                    {(status_code == 200 && !loading)
                                                        ? 
                                                        <a href={`https://www.google.com/maps?saddr=${userAddress}&daddr=${coordinates.lat},${coordinates.lng}`}  className="btn btn-primary"><span>Get Directions</span> <i className="icon-long-arrow-right"></i> </a>
                                                        :
                                                            <ContentLoader style={{width: '100%', height:'40px'}}>
                                                                <rect x="0" y="0" rx="4" ry="4" height="40" style={{width: '70%'}} />
                                                            </ContentLoader>   
                                                        } 
                                                    </div>

                                                </div>  

                                                <div className="col-md-6" style={{border: '1px solid #ff4861', padding: '1%'}}>
                                                    <div className="container-res">
                                                        {coordinates !== null && coordinates.lat !== null && coordinates.lng !== null 
                                                        ?
                                                            <GoogleMap userLocation={{lat_lng: { lat: coordinates.lat, lng: coordinates.lng }}} markers={[{ lat: coordinates.lat, long: coordinates.lng }]} radius={1} />
                                                        :
                                                            <>
                                                                <div>Loading maps</div>
                                                                <span className="slider-loader"></span>
                                                            </>
                                                        }
                                                        
                                                    </div>
                                               </div>  
                                                
                                            </div> 

                                            <div className="product-details-tab">
                                                <ul className="nav nav-pills justify-content-left" role="tablist">
                                                    <li className="nav-item">
                                                        <a className="nav-link active" id="product-desc-link" data-toggle="tab" href="#product-desc-tab" role="tab" aria-controls="product-desc-tab" aria-selected="true">Product Information</a>
                                                    </li>
                                                </ul>
                                                <div className="tab-content" style={{borderLeft: '0rem', borderRight: '0rem', borderBottom: '0rem'}}>
                                                    <div className="tab-pane fade show active" id="product-desc-tab" role="tabpanel" aria-labelledby="product-desc-link" style={{}}>
                                                        <div className="product-desc-content" style={{padding: '0px'}}>
                                                        
                                                            {(status_code == 200 && !loading)
                                                            ? 
                                                                <p>{ReactHtmlParser(product.description)}</p>
                                                            :
                                                                <ContentLoader style={{width: '100%', height:'124px'}}>
                                                                    <rect x="0" y="8" rx="4" ry="4" height="12" style={{width: '100%'}} />
                                                                    <rect x="0" y="28" rx="4" ry="4"  height="12" style={{width: '100%'}} />
                                                                    <rect x="0" y="48" rx="4" ry="4"  height="12" style={{width: '100%'}} />
                                                                    <rect x="0" y="68" rx="4" ry="4"  height="12" style={{width: '100%'}} />
                                                                    <rect x="0" y="88" rx="4" ry="4"  height="12" style={{width: '100%'}} />
                                                                    <rect x="0" y="108" rx="4" ry="4" height="12" style={{width: '100%'}} />
                                                                </ContentLoader>   
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                            
                                </div>
                            </div>

                            
                            {(status_code == 200 && !loading)
                            ? 
                                <h2 className="title text-center mb-4">You May Also Like</h2>
                            :
                                <h2 className="mb-3" style={{textAlign: 'center'}}>
                                     <ContentLoader style={{width: '40%', height:'38px', display: 'inline-block'}}>
                                        <rect x="0" y="0" rx="4" ry="4" height="23" style={{width: '100%'}} />
                                        <rect x="0" y="28" rx="4" ry="4" height="10" style={{width: '75%'}} />
                                    </ContentLoader> 
                                </h2>
                            }

                            
  
                            {(status_code == 200 && !loading)
                            ? 
                                <ProductsCollection items={related_products} route={'product'} />
                            :
                                <LoadingCollection />
                            }
                                
                        </div>
                    </div>
                </main>

            </Layout>
        );
    }
}


const mapStateToProps = (state) => ({ productReducer: state.productReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Product));