import React from "react";

export default function description(props) {
	let product = props.product;
	let business = props.product.business
	return (
		<div className="mt-5">
			<div className="flex items-center px-5 py-3 border-b border-gray-200">
				<h2 className="font-medium text-base mr-auto">Description</h2>
			</div>
			<div >
				
				<div className="p-5">
					<p className="font-medium ">Name</p> 
					<div className="text-gray-700 mt-2 font-medium text-lg">{product.name}</div>
				</div>

				<div className="px-5 py-3 border-t border-gray-200">
					<p className="font-medium ">Description</p>
					<p className="mt-2">{product.description === null ? "Not provided" : product.description }</p>
				</div>

				<div className="px-5 py-3 border-t border-gray-200">
					<p className="font-medium ">Category</p>
					<p className="mt-2">{product.category === null ? "Not provided" : product.category }</p>
				</div>

				<div className="px-5 py-3 border-t border-gray-200">
					<p className="font-medium ">Subcategory</p>
					<p className="mt-2">{product.subcategory === null ? "Not provided" : product.subcategory }</p>
				</div>

				<div className="px-5 py-3 border-t border-gray-200">
					<p className="font-medium ">Price</p>
					<p className="mt-2">{product.amount === null ? "Not provided" : product.amount }</p>
				</div>

				<div className="px-5 py-3 border-t border-gray-200">
					<p className="font-medium ">View count</p>
					<p className="mt-2">{product.views_count === null ? "Not provided" : product.views_count }</p>
				</div>

				<div className="flex items-center px-5 py-3 border-t border-gray-200">
			
					<a href={`/edit-product/${business.unique_id}/${product.id}`} className="intro-x w-8 h-8 flex items-center justify-center text-theme-8 ml-auto tooltip tooltipstered"> 
					<span style={{fontSize: "24px"}} className="fa fa-edit"></span>
					</a>

					<a href={`/delete-product/${business.unique_id}/${product.id}`} className="intro-x w-8 h-8 flex items-center justify-center text-theme-6 ml-2 tooltip tooltipstered"> 
						<span style={{fontSize: "24px"}} className="fa fa-trash"></span>
					</a>
				</div>

					
				
			</div>
		</div>
	);
}
