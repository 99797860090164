import { 
    BEFORE_FETCH_PRODUCT,
    FETCH_PRODUCT,
    NETWORK_ERROR_FETCHING_PRODUCT
 } from '../types/product.types';

let initialState = {
    product: null,
    related_products: [],
    loading: false,
    coordinates: null,
    status_code: null,
    messages: [],
    previous_product: null,
    next_product: null,
}

export default function productReducer(state = initialState, action){
    switch(action.type){
        case BEFORE_FETCH_PRODUCT: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case FETCH_PRODUCT:{
            return Object.assign({}, state, {
                loading: false,
                product: action.payload.data.result.product,
                previous_product: action.payload.data.result.previous_product,
                next_product: action.payload.data.result.next_product,
                related_products: action.payload.data.result.related_products,
                coordinates: {
                    lat: action.payload.data.result.product.lat,
                    lng: action.payload.data.result.product.long
                },
                status_code: action.payload.status
            });
        }
        case NETWORK_ERROR_FETCHING_PRODUCT: {
            console.log('PAYLOAD:', action.payload);
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: [action.payload.statusText]
            });
        }    
        default:
            return state;
    }

}