import React, {Component} from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Layout from '../common/Layout';
import * as Actions from "./actions/dashboard.action";
import {DashboardLoading} from "./../common/widgets/Loaders";
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';

class Dashboard extends Component{

    constructor(props){
        super(props)
        this.props.actions.getData()
        this.props.actions.getChartData()
        this.props.actions.getListOfBusinesses() 
    }

    state = {
        windowWidth: 800
    }

    componentDidMount(){
        let windowWidth = window.document.getElementById("graphContainer").offsetWidth;
        windowWidth =  windowWidth-20
        this.setState({windowWidth: windowWidth})
    }

    updateGraph(business_id){
        this.props.actions.getChartData(business_id)
    }

    
    render = () => {
        const reducer = this.props.reducer
        const data = reducer.dashboardGraph 
        
        return(
            <Layout>
                
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
                    </ol>
                </nav>

                {this.props.reducer.dashboardLoading ? <DashboardLoading /> : 
                

                <>

                <div className="row">

                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-primary shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                            Products
                                        </div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{this.props.reducer.dashboardData.products}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-calendar fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-success shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                            Businesses</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{this.props.reducer.dashboardData.businesses}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-briefcase fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-info shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Tasks
                                        </div>
                                        <div className="row no-gutters align-items-center">
                                            <div className="col-auto">
                                                <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">50%</div>
                                            </div>
                                            <div className="col">
                                                <div className="progress progress-sm mr-2">
                                                    <div className="progress-bar bg-info" role="progressbar"
                                                        style={{width: '50%'}} aria-valuenow="50" aria-valuemin="0"
                                                        aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 

                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-warning shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                            Pending Requests</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">18</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-comments fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>*/}
                </div>


                <div className="row">
                    <div className="col-sm-12">
                        <div className="card shadow mb-4">
                           
                            <div
                                className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 className="m-0 font-weight-bold text-primary">{reducer.dashboardGraphTitle} views</h6>
                                <div className="dropdown no-arrow">
                                    <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                        aria-labelledby="dropdownMenuLink">
                                        <div className="dropdown-header">Businesses:</div>
                                        {reducer.dashboardBusinesses.length > 0 ?
                                            reducer.dashboardBusinesses.map((data)=>
                                                <a key={`id${data.id}`} className="dropdown-item" href="#" onClick={()=>this.updateGraph(data.unique_id)}>{data.name}</a>
                                            )
                                    
                                            :  <a className="dropdown-item" href="#">No business found</a>
                                        }
                                        
                                        
                                    </div>
                                </div>
                            </div>
                            
                            <div className="card-body" id="graphContainer">

                                <LineChart style={{ width: '100%'}} width={this.state.windowWidth} height={300} data={data} margin={{ top: 5, right: 20, bottom: 0, left: 0 }}>
                                    <Line type="monotone" dataKey="uv" stroke="#8884d8" />
                                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                </LineChart>
                                 
                            </div>
                        </div>
                    </div>
                </div>
                    
                </>
                
                }
                
            </Layout>
        );
    }
}

const mapStateToProps = (state)=>({reducer: state.dashboardReducer})
const mapActionToProps = (dispatch)=>({
    actions: bindActionCreators(Actions, dispatch)
})
export default connect(mapStateToProps, mapActionToProps)(Dashboard);