import React from "react";
import UpdateImage from "./updateImage";
import Loading from "./loading"


export default function(props){
    return(
        <div className="intro-y box border border-gray-200 rounded-md p-5 mt-5">
            <div className="font-medium flex items-center border-b border-gray-200 pb-5">
                    Uploaded  Images 
            </div>
            <div className="mt-5">
                
                <div className="mt-3">
                
                    <div className="border-2 border-dashed rounded-md mt-3 pt-4">

                        {props.loading ?
                            <Loading />
                        : ''}
                    
                        <div className="flex flex-wrap px-4">
                    
                            {props.images.length > 0 ?props.images.map((value, index)=>
                                    <UpdateImage key={`uploadImage${index}`} id={props.id} image= {value} deleteImage = {props.deleteImage} type={props.type} />  
                            ) : ''}
   
                        </div>
                        <div className="px-4 pb-4 flex items-center cursor-pointer relative">
                            <i data-feather="image" className="w-4 h-4 mr-2"></i> <span className="text-theme-1 mr-1">Upload a file</span> or drag and drop 
                            <input type="file" className="w-full h-full top-0 left-0 absolute opacity-0" onChange={(e)=>props.uploadImage(e, props.id, props.type)}/>
                        </div>
                        
                    </div>
                    
                </div>

                

            </div>
        </div>
    )
}