import React from 'react';
import ClusterLogo from '../../assets/images/logo/logoface.png';

const  ProductCard = (props) => {
    const { product } = props;
    return (
        <div className="intro-y col-span-12 sm:col-span-12 md:col-span-3 xxl:col-span-2">
            <div className="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in">
                
                <a href={`/product/${product.id}`} className="w-full file__icon file__icon--image mx-auto">
                    <div className="file__icon--image__preview image-fit">
                        {product.images.length > 0
                        ?
                        <img alt="Midone Tailwind HTML Admin Template" src={`${product.images[0].s3_bucket_url}${product.images[0].url}`} />
                        :
                        <img alt="Cluster" src={ClusterLogo} style={{opacity: 0.3}} />
                        }
                    </div>
                </a>
                <div className="block font-medium mt-4 text-center truncate">{product.name}</div> 
                <div className="block text-gray-600 text-xs mt-0 text-center truncate">{product.description}..</div> 
                <div className="text-gray-600 text-xs text-center mt-2">
                    <a href={`/product/${product.id}`} style={{borderRadius: 0}} className="button mr-2 mb-2 flex w-full items-center justify-center bg-theme-1 text-white">₦{product.amount} -  View Details </a> 
                </div>

            </div>
        </div>
    );
}


export default ProductCard;