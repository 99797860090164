import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { store } from 'react-notifications-component';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { customNotification } from '../../../../components/notifications/Notifications';
import { getLocalToken, setLocalToken } from '../../../../helpers/authentication/token';

import * as Actions from './actions/popup-register.actions';


class PopupRegister extends Component{

    constructor(props){
        super(props);

        this.state = {
            email: '',
            password: '',
            firstname: '', 
            lastname: '', 
            phone: '',
            token: getLocalToken()
        }
    }

    componentDidMount = () => {

    }

    attemptRegistering = async (event) => {
        event.preventDefault();
        const { email, password, firstname, lastname, phone } = this.state;
        const { beforeRegister, register } = this.props.actions;

        await beforeRegister();
        await register({ email, password, firstname, lastname, phone });

        /* Give feedback to user */
        const { popupRegisterReducer, onRegisterSuccessful } = this.props;
        const { user, messages } = popupRegisterReducer;
        if(user){
            messages.push('Proceed to Login.');
            customNotification(messages, "Welcome", "success");
            //inform the parent component about the change, and send user payload
            onRegisterSuccessful(user);
        }else{
            customNotification(messages, "Just a moment!", "danger");
        }
    }

    handleChange = (event) => {
        event.preventDefault();
        this.setState(
            { [event.target.name]: event.target.value }
        );
    }

    render = () => {

        const { email, password, firstname, lastname, phone } = this.state;
        const { messages, loading } = this.props.popupRegisterReducer;
        const { switchPopup } = this.props;

        return (
                        
            <div className="form-tab">
                <ul className="nav nav-pills nav-fill nav-border-anim" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="signin-tab" data-toggle="tab" href="#signin" role="tab" aria-controls="signin" aria-selected="true">Sign Up</a>
                    </li>
                    
                </ul>
                <div className="tab-content" id="tab-content-5">
                    <div className="tab-pane fade show active" id="signin" role="tabpanel" aria-labelledby="signin-tab">
                        <form onSubmit={this.attemptRegistering}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label for="singin-email">First Name *</label>
                                        <input disabled={loading} onChange={this.handleChange} value={firstname} type="text" className="form-control" id="singin-email" name="firstname" required />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label for="singin-email">Last Name *</label>
                                        <input disabled={loading} onChange={this.handleChange} value={lastname} type="text" className="form-control" id="singin-email" name="lastname" required />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label for="singin-email">Email address *</label>
                                        <input disabled={loading} onChange={this.handleChange} value={email} type="email" className="form-control" id="singin-email" name="email" required />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label for="singin-email">Phone *</label>
                                        <PhoneInput
                                            inputClass="form-control"
                                            country={'ng'}
                                            onlyCountries={['ng']}
                                            placeholder={`+234 70 123 456 78`}
                                            value={phone}
                                            countryCodeEditable={false}
                                            onChange={phone => this.setState({ phone })}
                                        />
                                        {/*<input  onChange={this.handleChange} value={phone} type="text" className="form-control" id="singin-email" name="phone" required /> */}
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label for="singin-password">Password *</label>
                                <input disabled={loading} onChange={this.handleChange} value={password} type="password" className="form-control" id="singin-password" name="password" required />
                            </div>

                            <div className="form-footer">
                                <button disabled={loading} type="submit" className="btn btn-primary">
                                    { loading && <i className="fa fa-spinner fa-spin"></i> }
                                    <span>Register</span>
                                    <i className="icon-long-arrow-right"></i>
                                </button>
                            </div>
                        </form>
                        <div className="form-choice">
                            <p className="text-center">Already have an account?</p>
                            <div className="row">
                                <div className="col-sm-12">
                                    <button onClick={() => switchPopup('login')} className="btn btn-block btn-outline-primary">
                                        Login
                                    </button>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = (state) => ({ popupRegisterReducer: state.popupRegisterReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PopupRegister);