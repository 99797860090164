import React, { Component } from 'react';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Pagination } from 'react-laravel-paginex';
import ContentLoader, { Facebook, Instagram } from 'react-content-loader';

import Layout from '../common/Layout';
import ProductsCollection from '../common/widgets/products/ProductsCollection';
import LoadingCollection from '../common/widgets/products/LoadingCollection';
import Banner from './components/Banner';
import HotCollection from './components/HotCollection';
import SpecialCollection from './components/SpecialCollection';
import Brand from './components/Brand';
import CTA from './components/CTA';
import BlogSection from './components/BlogSection';

import * as Actions from './actions/home.actions';
import GoogleSearch from "components/googleSearch/googleSearch";
import GoogleMap from "components/googleMap/googleMap";
import { 
    setParameters, 
    getParameter
} from '../../../helpers/theme-two/search/url-parameters';

import {
	getLocalAddress
} from '../../../helpers/location/LocalAddress';
import {
	customNotification,
	networkNotification
} from '../../../components/notifications/Notifications';

class Home extends Component{

    constructor(props){
		super(props);
        
		this.state = {
            userLocation: getLocalAddress()
		}
    }
    
    componentDidMount = async() => {
		this.search();
    }
    
    handlePageChange = (data) => {
        setParameters([
            { name: 'page', value: data.page }
        ], true);
    }

    handleSortChange = (e) => {
        e.preventDefault();
        setParameters([
            { name: 'sort', value: e.target.value}
        ], true);
    }

    sortOptions = () => {
        
        const sortOptions = [
            {id: 1, name: 'Nearest'}, 
            //{id: 2, name: 'Farthest'}, 
            {id: 3, name: 'Recently Created'}, 
            {id: 4, name: 'Oldest'}, 
            {id: 5, name: 'A-Z'},
            {id: 6, name: 'Z-A'},
        ];

        const html =(
            <>
                {sortOptions.map(
                    element => (
                        <option value={element.id}>{element.name}</option>
                    )
                )}
            </>
        );

        return html;
    }


	search = async () => {
		const { actions } = this.props;

		if(getLocalAddress()){
			await actions.beforeSearch();
            const payload = {
				lat: getLocalAddress().lat_lng.lat,
				long: getLocalAddress().lat_lng.lng,
				description: getParameter('description', ''),
				type: getParameter('type', 1),
                radius: getParameter('radius', 10),
                sort: getParameter('sort', 1),
                page: getParameter('page', 1)
			};

			await actions.search(payload);

			let { homeReducer } = this.props;
			let { messages, status_code } = homeReducer;

			if(status_code !== 200){
			    networkNotification(messages, status_code);
			}

		}else{
			customNotification(
				['Cluster is waiting for your address.'], 
				'Attention Required!', 
				'warning'
			);
		}
	}

	setHomeComponentState = (field, value) => {
		this.setState({
			[field]: value
        });
        
        //if the address is updated, we want to trigger the search.
        this.triggerSearch(field, value);
    }
    

    triggerSearch = (field, value) => {
        if(field == 'userLocation' && value !== null){
            this.search();
        }
    }


    render(){

        const { homeReducer } = this.props;
		const { items, route, status_code, loading, pagination } = homeReducer;
        const { userLocation } = this.state;
        const radius = getParameter('radius', 10);
        
        
        return(
            <Layout  isSearchPage={true} setHomeComponentState={this.setHomeComponentState}>  

                <div className="main">
                    {userLocation !== null
                    ?
                    <>
                    <div className="intro-slider-container" style={{backgroundColor: '#ff4861'}}>
                        <GoogleMap markers={items} radius={radius} userLocation={userLocation} />
                        {loading &&
                            <span className="slider-loader"></span>
                        }
                    </div>
                
                    <div className="bg-light pt-3 pb-5">

                        <div className="container electronics">

                            <div className="toolbox">
                                
                                <div className="toolbox-left">
                                    {(!loading && status_code == 200)
                                    ?
                                    <div className="toolbox-info">
                                        Page <span>{pagination.current_page} of {pagination.last_page}</span> 
                                    </div>
                                    :
                                    <div className="toolbox-info">
                                        <ContentLoader style={{width: '100%', height:'45px'}}>
                                            <rect x="0" y="0" rx="4" ry="4"  height="30" style={{width: '100%'}} />
                                            <rect x="0" y="35" rx="4" ry="4"  height="10" style={{width: '50%'}} />
                                        </ContentLoader> 
                                    </div>
                                    }
                                </div>
                               
                                <div className="toolbox-right">
                                    {(!loading && status_code == 200)
                                    ?
                                    <div className="toolbox-sort">
                                        <label for="sortby">Sort by:</label>
                                        <div className="select-custom">
                                            <select onChange={(e) => this.handleSortChange(e)} value={getParameter('sort', 1)} name="sort" id="sortby" className="form-control">
                                                {this.sortOptions()}
                                            </select>
                                        </div>
                                    </div>
                                    :
                                    <div className="toolbox-sort">
                                        <ContentLoader style={{width: '100%', height:'45px'}}>
                                            <rect x="0" y="0" rx="4" ry="4"  height="30" style={{width: '100%'}} />
                                            <rect x="0" y="35" rx="4" ry="4"  height="10" style={{width: '50%'}} />
                                        </ContentLoader> 
                                    </div>
                                    }
                                </div>
                            </div>

                            {(!loading && status_code == 200) 
                            ? 
                                <ProductsCollection items={items} route={route} />
                            :
                                <LoadingCollection />
                            }


                            <nav aria-label="Page navigation" className="mt-3">
                                {(!loading && status_code == 200 && pagination)
                                ?
                                    (pagination.last_page >= pagination.current_page) &&    
                                        <Pagination buttonIcons={true} nextButtonIcon="icon-long-arrow-right" prevButtonIcon="icon-long-arrow-left" changePage={this.handlePageChange} data={pagination} />
                                    
                                
                                :
                                <ContentLoader style={{width: '100%', height:'40px'}}>
                                    <rect x="0" y="0" rx="4" ry="4" height="40" style={{width: '50%'}} />
                                </ContentLoader>        
                                }
                            </nav>

                        </div>
                    </div>
                    </>
                    :
                    <div className="page-header text-center"  style={{backgroundImage: `url('themetwo/assets/images/page-header-bg.jpg')`}}>
                        <div className="mt-8 pt-8 pb-8 mb-10">
                            <h1 className="page-title">
                                Address needed
                            </h1>
                            <span>Cluster will need you to insert your address above so we can accurately find products and services near you.</span>
                        </div>
                    </div>            
                    }
                </div>

            </Layout>
        );
    }
}

const mapStateToProps = (state) => ({ homeReducer: state.homeReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));