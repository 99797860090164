import React from 'react';

const Jumbotron = (props) => {
    const { title, message, linkTitle, linkHref, displayButton, buttonClass } = props;

    const btnClass = buttonClass ? `btn-${buttonClass}`: 'btn-danger';
    return (
        
        <div className="jumbotron">
            <h1>{title}</h1>
            <p className="lead">{message}</p>
            {displayButton &&
                <a className={`btn btn-lg ${btnClass}`} href={linkHref} role="button">{linkTitle} &raquo;</a>
            }
        </div>
        
    );
}

export default Jumbotron;