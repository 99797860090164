import axios from "axios";
import {BUSINESS_IMAGES, PRODUCT_IMAGES, UPDATE_IMAGE_LOADING} from "./../types/updateImage.type"
import {endpoint} from "config";
import {getToken} from "helpers/authentication/token";
import {handleError} from "helpers/error/error";
import {popAlert} from "helpers/alert/popUpAlert"

function startLoading(dispatch){
    dispatch({type:UPDATE_IMAGE_LOADING, payload: true})
}

function endLoading(dispatch){
    dispatch({type:UPDATE_IMAGE_LOADING, payload: false})
}

export function getImage(id, type, dispatch){  
    let token = getToken();
    axios.get(`${endpoint}get/images/${id}/${type}`, {headers:{Authorization: `Bearer ${token}`}})
    .then((data)=>{
        let responseData = data.data;
        if(responseData.status === 'success'){
            let dispatchType;
            if(type === 'business'){
                dispatchType = BUSINESS_IMAGES;
            }else if(type === 'product'){
                dispatchType = PRODUCT_IMAGES;
            }else{
                return;
            }
            dispatch({type: dispatchType, payload:data.data.result})
        }else{
            handleError(data)
        }
    }).catch((error)=>{
        handleError(error)
    })
}

export function setImages(images, type){
    return (dispatch)=>{
        if(type === 'business'){
            dispatch({type:BUSINESS_IMAGES, payload: images})
        }else if(type === 'product'){
            dispatch({type:PRODUCT_IMAGES, payload: images})
        }   
    }
}

export function deleteImage(id, imageId, type){
    return (dispatch)=>{
        let token = getToken();
        let confirm = window.confirm("Do you wish to delete image")
        if(confirm){
            startLoading(dispatch)
            axios.delete(`${endpoint}delete/image/${imageId}`, {headers:{Authorization: `Bearer ${token}`}})
            .then((data)=>{
                let responseData = data.data;
                if(responseData.status === 'success'){
                    popAlert("success", "Success", responseData.message)
                }else{
                    popAlert("error", "Error", responseData.message)
                }
                getImage(id, type, dispatch)
                endLoading(dispatch)
            }).catch((error)=>{
                handleError(error);
                endLoading(dispatch);
            })
        }
        
    }
}

export function uploadImage(e, id, type){
    return (dispatch)=>{
        startLoading(dispatch)
        let token = getToken();
        let typeId;
        if(type === 'business'){
            typeId = 2;
        }else{
            //product
            typeId = 1;
        }
        let formData = new FormData();
        formData.append('id', id)
        formData.append('type', typeId)
        formData.append('images[]', e.target.files[0])
        axios.post(`${endpoint}add/image`, formData, {headers:{Authorization: `Bearer ${token}`}})
        .then((data)=>{
            let responseData = data.data;
            if(responseData.status === 'success'){
                popAlert('success', 'Success', responseData.message)
                getImage(id, type, dispatch)
            }else{
                popAlert('error', 'Error', responseData.message)
                // handleError(data)
            }
            endLoading(dispatch)

        }).catch((error)=>{
            handleError(error)
            endLoading(dispatch)
        })
    }
}