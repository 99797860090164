import React from "react";

export default function description(props) {
	return (
		<div className="intro-y box mt-5">
			<div className="flex items-center px-5 py-3 border-b border-gray-200">
				<h2 className="font-medium text-base mr-auto">Description</h2>
			</div>
			<div className="py-5">
				<div className="px-5">
					<div className="font-medium text-lg">{props.name}</div>
					<div className="text-gray-700 mt-2">
						{props.description}
					</div>
					<div className="flex items-center mt-5">
						{/* <div className="px-3 py-2 bg-theme-14 text-theme-10 rounded font-medium">
							We can go there
						</div> */}
						<button className="button justify-center block bg-gray-200 text-gray-600 ml-auto">
							Go there
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
