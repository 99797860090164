import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import * as Actions from "./actions/product.actions";

import Title from "./components/title";
import SummaryCard2 from "./components/summaryCard2";
import Products from "./components/products";
import PageLoader from "components/loaders/pageLoader"



class MerchantProducts extends Component {
	state = {
		businessUniqueId: ''
	}
	componentDidMount(){
		let businessId = this.props.match.params.businessId;
		this.setState({businessUniqueId: businessId});
		this.props.actions.getProducts(businessId);
		
		this.props.actions.getProductDashboard(businessId);
	}

	render() {
		return (
			<> 
			<div className="grid grid-cols-12 gap-6">
				<div className="col-span-12 xxl:col-span-9 grid grid-cols-12 gap-6">
					<div className="col-span-12 mt-8">
						<Title businessUniqueId = {this.state.businessUniqueId} />

						<div className="col-span-12 grid grid-cols-12 gap-6 mt-8">
							<SummaryCard2 name="Products" description={this.props.reducer.totalProducts} />

							<SummaryCard2 name="Views" description={this.props.reducer.totalProductViews} />
						</div>
					</div>

					{this.props.reducer.loading ?
						<PageLoader />  : 
						<Products 
							products={this.props.reducer.products} 
							businessUniqueId = {this.state.businessUniqueId} 
							deleteProduct = {this.props.actions.deleteProduct}
						/>
					}
					
				</div>
			</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({ reducer: state.businessProductsReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MerchantProducts));
