import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import ContentLoader, { Facebook, Instagram } from 'react-content-loader';
import {Helmet} from "react-helmet";
import { Pagination } from 'react-laravel-paginex';

import * as Actions from './actions/provider.actions';
import ClusterLogo from '../../../assets/images/logo/logoface.png';
import Layout from '../common/Layout';
import {
	getLocalAddress
} from '../../../helpers/location/LocalAddress';
import GoogleMap from "components/googleMap/googleMap";


class Provider extends Component{

    componentDidMount = async() => {
        this.fetchStorePaginated();
    }


    fetchStorePaginated = async () => {
        const { beforeFetchStore, fetchStore } = this.props.actions;
        const id = this.props.match.params.id;
        const page = this.page();
        
        const payload = {
            id, 
            page
        }

		await beforeFetchStore();
        fetchStore(payload);
    }


    page = () => {
        const urlString = window.location.href; 
        const url = new URL(urlString);
        const page = url.searchParams.get('page');
        return page ? page : 1;
    }


    /**
     * This function accepts the new page number from pagination package
     * and appends it to  url, and Reloads the page with that new URL.
     * The new URL will cause componentDidMount() to reload again
     * and use the new page parameter to load the Page again.
     */
    updatePage = (data) => {
        const url = new URL(window.location.href);
        url.searchParams.set('page', data.page);
        window.location.href = url;
    }

    
    render(){

        const route = 'product';
        const {
            business, store, products, pagination,
            loading, coordinates, status_code
        } = this.props.providerReducer;

        const userAddress = getLocalAddress() ? getLocalAddress().address : 'My Location';

        const streetView = (coordinates !== null && coordinates.lat !== null && coordinates.lng !== null) 
            ? `https://maps.googleapis.com/maps/api/streetview?location=`+coordinates.lat+`,`+coordinates.lng+`&size=456x456&key=AIzaSyCpBYUxrE97uVDHHCULEEE6VQObySRIA7I`
            : '';

        console.log('Baffling', streetView);    

        return(
            <Layout isSearchPage={false}>

                {(status_code == 200 && !loading && business) &&
                    <Helmet>
                        <title>{business.name}</title>
                    </Helmet>
                }

                <main className="main">

                    <nav aria-label="breadcrumb" className="breadcrumb-nav mb-2">
                        <div className="container">
                            {(status_code == 200 && !loading)
                            ?
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{business.name.substring(0,35)}...</li>
                                </ol>
                            :
                                <ContentLoader style={{width: '35%', height:'26px'}}>
                                    <rect x="0" y="3" rx="4" ry="4"  height="20" style={{width: '100%'}} />
                                </ContentLoader>  
                            }
                        </div>
                    </nav>

                    <div className="page-content">
                        <div className="container">
                            <div className="row">

                                <div className="col-lg-12">
                                    <h3 className="widget-title">
                                        
                                            {(!loading && status_code == 200 && business)
                                                ?
                                                <div style={{width: 'auto', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                                    <div style={{width: 'auto', display: 'inline-block'}}>
                                                        <a href={business.back_link} target="_blank" title={`Visit store on ${business.source.name}`}>
                                                            <img src={business.source.icon} style={{width: '30px'}} />
                                                        </a>    
                                                    </div>

                                                    <div style={{width: 'auto' , display: 'inline-block'}}>
                                                        <a href={business.back_link} target="_blank" title={`Visit store on ${business.source.name}`}>
                                                            <b>&nbsp;{business.name}</b>
                                                        </a>
                                                    </div>
                                                </div>
                                                :
                                                <ContentLoader style={{width: '100%', height:'20px'}}>
                                                    <rect x="0" y="0" rx="4" ry="4" height="20" style={{width: '100%'}} />
                                                </ContentLoader>        
                                            }
                                        
                                    </h3>
                                </div>
                            </div> 

                            <div className="row">   

                                <div className="col-lg-9">


                                    <div className="toolbox">

                                        {(!loading && status_code == 200) &&
                                            <div className="toolbox-left">
                                                <div className="toolbox-info">
                                                    Page <span>{pagination.current_page} of {pagination.last_page}</span> 
                                                </div>
                                            </div>
                                        }

                                    </div>


                                    

                                    <div className="products mb-3">
                                        
                                        
                                        {(!loading && status_code == 200)
                                        ?
                                        products.map(
                                        product =>     
                                        <div className="product product-list">
                                            <div className="row">
                                                <div className="col-6 col-lg-3">
                                                    <figure className="product-media">
                                                        <a href={`/${route}/${product.public_url}`}>
                                                            <div className="container-res">
                                                                <img src={`${product.images && product.images.length > 0 ? product.images[0].s3_bucket_url+product.images[0].url: ClusterLogo}`} alt="Product image" className="product-image" />
                                                            </div>
                                                        </a>
                                                    </figure>
                                                </div>

                                                <div className="col-6 col-lg-3 order-lg-last">
                                                    <div className="product-list-action">
                                                        <div className="product-price">
                                                            ₦ {(product.amount ?  product.amount : 0).toLocaleString()}
                                                        </div>
                                                        <div className="ratings-container">
                                                            <span className="ratings-text"><i className="fa fa-eye" style={{color:'#fcb941', fontWeight: 'bold'}}></i>  ( {product.views_count} Views )</span>
                                                        </div>

                                                        <div className="product-action">
                                                            
                                                                <img src={product.source.icon}/>
                                                            
                                                        </div>

                                                        <a href={`/${route}/${product.public_url}`} className="btn btn-primary"><span>View Item</span></a>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="product-body product-action-inner">
                                                        <h3 className="product-title"><a href={`/${route}/${product.public_url}`}>{product.name.substring(0, 55)}... </a></h3>

                                                        <div className="product-content">
                                                            <p>{product.description.substring(0, 300)}... </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        )

                                        :
                                        <>
                                            <ContentLoader style={{width: '100%', height:'124px'}}>
                                                <rect x="0" y="8" rx="4" ry="4" height="12" style={{width: '100%'}} />
                                                <rect x="0" y="28" rx="4" ry="4" height="12" style={{width: '100%'}} />
                                                <rect x="0" y="48" rx="4" ry="4" height="12" style={{width: '100%'}} />
                                                <rect x="0" y="68" rx="4" ry="4" height="12" style={{width: '100%'}} />
                                                <rect x="0" y="88" rx="4" ry="4" height="12" style={{width: '100%'}} />
                                                <rect x="0" y="108" rx="4" ry="4" height="12" style={{width: '100%'}} />
                                            </ContentLoader> 

                                            <ContentLoader style={{width: '100%', height:'40px'}}>
                                                <rect x="0" y="8" rx="4" ry="4" height="12" style={{width: '30%'}} />
                                                <rect x="0" y="28" rx="4" ry="4" height="12" style={{width: '30%'}} />
                                            </ContentLoader> 

                                            <ContentLoader style={{width: '100%', height:'124px'}}>
                                                <rect x="0" y="8" rx="4" ry="4" height="12" style={{width: '100%'}} />
                                                <rect x="0" y="28" rx="4" ry="4" height="12" style={{width: '100%'}} />
                                                <rect x="0" y="48" rx="4" ry="4" height="12" style={{width: '100%'}} />
                                                <rect x="0" y="68" rx="4" ry="4" height="12" style={{width: '100%'}} />
                                                <rect x="0" y="88" rx="4" ry="4" height="12" style={{width: '100%'}} />
                                                <rect x="0" y="108" rx="4" ry="4" height="12" style={{width: '100%'}} />
                                            </ContentLoader> 

                                            <ContentLoader style={{width: '100%', height:'40px'}}>
                                                <rect x="0" y="8" rx="4" ry="4" height="12" style={{width: '30%'}} />
                                                <rect x="0" y="28" rx="4" ry="4" height="12" style={{width: '30%'}} />
                                            </ContentLoader> 

                                            <ContentLoader style={{width: '100%', height:'124px'}}>
                                                <rect x="0" y="8" rx="4" ry="4" height="12" style={{width: '100%'}} />
                                                <rect x="0" y="28" rx="4" ry="4" height="12" style={{width: '100%'}} />
                                                <rect x="0" y="48" rx="4" ry="4" height="12" style={{width: '100%'}} />
                                                <rect x="0" y="68" rx="4" ry="4" height="12" style={{width: '100%'}} />
                                                <rect x="0" y="88" rx="4" ry="4" height="12" style={{width: '100%'}} />
                                                <rect x="0" y="108" rx="4" ry="4" height="12" style={{width: '100%'}} />
                                            </ContentLoader> 
                                        </>
                                        }


                                    </div>  

                                        

                                    <nav aria-label="Page navigation">
                                        
                                        {(!loading && status_code == 200 && pagination)
                                        ?
                                            (pagination.last_page >= pagination.current_page) &&    
                                                <Pagination buttonIcons={true} nextButtonIcon="icon-long-arrow-right" prevButtonIcon="icon-long-arrow-left" changePage={this.updatePage} data={pagination} />
                                            
                                        
                                        :
                                        <ContentLoader style={{width: '100%', height:'40px'}}>
                                            <rect x="0" y="0" rx="4" ry="4" height="40" style={{width: '50%'}} />
                                        </ContentLoader>        
                                        }

                                    </nav>

                                </div>


                                <aside className="col-lg-3 order-lg-first">
                                    <div className="sidebar sidebar-shop">
                                        <div className="widget widget-collapsible">
                                            <div>
                                                {(coordinates !== null && coordinates.lat !== null && coordinates.lng !== null) &&
                                                    
                                                    <img className="product-image" src={streetView} />
                                    
                                                }
                                            </div>

                                            <br />

                                            <div className="container-res">
                                                
                                                {coordinates !== null && coordinates.lat !== null && coordinates.lng !== null 
                                                ?
                                                    <GoogleMap userLocation={{lat_lng: { lat: coordinates.lat, lng: coordinates.lng }}} markers={[{ lat: coordinates.lat, long: coordinates.lng }]} radius={1} />
                                                :
                                                    <>
                                                        <span className="slider-loader"></span>
                                                    </>
                                                }
                                            
                                            </div>

                                        </div>

                                        <div className="widget widget-clean">
                                            {(!loading && status_code == 200 && business)
                                            ?
                                            <>{business.address}</>
                                            :
                                            <ContentLoader style={{width: '100%', height:'20px'}}>
                                                <rect x="0" y="0" rx="4" ry="4" height="20" style={{width: '90%'}} />
                                            </ContentLoader>        
                                            }
                                        </div>

                                        
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </main>

            </Layout>
        );
    }

}


const mapStateToProps = (state) => ({ providerReducer: state.providerReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Provider));