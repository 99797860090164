import React, {Component} from 'react';
import { Pagination } from 'react-laravel-paginex';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import * as Actions from './actions/view-business.actions';
import Layout from '../common/Layout';
import { TwinBubbles, BusinessProductsLoading } from '../common/widgets/Loaders';
import { getLocalToken } from '../../../helpers/authentication/token';
import Cluster from '../../../assets/images/logo/logoface.png';
import {
	customNotification,
	networkNotification
} from '../../../components/notifications/Notifications';

class ViewBusiness extends Component{


    async componentDidMount(){
        const { actions } = this.props;
        const { beforeFetchBusinessProducts, fetchBusinessProducts } = actions;

        const data = {
            page: this.getParameter('page', 1),
            pid: this.props.match.params.business_id
        };

        await beforeFetchBusinessProducts();
        fetchBusinessProducts(data);
    }


    handlePageChange = (data) => {
        this.setParameter('page', data.page);
    }

    setParameter = (parameterName, value) => {
        const url = new URL(window.location.href);
        url.searchParams.set(parameterName, value);
        window.location.href = url;
    }

    getParameter = (parameterName, defaultValue) => {
        const urlString = window.location.href; 
        const url = new URL(urlString);
        const param = url.searchParams.get(parameterName);
        return param ? param : defaultValue;
    }

    deleteItem = async (event, product) => {
        event.preventDefault();

        const { actions } = this.props;
        const { beforeDeleteBusinessProduct, deleteBusinessProduct } = actions;

        const data = {
            pid: product.pid
        };

        if(window.confirm(`Are you sure you want to delete ${product.name}, action is irreversable.`)){
            await beforeDeleteBusinessProduct(data.pid);
            await deleteBusinessProduct(getLocalToken(), data);
            let { status_code, messages } = this.props.dashboardBusinessProductsReducer;
            networkNotification(messages, status_code);
        }
    }


    render = () => {
        
        const { dashboardBusinessProductsReducer } = this.props;
        const { business, products, status_code, loading, pagination } = dashboardBusinessProductsReducer;
        const truncateElipsesStyle = { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' };

        console.log('reducers', dashboardBusinessProductsReducer)

        return(
            <Layout>
                
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/account/dashboard">Dashboard</a></li>
                        <li className="breadcrumb-item"><a href="/account/dashboard/businesses">Businesses</a></li>
                        <li className="breadcrumb-item active" aria-current="page">{business ? business.name: `fetching...`}</li>
                    </ol>
                </nav>


                {business &&
                <div className="card text-center mb-3">
                    <div className="card-body">
                        <h5 className="card-title">{business.name}</h5>
                        <p className="card-text">{business.description}</p>
                        <div className="btn-group" role="group">
                            
                        {dashboardBusinessProductsReducer.deleteBusinessLoading ?
                            <>
                                <button id="btnGroupDrop1" type="button" className="btn btn-sm btn-primary mr-2">
                                    Loading...
                                </button> 
                            </>
                            :
                            <>
                                <button id="btnGroupDrop1" type="button" className="btn btn-sm btn-primary dropdown-toggle mr-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Manage Business
                                </button>
                            </>
                        }
                            <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                <a className="dropdown-item" href={`/account/dashboard/businesses/edit/${business.pid}`}><i className="fa fa-pen"></i> Edit Business</a>
                                <a className="dropdown-item" href="#" onClick={()=>this.props.actions.deleteBusiness(business.unique_id, business.pid)}><i className="fa fa-trash"></i> Delete Business</a>
                            </div>
                        </div>

                        <a href={`/account/dashboard/businesses/view/${business.pid}/product/create`} className="btn btn-sm btn-success"><i className="fa fa-plus"></i> Create Item</a>
                    </div>
                    {!loading &&
                    <div className="card-footer text-muted">
                        {products.length} Item(s)
                    </div>
                    }
                </div>
                }


                <div className="row">

                    {loading &&
                         <BusinessProductsLoading />   
                    }

                    {!loading && status_code && products.length < 1 &&
                        <div className="col-sm-12 ">
                            <div className="card text-center p-5">
                                <div className="pt-5 pb-5 text-primary">
                                    Business does not have any Items, click the "Create Item" button to add Items to Business.
                                </div>
                            </div>
                        </div>
                    }

                    {products.map(
                        product =>
                        <div className="col-sm-6">
                            <div className="card mb-3">
                                <div className="row no-gutters">
                                    <div className="col-md-4 container-res">
                                        <img src={product.images[0] !== undefined ? `${product.images[0].s3_bucket_url}${product.images[0].url}` : Cluster}  className="card-img" alt="..." />
                                    </div>
                                    <div className="col-md-8">
                                        <div className="card-body">
                                            <h5 className="card-title" style={truncateElipsesStyle}>{product.name}</h5>
                                            <p className="card-text" style={{height: '100px', overflowY: 'scroll'}}>{product.description}</p>
                                            
                                            {product.loading 
                                            ?
                                                <button type="button" className="btn btn-sm btn-secondary">
                                                    <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                    Deleting...
                                                </button>

                                            :
                                                <div className="btn-group" role="group">
                                                    <button  id="btnGroupDrop1" type="button" className="btn btn-sm btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        Manage
                                                    </button>
                                                    <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                                        <a className="dropdown-item" href={`/account/dashboard/businesses/view/${product.business.pid}/product/edit/${product.pid}`}><i className="fa fa-pen"></i> Edit Item</a>
                                                        <a className="dropdown-item" onClick={(event) => this.deleteItem(event, product)}><i className="fa fa-trash"></i> Delete Item</a>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}


                </div>
 




                {pagination && !loading && status_code && pagination.total > 1 &&
                    <Pagination 
                        buttonIcons={true} 
                        nextButtonIcon="fa fa-chevron-right" 
                        prevButtonIcon="fa fa-chevron-left" 
                        changePage={this.handlePageChange} 
                        data={pagination} 
                    />
                }

            </Layout>
        );
    }
}

const mapStateToProps = (state) => ({ dashboardBusinessProductsReducer: state.dashboardBusinessProductsReducer});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewBusiness));
