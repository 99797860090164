import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import * as Actions from "./actions/viewProduct.action";
import Description from "./components/description";
import Stores from "./components/stores";
import ProductImages from "./components/productImages";
import Breadcrumb from "components/breadcrumb/breadcrumb";
import GoogleMapSingle from "components/googleMap/googleMapSingle"
import Slider from "components/slider/slider"
import ClusterLogo from 'assets/images/logo/logoface.png';
import {
	getLocalAddress
} from 'helpers/location/LocalAddress';
// import OtherImages from "./components/otherImages";

class Products extends Component {
	state = {
		productId: '',
		businessId : ''
	}

	componentDidMount(){
		let product_id = this.props.match.params.productId
		let business_id = this.props.match.params.businessId
		this.props.actions.getProduct(product_id)
		this.setState({productId: product_id, businessId: business_id})
	}

	render() {
		const { product, cordinates, loading } = this.props.productReducer;
		// const userAddress = getLocalAddress() ? getLocalAddress().address : 'My Location';

		return (
			product !== null ?
			
				<div className="content">

					<div className="col-span-12 mt-8">
						<Breadcrumb 
							title="View Product" 
							links={{
								Dashboard: "/dashboard", 
								Business: `/merchant-business/${this.state.businessId}`, 
								Products:`/merchant-products/${this.state.businessId}`, 
								"View Product": ""
							}} 
						/>
					</div>

					<div className="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6">

						<div className="col-span-12 lg:col-span-12 mt-0">
							<div className="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6 box mt-5">

								<ProductImages images={product.images}/>


									<div className="col-span-12 sm:col-span-6 lg:col-span-6 mt-8 pl-5 pr-5">
										<div className="block font-medium text-base mt-0">
											{product.name}
										</div> 
										<div className="text-gray-700 mt-2 mb-2">
											{product.description}
										</div>

										<div className="flex">
											<div>
												<div className="text-theme-20 text-lg xl:text-xl font-bold">₦{product.amount}</div>
												<div className="text-gray-600">price</div>
											</div>
											<div className="w-px h-12 border border-r border-dashed border-gray-300 mx-4 xl:mx-6"></div>
											<div>
												<div className="text-gray-600 text-lg xl:text-xl font-medium">{product.views_count}</div>
												<div className="text-gray-600">Views</div>
											</div>
										</div>
										
										<div className="flex items-center  border-t border-dashed border-gray-300  mt-5"></div>

										<div className="block font-medium text-base mt-0 pt-2">
											Business Name
										</div> 

										<div className="flex items-center  border-t border-dashed border-gray-300 mt-2"></div>

										<div className="block font-medium text-base mt-0 pt-2">
											<a href={`/business/${product.business.unique_id}`} >
												<span className="text-theme-20"><i className="fa fa-institution"></i> {product.business.name}</span>
											</a>
										</div> 

										<div className="text-gray-700 mt-2">
											{product.business.description}
										</div>

										<div className="flex items-center  border-t border-dashed border-gray-300 mt-2"></div>
										
										<div className="block font-medium text-base mt-0 pt-2">
											Contact Details
										</div> 

										<div className="mt-3 mb-5">
											<div className="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-1 box mt-5">
												<div className="col-span-12 sm:col-span-6 lg:col-span-6">
													<a href={`/edit-product/${product.business.unique_id}/${product.id}`} style={{borderRadius: 0}} className="button mr-2 mb-2 flex w-full items-center justify-center bg-theme-1 text-white"> 
														<i className="mr-2 fa fa-edit"></i> Edit 
													</a> 
												</div>

												<div className="col-span-12 sm:col-span-6 lg:col-span-6">
													<button  style={{borderRadius: 0}} className="button mr-2 mb-2 flex w-full items-center justify-center bg-theme-1 text-white">
														<a href={`/delete-product/${product.business.unique_id}/${product.id}`}> 
															<i className="mr-2 fa fa-trash"></i> Delete 
														</a>
													</button> 
												</div>

											</div>			
										</div>

									</div>

							</div>
						</div>


						<div className="col-span-12 sm:col-span-12 lg:col-span-12 mt-0">
							
							<div className="intro-y box p-5 mt-5">
								
								{cordinates[0].lat !== null && cordinates[0].lng !== null
								?
									<>					
										<GoogleMapSingle coordinates={cordinates[0]} mapHeightPercent={100} />
										<div className="mt-4">
											<div className="flex flex-col lg:flex-row items-center p-0">
												<div className="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0">
													<div className="text-gray-600 text-xs">Need help finding service provider?</div> 
												</div>

												{/* <div className="flex mt-4 lg:mt-0">
												<a href={`https://www.google.com/maps?saddr=${userAddress}&daddr=${coordinates.lat},${coordinates.lng}`} target="_blank" className="button button--sm text-white bg-theme-1 mr-2">Get Directions</a>
												</div> */}
											</div>
										</div>
									</>
								:
									<>					
										<div className="col-span-12 flex justify-center mt-5 p-20">
											<i className="fa fa-spin fa-spinner" style={{fontSize: '40px'}}></i>
										</div>
										<div className="text-gray-600 text-xs">Initializing maps...</div>
									</>
								}

							</div>
						</div>
					
					</div>
				
					
				</div>
			: "Loading ..." 
		);
	}
}

const mapStateToProps = (state) => ({ productReducer: state.merchantViewProductReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Products));
