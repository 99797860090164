import React from "react";
import {baseUrl} from "config"

export default function otherImage(props) {
	let product = props.product;
	return (
		<a
			href={`/product/${product.id}`}
			data-toggle="modal"
			data-target="#add-item-modal"
			className="intro-y block col-span-12 sm:col-span-4 lg:col-span-3 xxl:col-span-3"
		>
			<div className="box rounded-md p-3 relative zoom-in">
				<div className="flex-none pos-image relative block">
					<div className="pos-image__preview image-fit">
						<img alt={product.name} src={`${baseUrl}${product.image}`} />
					</div>
				</div>
			</div>
		</a>
	);
}
