import {
    BEFORE_FETCH_USER_SETTINGS,
    FETCH_USER_SETTINGS,
    NETWORK_ERROR_FETCHING_USER_SETTINGS,

    BEFORE_UPDATE_PASSWORD,
    UPDATE_PASSWORD,
    NETWORK_ERROR_UPDATING_PASSWORD
} from '../types/settings.types';

const initialState = {
    loading: false,
    status_code: null,
    messages: [],
    user: null,
    loading_password: false,
    status_code_password: null,
}

export default function(state = initialState, action){
    switch(action.type){
        case BEFORE_FETCH_USER_SETTINGS: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case FETCH_USER_SETTINGS: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message,
                user: action.payload.data.result.user,
            }); 
        }
        case NETWORK_ERROR_FETCHING_USER_SETTINGS: {
            return Object.assign({}, state, {
                loading: false,
                messages: action.payload.data.message,
                status_code: action.payload.status,
            });
        }


        case BEFORE_UPDATE_PASSWORD: {
            return Object.assign({}, state, {
                loading_password: true
            });
        }
        case UPDATE_PASSWORD: {
            return Object.assign({}, state, {
                loading_password: false,
                messages: action.payload.data.message,
                status_code_password: action.payload.status,
            }); 
        }
        case NETWORK_ERROR_UPDATING_PASSWORD: {
            return Object.assign({}, state, {
                loading_password: false,
                messages: action.payload.data.message,
                status_code_password: action.payload.status,
            });
        }
        default: {
            return state;    
        }     
    }
}