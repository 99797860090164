import {
    START_PROFILE_LOADING, 
    STOP_PROFILE_LOADING,
    START_PASSWORD_LOADING, 
    STOP_PASSWORD_LOADING,
    START_PROFILE_USER_LOADING,
    STOP_PROFILE_USER_LOADING,
    PROFILE_USER
} from './../types/profile.type';

let initialState = {
    profileLoading: false,
    passwordLoading: false,
    socialMediaLoading: false,
    profileUserLoading: false,
    profile:{}
}
export default function(state = initialState, action){
    switch(action.type){
        case START_PROFILE_LOADING:
            return {...state, profileLoading: true};
        case STOP_PROFILE_LOADING:
            return {...state, profileLoading: false};
        case START_PASSWORD_LOADING:
            return {...state, passwordLoading: true};
        case STOP_PASSWORD_LOADING:
            return {...state, passwordLoading: false};
        case START_PROFILE_USER_LOADING:
            return {...state, profileUserLoading: true};
        case STOP_PROFILE_USER_LOADING:
            return {...state, profileUserLoading: false};
        case PROFILE_USER:
            return {...state, profile: action.payload};
        default:
            return state;        
    }

}