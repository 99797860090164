import React from "react";
import OtherImage from "./otherImage";

export default function otherImages(props) {
	return (
		<div className="grid grid-cols-12 gap-5 mt-5 pt-5 border-t border-theme-5">
			{props.products.map((value, index) => {
				return <OtherImage key={`otherimg${index}`}  product={value}/>;
			})}
		</div>
	);
}
