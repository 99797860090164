import{
    BEFORE_CREATE_DASHBOARD_PRODUCT,
    CREATE_DASHBOARD_PRODUCT,
    NETWORK_ERROR_CREATING_DASHBOARD_PRODUCT
} from '../types/create-product.types';

const initialState = {
    loading: false,
    product: null,
    images: [],
    status_code: null,
    messages: [],
};

export default function(state = initialState, action){
    switch(action.type){
        case BEFORE_CREATE_DASHBOARD_PRODUCT: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case CREATE_DASHBOARD_PRODUCT: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message,
                product: action.payload.data.result.product
            }); 
        }
        case NETWORK_ERROR_CREATING_DASHBOARD_PRODUCT: {
            return Object.assign({}, state, {
                loading: false,
                messages: action.payload.data.message,
                status_code: action.payload.status,
            });
        }
        default: {
            return state;    
        }     
    }
}