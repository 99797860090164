import React from 'react'

export default function(props){
    return(
        <div className="intro-y box lg:mt-5" style={{display: props.displaySocialMedia}}>
            <div className="flex items-center p-5 border-b border-gray-200">
                <h2 className="font-medium text-base mr-auto">
                    Social Media
                </h2>
            </div>
            <div className="p-5">
                <div className="grid grid-cols-12 gap-5">
                    <div className="col-span-12 xl:col-span-12">
                        <div>
                            <label>Facebook</label>
                            <input type="text" className="input w-full border bg-gray-100 cursor-not-allowed mt-2" placeholder="Input text" value="facebook.com" disabled />
                        </div>
                        <div className="mt-3">
                            <label>Twitter</label>
                            <input type="text" className="input w-full border mt-2" placeholder="Input text" value="twitter.com" disabled />
                        </div>
                        
                        <div className="mt-3">
                            <label>Instagram</label>
                            <input type="text" className="input w-full border mt-2" placeholder="Input text" value="instagram.com" />
                        </div>
                    </div>
                    
                </div>
                <div className="flex justify-end mt-4">
                    <button type="button" className="button w-20 bg-theme-1 text-white ml-auto">Save</button>
                </div>
            </div>
        </div>
    )
}