import React from 'react';

const InvalidPasswordResetLink = () => {

    return(
        <>   
            <div className="intro-x text-3xl text-theme-6 font-medium text-center">Oops!</div>
            <div className="intro-x text-lg mt-3">Password reset link is invalid, please request for a new one.</div>

            <br />
            <a href="/forgot-password" className="intro-x button button--lg  border border-white mt-10">Request for a new password reset link.</a>
        </>
    );
}

export default InvalidPasswordResetLink;