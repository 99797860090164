import axios from 'axios';

import {
    BEFORE_FETCH_DASHBOARD_BUSINESS_PRODUCTS,
    FETCH_DASHBOARD_BUSINESS_PRODUCTS,
    NETWORK_ERROR_FETCHING_DASHBOARD_BUSINESS_PRODUCTS,

    BEFORE_DELETE_BUSINESS_PRODUCT,
    DELETE_BUSINESS_PRODUCT,
    NETWORK_ERROR_DELETING_BUSINESS_PRODUCT,
  
    DELETE_BUSINESS_LOADING,
    DELETE_BUSINESS,
    DELETE_BUSINESS_ERROR

} from '../types/view-business.types';

import { endpointV2 } from '../../../../config'; 
import { getRequest } from 'helpers/httpRequest/request';
import { customNotification } from 'components/notifications/Notifications';
import { getLocalToken } from 'helpers/authentication/token';



export const beforeFetchBusinessProducts = () => (
    { type: BEFORE_FETCH_DASHBOARD_BUSINESS_PRODUCTS }
)

export const fetchBusinessProducts = (data) => (
    async (dispatch) => {
        try{
            let token = getLocalToken()
            const response = await axios({
                method: 'get',
                url: `${endpointV2}fetch/products/${data.pid}?page=${data.page}`,
                headers: {
                    "content-type": 'application/json',
                    "Accept" : 'application/json',
                    "Authorization" : `Bearer ${token}`
                },
            });

            dispatch({ type: FETCH_DASHBOARD_BUSINESS_PRODUCTS, payload: response });

        }catch(e){
            dispatch({ type: NETWORK_ERROR_FETCHING_DASHBOARD_BUSINESS_PRODUCTS, payload: e.response});
        }
    }
)



export const beforeDeleteBusinessProduct = (pid) => (
    { type: BEFORE_DELETE_BUSINESS_PRODUCT, product_id: pid }
)

export const deleteBusinessProduct = (token, data) => (
    async (dispatch) => {
        try{
            const response = await axios({
                method: 'delete',
                url: `${endpointV2}product/${data.pid}`,
                headers: {
                    "content-type": 'application/json',
                    "Accept" : 'application/json',
                    "Authorization" : `Bearer ${token}`
                },
            });

            dispatch({ type: DELETE_BUSINESS_PRODUCT, product_id: data.pid, payload: response });

        }catch(e){
            dispatch({ type: NETWORK_ERROR_DELETING_BUSINESS_PRODUCT, product_id: data.pid, payload: e.response});
        }
    }
)

export const deleteBusiness = (unique_id, pid)=>{
    return (dispatch)=>{
        dispatch({type: DELETE_BUSINESS_LOADING})
        getRequest('v1', `delete/business/${unique_id}`).then((data)=>{
            
            dispatch({type: DELETE_BUSINESS, payload: data.result})
            customNotification(data.message, 'Delete Image', 'success')

            let fetchData = {};
            fetchData.pid = pid;
            fetchData.page = 1;
            fetchBusinessProducts(fetchData)
            window.location.href = "/account/dashboard/businesses"
            

        }).catch((error)=>{
            dispatch({type: DELETE_BUSINESS_ERROR})
            customNotification([error.message], 'Error', 'danger')

        })
    }
}

