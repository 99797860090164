import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import { store } from 'react-notifications-component';

import Layout from '../common/Layout';
import Jumbotron from '../common/Jumbotron';
import * as Actions from "./actions/login.actions";
import {getToken} from "helpers/authentication/token";
import {
	customNotification,
	networkNotification
} from '../../../components/notifications/Notifications';

import { 
    getLocalToken, 
    setLocalToken,
    clearLocalToken
} from '../../../helpers/authentication/token';


class Login extends Component {
	constructor(){
		super();
		this.state = {
			email : '',
			password : '',
		}
		this.login = this.login.bind(this)
		this.updateFormState = this.updateFormState.bind(this)
	}

	componentDidMount(){
		
		if(getToken() !== null){
			//window.location.href = "/"
		}
    }

	updateFormState(e){
		e.preventDefault();
		this.setState({[e.target.name]: e.target.value});
	}

	login = async (event) => {
		event.preventDefault();

		const { beforeLogin, login } = this.props.actions;
		await beforeLogin();
		await login({email: this.state.email, password: this.state.password});

        let { loginReducer } = this.props;
		let { status_code, messages, token } = loginReducer;
		networkNotification(messages, status_code);
		
		if(token){
			setLocalToken(token);
			window.location.href = "/account/dashboard";
		}
	}

	render() {

		const { email, password } = this.state;
		const { loginReducer } = this.props;
		const { loading } = loginReducer;

		return (
			<Layout>
				<div className="text-center py-5">
					<form className="form-signin">
						<a href="/">
							<img className="mb-4" src="/logo-black.png" alt="" height="45" />
						</a>
						<h1 className="h3 mb-3 font-weight-normal">Please Sign In</h1>
						<label for="inputEmail" className="sr-only">Email address</label>
						<input disabled={loading} type="email" onChange={this.updateFormState} id="inputEmail" className="form-control at-top" value={email} name="email" placeholder="Email address" required autofocus />
						<label for="inputPassword" className="sr-only">Password</label>
						<input disabled={loading}  type="password" onChange={this.updateFormState} id="inputPassword" className="form-control at-bottom" value={password} name="password" placeholder="Password" required />
						<div className="checkbox mb-3">
							<label>
							<input type="checkbox" value="remember-me" /> Remember me
							</label>
						</div>
						<button disabled={loading} className="btn btn-lg btn-danger btn-block" type="submit" onClick={this.login}>
							{loading?
								<><i className="fa fa-spinner fa-spin"></i> Authenticating...</>
							:
								<>Sign in</>
							}
						</button>

						<div className="row">
							<div className="col-md-6 col-sm-12">
								<p className="mt-3 text-muted">Don't have an account? <a href="/register">Register</a></p>
							</div>

							<div className="col-md-6 col-sm-12">
								<p className="mt-3 text-muted">Having Issues Signing In? <a href="/forgot-password">Recover Password?</a></p>
							</div>
						</div>
						
					</form>
				</div>

			</Layout>
		);
	}
}

const mapStateToProps = (state) => ({ loginReducer: state.loginReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
