import React from "react";
import {FileZone} from "components/fileUpload/dropZone";
import {PreviewImage} from "components/fileUpload/previewImage";

export default function FileForm(props) {
	
	return (
		<div className="intro-y box mt-5">
			<div className="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200">
				<h2 className="font-medium text-base mr-auto">
					Upload product images
				</h2>
				
			</div>
			<FileZone prop = {props} />

			<div className="intro-y grid grid-cols-12 gap-3 sm:gap-6 mt-5" id="previewImg">
				{props.images.length > 0 ? props.images.map((value, index)=>{
					return(
						<PreviewImage key={`pre${index}`} index={index} value={value} prop={props} />
						
					)
				}) : ''}
				
			</div>
		</div>
	);
}
