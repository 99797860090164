import axios from 'axios';

import {
    BEFORE_FETCH_USER_SETTINGS,
    FETCH_USER_SETTINGS,
    NETWORK_ERROR_FETCHING_USER_SETTINGS,

    BEFORE_UPDATE_PASSWORD,
    UPDATE_PASSWORD,
    NETWORK_ERROR_UPDATING_PASSWORD
} from '../types/settings.types';

import { endpointV2 } from '../../../../config'; 


export const beforeFetchUserSettings = () => (
    { type: BEFORE_FETCH_USER_SETTINGS }
);


export const fetchUserSettings = (token) => (
    async (dispatch) => {
        try{
            const response = await axios({
                method: 'get',
                url: `${endpointV2}user/data`,
                headers: {
                    "content-type": 'application/json',
                    "Accept" : 'application/json',
                    "Authorization" : `Bearer ${token}`
                },
            });

            dispatch({ type: FETCH_USER_SETTINGS, payload: response });

        }catch(e){
            dispatch({ type: NETWORK_ERROR_FETCHING_USER_SETTINGS, payload: e.response});
        }
    }
);




export const beforeUpdatePassword = () => (
    { type: BEFORE_UPDATE_PASSWORD }
);


export const updatePassword = (token, data) => (
    async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpointV2}user/data/update-password`,
                headers: {
                    "content-type": 'application/json',
                    "Accept" : 'application/json',
                    "Authorization" : `Bearer ${token}`
                },
                data: data
            });

            dispatch({ type: UPDATE_PASSWORD, payload: response });

        }catch(e){
            dispatch({ type: NETWORK_ERROR_UPDATING_PASSWORD, payload: e.response});
        }
    }
);