import axios from 'axios';

import {
    BEFORE_FETCH_DASHBOARD_EDIT_PRODUCT,
    FETCH_DASHBOARD_EDIT_PRODUCT,
    NETWORK_ERROR_FETCHING_DASHBOARD_EDIT_PRODUCT,

    BEFORE_UPDATE_DASHBOARD_PRODUCT,
    UPDATE_DASHBOARD_PRODUCT,
    NETWORK_ERROR_UPDATING_DASHBOARD_PRODUCT,

    BEFORE_UPDATE_DASHBOARD_PRODUCT_IMAGES,
    UPDATE_DASHBOARD_PRODUCT_IMAGES,
    NETWORK_ERROR_UPDATING_DASHBOARD_PRODUCT_IMAGES,
} from '../types/edit-product.types';

import { endpointV2 } from '../../../../config'; 


export const beforeFetchEditProduct = () => (
    {type: BEFORE_FETCH_DASHBOARD_EDIT_PRODUCT}
);

export const fetchEditProduct = (token, data) => (
    async (dispatch) => {
        try{
            const response = await axios({
                method: 'get',
                url: `${endpointV2}product/${data.pid}`,
                headers: {
                    "content-type": 'application/json',
                    "Accept" : 'application/json',
                    "Authorization" : `Bearer ${token}`
                },
            });

            dispatch({ type: FETCH_DASHBOARD_EDIT_PRODUCT, payload: response });

        }catch(e){
            dispatch({ type: NETWORK_ERROR_FETCHING_DASHBOARD_EDIT_PRODUCT, payload: e.response});
        }
    }
);


export const beforeUpdateProduct = () => (
    {type: BEFORE_UPDATE_DASHBOARD_PRODUCT}
);


export const updateProduct = (token, body) => (
    async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpointV2}product/${body.pid}`,
                headers: {
                    "content-type": 'application/json',
                    "Accept" : 'application/json',
                    "Authorization" : `Bearer ${token}`
                },
                data: body.data
            });



            dispatch({ type: UPDATE_DASHBOARD_PRODUCT, payload: response });

        }catch(e){
            dispatch({ type: NETWORK_ERROR_UPDATING_DASHBOARD_PRODUCT, payload: e.response});
        }
    }
);


export const beforeUpdateImages = () => (
    {type: BEFORE_UPDATE_DASHBOARD_PRODUCT_IMAGES}
);

export const updateProductImages = (token, body) => (
    async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpointV2}update/images/product/${body.pid}`,
                headers: {
                    "Accept" : 'application/json',
                    "Content-Type": 'multipart/form-data',
                    "Authorization" : `Bearer ${token}`,
                    //"X-Requested-With": "XMLHttpRequest"
                },
                data: body.formData
            });

            dispatch({ type: UPDATE_DASHBOARD_PRODUCT_IMAGES, payload: response });

        }catch(e){
            dispatch({ type: NETWORK_ERROR_UPDATING_DASHBOARD_PRODUCT_IMAGES, payload: e.response});
        }
    }
);