import CryptoJS from "crypto-js";
import {getToken} from "./../authentication/token";
let seperator = "*#";

export function encryptUserData(data, api_token){
    let stringData = data.id + seperator + data.firstname + seperator + data.lastname + seperator + data.email
    let ciphertext = CryptoJS.AES.encrypt(stringData, api_token).toString();
    sessionStorage.setItem("userData", ciphertext)
}

export function decryptUserData(){
    // Decrypt
    let userData = sessionStorage.getItem("userData")
    let apiToken = getToken();
    let bytes  = CryptoJS.AES.decrypt(userData, apiToken);
    let originalText = bytes.toString(CryptoJS.enc.Utf8);
    let dataArray = originalText.split(seperator)
    let dataObj = {id: dataArray[0], firstname: dataArray[1], lastname: dataArray[2], email: dataArray[3]}
    return dataObj;
}