import { MPRODUCT } from "./../types/viewProduct.type"

let initialState = {
    product: null,
    loading: false,
    cordinates: []
}
export default function productReducer(state = initialState, action){
    switch(action.type){
        case MPRODUCT:
            let payload = action.payload;
            return {...state, product: payload.product, cordinates: payload.cordinates}
        default:
            return state;
    }

}