import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";

export default function googleAddress(props){
    //props must have addresss, handleChange and handleSelect
    const { containerClassName, inputClassName } = props;

    return (
        
        <PlacesAutocomplete
            value={props.address}
            onChange={props.handleChange}
            onSelect={props.handleSelect}
            
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <>    
                    <div className={containerClassName}>    
                        <input
                            {...getInputProps({
                                placeholder: 'Enter address where you intend to find things...',
                                className: inputClassName,
                            })}
                            
                            value={props.address}
                        />

                    </div>  


                    <div className="show" style={{position: 'absolute', zIndex: 10000, width: '100%', backgroundColor: '#fff', color:'#333', height: 'auto', fontSize: '15px'}}>
                        
                        {loading && <i data-loading-icon="oval" className="w-8 h-8"></i>}

                        <nav className="side-nav">

                            <ul className="menu-vertical" style={{position: 'absolute', zIndex: 10000, width: '100%', backgroundColor: '#fff', color:'#333', height: 'auto', fontSize: '15px'}}>
                                {suggestions.map((suggestion, index) => {
                                    const className = suggestion.active ? '' : '';
                                    const style = suggestion.active
                                    ? { fontWeight: 'normal', cursor: 'pointer', color: '#ff4861' }
                                    : { fontWeight: 'normal', cursor: 'pointer' };

                                    return (

                                        <li key={index}>
                                            <a {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                                })}
                                            >
                                                <i className="icon-map-marker" style={{color: '#ff4861'}}></i> {suggestion.description}
                                            </a>
                                        </li>      
                                    )
                                })}
                            </ul>
                            
                        </nav>
                    </div> 

                </>
            )}

        </PlacesAutocomplete>

    )
				
}