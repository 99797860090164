import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import * as Actions from "./actions/stores.actions";
import Title from "./components/title";
import SummaryCard from "./components/summaryCard";
import Stores from "./components/stores";
import CircularLoader from "components/loaders/circularLoader";

class MerchantStores extends Component {
	componentDidMount(){
		this.props.actions.fetchBusinesses()
		this.props.actions.fetchStoreDashboard()
	}

	render() {
		let {reducer, actions} =  this.props;
		
		return (
			<div className="grid grid-cols-12 gap-6">
				<div className="col-span-12 xxl:col-span-9 grid grid-cols-12 gap-6">
					<div className="col-span-12 mt-8">
						<Title />

						<div className="col-span-12 grid grid-cols-12 gap-6 mt-8">
							<SummaryCard 
								name="Active" 
								description={
									reducer.businessStat.active === undefined ? 0 : reducer.businessStat.active
									} 
								loading={reducer.businessStatloading}
							/>

							<SummaryCard 
								name="Inactive" 
								description={
									reducer.businessStat.inactive === undefined ? 0 : reducer.businessStat.inactive
									}
								loading={reducer.businessStatloading} 
							/>
						</div>
					</div>
					{reducer.loading ? <CircularLoader /> :
					<Stores businesses = {reducer.businesses} deleteBusiness = {actions.deleteBusiness} />
					}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({reducer: state.mechantStoreReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MerchantStores));
