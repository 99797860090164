import axios from 'axios';
import {
    BEFORE_SEARCH,
    SEARCH,
    NETWORK_ERROR_SEARCHING
} from '../types/landing.types';

import {
    endpoint
} from '../../../../config';


export const beforeSearch = () => (
    { type: BEFORE_SEARCH }
);


export const search = (data) => (
    async (dispatch) => {
        try{
            const response = await axios({
                method: 'get',
                url: `${endpoint}search`,
                headers: {
                    "content-type": "application/json",
                    "Accept" : "application/json",
                },
                params: data
            });
            console.log('PRODUCTS:: ', response);
            dispatch({ type: SEARCH, payload: response });
        }catch(e){
            console.log('Error:: ', e.response);
            dispatch({ type: NETWORK_ERROR_SEARCHING, payload: e.response });
        }
    }
);