import React from "react";
import ReactDOM from "react-dom";
import ReactNotification from 'react-notifications-component';
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import reduxThunk from "redux-thunk";
import ThemeOneAppRoute from "./routes/ThemeOneRoute";
import ThemeTwoAppRoute from "./routes/ThemeTwoRoute";
import ThemeThreeAppRoute from './routes/ThemeThreeRoute';
import ThemeFourAppRoute from './routes/ThemeFourRoute';
import * as serviceWorker from "./serviceWorker";
import reducers from "./reducers";
import 'react-notifications-component/dist/theme.css'

export const store = createStore(reducers, {}, applyMiddleware(reduxThunk));

ReactDOM.render(
	<Provider store={store}>
		<ReactNotification />
		<ThemeTwoAppRoute />
		<ThemeOneAppRoute />
		<ThemeThreeAppRoute />
		<ThemeFourAppRoute />
	</Provider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
