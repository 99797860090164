import React, {Component} from "react"
import DisplayInformation from "./components/displayInformation";
import PasswordInformation from "./components/passwordInformation";
import SocialMediaInformation from "./components/socialMediaInformation";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "./actions/profile.action"

class Profile extends Component{

    constructor(){
        super();
        this.updateState = this.updateState.bind(this)
        this.updateProfile = this.updateProfile.bind(this)
        this.updatePassword = this.updatePassword.bind(this)
    }

    state = {
        displayProfile: 'block',
        displayPassword: 'none',
        displaySocialMedia: 'none',
        profileInfo:{
            firstname:'',
            lastname:'',
            phoneNumber:'',
            email: '',
            createdAt:''
        },
        passwordInfo:{
            password:'',
            newPassword: '',
            confirmPassword: ''
        },
        passwordInfoError:{
            password:'',
            newPassword: '',
            confirmPassword: ''
        }
    }

    updateState(e, type){
        if(type === 'profile'){
            this.setState({profileInfo: {[e.target.name]: e.target.value} })
        }else if(type === 'password'){
            this.setState({passwordInfo: {[e.target.name]: e.target.value} })
        }else if(type == 'passwordError'){
            this.setState({passwordInfoError: e})
        }    
    }

    displayTab(tab){
        switch(tab){
            case 'profile':
                this.setState({displayProfile:'block', displayPassword:'none', displaySocialMedia:'none'});
                break;
            case 'password':
                this.setState({displayProfile:'none', displayPassword:'block', displaySocialMedia:'none'});
                break;
            case 'socialMedia':
                this.setState({displayProfile:'none', displayPassword:'none', displaySocialMedia:'block'});
                break;
            default:
                this.setState({displayProfile:'block', displayPassword:'none', displaySocialMedia:'none'});         
        }

    }

    updateProfile(e){
        e.preventDefault();
        let formData = new FormData(e.target);
        this.props.actions.updateProfile(formData)
    }

    updatePassword(e){
        e.preventDefault();
        let formData = new FormData(e.target);
        this.props.actions.updatePassword(formData, this.updateState)
    }

    componentDidMount(){
        let result = this.props.actions.getUser() 
        result.then((data)=>{
            this.setState({
                profileInfo: {
                    firstname: data.firstname,
                    lastname: data.lastname,
                    email: data.email,
                    phoneNumber: data.phone_number,
                    createdAt: data.created_at
                }
            })
        }).catch((error)=>{
            
        })
    }

    render(){
        return(
            <div className="content">

                <div className="intro-y flex items-center mt-8">
                    <h2 className="text-lg font-medium mr-auto">
                        Update Profile
                    </h2>
                </div>
                <div className="grid grid-cols-12 gap-6">
                   
                    <div className="col-span-12 lg:col-span-4 xxl:col-span-3 flex lg:block flex-col-reverse">
                        <div className="intro-y box mt-5">
                            <div className="relative flex items-center p-5">
                                {/* <div className="w-12 h-12 image-fit">
                                    <img alt="Cluster" className="rounded-full" src="dist/images/profile-8.jpg" />
                                </div> */}
                                <div className="ml-4 mr-auto">
                                    <div className="font-medium text-base">{`${this.state.profileInfo.firstname} ${this.state.profileInfo.lastname}`}</div>
                                    <div className="text-gray-600">{this.state.profileInfo.createdAt}</div>
                                </div>
                               
                            </div>
                            <div className="p-5 border-t border-gray-200">
                                <span onClick={()=>this.displayTab('profile')} className="flex items-center text-theme-1 font-medium mouseClick" href=""> <i data-feather="activity" className="w-4 h-4 mr-2"></i> Personal Information </span>
                               
                                <span onClick={()=>this.displayTab('password')}  className="flex items-center mt-5 mouseClick" href=""> <i data-feather="lock" className="w-4 h-4 mr-2"></i> Change Password </span>
                            </div>
                            <div className="p-5 border-t border-gray-200">
                                <span onClick={()=>this.displayTab('socialMedia')}  className="flex items-center mt-5 mouseClick" href=""> <i data-feather="lock" className="w-4 h-4 mr-2"></i> Social Networks </span>
                            </div>
                            
                        </div>
                    </div>
                   
                    <div className="col-span-12 lg:col-span-8 xxl:col-span-9">

                        {this.props.reducers.profileUserLoading ? <span className="fa fa-spinner fa-spin"></span> :
                        
                            <DisplayInformation 
                                displayProfile={this.state.displayProfile} 
                                updateProfile={this.updateProfile} 
                                profileInfo={this.state.profileInfo} 
                                updateState = {this.updateState}
                                loading={this.props.reducers.profileLoading}
                            />
                        }

                        <PasswordInformation 
                            displayPassword={this.state.displayPassword} 
                            updatePassword = {this.updatePassword} 
                            passwordInfo = {this.state.passwordInfo}
                            loading = {this.props.reducers.passwordLoading}
                            updateState = {this.updateState}
                            passwordInfoError = {this.state.passwordInfoError}
                        />
                        
                        <SocialMediaInformation displaySocialMedia={this.state.displaySocialMedia} />
                        
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateTopProps = function(state){
    return {reducers: state.profileReducer}
}
const mapActionsToProps = function(dispatch){
    return{actions: bindActionCreators(actions, dispatch)}
}
export default connect(mapStateTopProps, mapActionsToProps)(Profile);