import {
    BEFORE_FETCH_QUICK_VIEW,
    FETCH_QUICK_VIEW,
    NETWORK_ERROR_FETCHING_QUICK_VIEW
} from '../types/google-map.types';

let initialState = {
    quick_view: null,
    loading: false,
    status_code: null,
    messages: []
}

export default function productReducer(state = initialState, action){
    switch(action.type){
        case BEFORE_FETCH_QUICK_VIEW: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case FETCH_QUICK_VIEW:{
            return Object.assign({}, state, {
                loading: false,
                quick_view: action.payload.data.result.quick_view,
                status_code: action.payload.status,
                messages: action.payload.data.message
            });
        }
        case NETWORK_ERROR_FETCHING_QUICK_VIEW: {
            console.log('PAYLOAD:', action.payload);
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message
            });
        }    
        default:
            return state;
    }

}