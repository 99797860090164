import { store } from 'react-notifications-component';


function formatAlertMessage(message){
  let alertMessage = '';
  if(Array.isArray(message)){
    for(let x = 0; x< message.length; x++){
        alertMessage += message[x];
    }
  }else{
    alertMessage = message
  }
  return alertMessage;
}

export function popAlert(type, title, message){
  
    let alertMessage = formatAlertMessage(message)
    store.addNotification({
        title: title,
        message: alertMessage,
        type: type,
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
          showIcon: true
        }
    });

}