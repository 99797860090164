import { combineReducers } from "redux";

import logoutEventReducer from './components/logoutEvent/reducers/logout-event.reducer';
import loginReducer from './pages/theme-four/login/reducers/login.reducer';
import verifyEmailReducer from './pages/theme-four/verify-email/reducers/verify-email.reducer'
import registerReducer from './pages/theme-four/register/reducers/register.reducer';
import forgotPasswordReducer from './pages/theme-four/forgot-password/reducers/forgot-password.reducer';
import passwordResetReducer from './pages/theme-four/password-reset/reducers/password-reset.reducer';
import resendVerificationReducer from './pages/theme-four/resend-verification/reducers/resend-verification.reducer';
import landingReducer from './pages/user/landing/reducers/landing.reducer';
import homeReducer from './pages/theme-two/home/reducers/home.reducer';
import productReducer from './pages/theme-two/product/reducers/product.reducer';
import providerReducer from './pages/theme-two/provider/reducers/provider.reducer';
import storeReducer from "./pages/user/stores/reducers/stores.reducer";
import productReducers from "./pages/user/products/reducers/products.reducer";
import createStoreReducer from "./pages/merchant/createStore/reducers/createStores.reducer";
import createProductReducer from "./pages/merchant/createProduct/reducers/createProduct.reducer";
// import dashboardReducer from "./pages/merchant/dashboard/reducers/dashboard.reducer";
import mechantStoreReducer from "./pages/merchant/stores/reducers/stores.reducer";
import categoryReducer from "helpers/category/reducers/category.reducer";
import slotReducer from "helpers/slot/reducers/slot.reducer";
import imageReducer from "helpers/images/reducers/updateImage.reducer";
import businessProductsReducer from "./pages/merchant/products/reducers/product.reducer";
import merchantViewStoreReducer from "./pages/merchant/viewStore/reducers/viewStore.reducer";
import merchantViewProductReducer from "./pages/merchant/viewProduct/reducers/viewProduct.reducer";
import profileReducer from "./pages/profile/reducers/profile.reducer";

import popupLoginReducer from './pages/theme-two/common/popup-login/reducers/popup-login.reducer';
import popupRegisterReducer from './pages/theme-two/common/popup-register/reducers/popup-register.reducer';
import accountDropdownReducer from './pages/theme-two/common/account-dropdown/reducers/account-dropdown.reducer';
import googleMapReducer from './components/googleMap/reducers/google-map.reducer';

import dashboardBusinessesReducer from './pages/theme-three/businesses/reducers/businesses.reducer';
import dashboardBusinessProductsReducer from './pages/theme-three/view-business/reducers/view-business.reducer';
import dashboardReducer from "./pages/theme-three/dashboard/reducers/dashboard.reducer";

import createBusinessReducer from "./pages/theme-three/create-business/reducers/createBusiness.reducer"
import dashboardEditProductReducer from './pages/theme-three/edit-product/reducers/edit-product.reducer';
import imageThumbnailsWidgetReducer from './pages/theme-three/common/widgets/image-thumbnails/reducers/image-thumbnails.reducer';

import dashboardCreateProductReducer from './pages/theme-three/create-product/reducers/create-product.reducer';
import dashboardUserSettingsReducer from './pages/theme-three/settings/reducers/settings.reducer';

import editBusinessReducer from "./pages/theme-three/edit-business/reducers/edit-business.reducer"




export default combineReducers({
	logoutEventReducer,
	loginReducer, 
	verifyEmailReducer,
	registerReducer,
	forgotPasswordReducer,
	passwordResetReducer,
	resendVerificationReducer,
	homeReducer,
	productReducer,
	providerReducer,
	landingReducer,
	storeReducer,
	productReducers,
	createStoreReducer,
	createProductReducer,
	dashboardReducer,
	mechantStoreReducer,
	merchantViewStoreReducer,
	merchantViewProductReducer,
	businessProductsReducer,
	slotReducer,
	categoryReducer,
	imageReducer,
	profileReducer,

	popupLoginReducer,
	popupRegisterReducer,
	accountDropdownReducer,

	googleMapReducer,

	dashboardBusinessesReducer,
	dashboardBusinessProductsReducer,

	createBusinessReducer,
	dashboardEditProductReducer,
	imageThumbnailsWidgetReducer,
	dashboardCreateProductReducer,

	dashboardUserSettingsReducer,
	editBusinessReducer

});
