import {
    START_PROFILE_LOADING, 
    STOP_PROFILE_LOADING, 
    START_PASSWORD_LOADING,
    STOP_PASSWORD_LOADING,
    START_PROFILE_USER_LOADING,
    STOP_PROFILE_USER_LOADING,
    PROFILE_USER
} from "./../types/profile.type";
import axios from "axios";
import {endpoint} from "config";
import {getToken} from "helpers/authentication/token";
import {popAlert} from "helpers/alert/popUpAlert";
import {handleError} from "helpers/error/error";


export function updateProfile(data){
    return async (dispatch)=>{
        dispatch({type: START_PROFILE_LOADING})
        let token = getToken();

        axios.post(`${endpoint}user/update/profile`, data, {
            headers:{"Authorization": `Bearer ${token}`,"content-type": "application/json"}
        })
        .then((response)=>{
            dispatch({type: STOP_PROFILE_LOADING})
            let responseData = response.data
            if(responseData.status === 'success'){
                popAlert("success", "Success", responseData.message)
            }else{
                popAlert("danger", "Error", responseData.message)
            }

        }).catch((error)=>{
            dispatch({type: STOP_PROFILE_LOADING})
            handleError(error)
        })

    }
}


export function updatePassword(data, updateState){
    return async (dispatch)=>{
        dispatch({type: START_PASSWORD_LOADING})
        let token = getToken();

        axios.post(`${endpoint}user/update/password`, data, {
            headers:{"Authorization": `Bearer ${token}`,"content-type": "application/json"}
        })
        .then((response)=>{
            dispatch({type: STOP_PASSWORD_LOADING})
            let responseData = response.data
            if(responseData.status === 'success'){
                popAlert("success", "Success", responseData.message)
                updateState({
                    password:'',
                    newPassword: '',
                    confirmPassword: ''
                }, 'passwordError')
            }else{
                popAlert("danger", "Error", responseData.message)
                if(responseData.result !== null){
                    let reponseResult = responseData.result;
                    let objKeys = Object.keys(reponseResult);

                    let errorObj = {};
                    objKeys.forEach(element => {
                        errorObj[element] = reponseResult[element][0]; 
                    });
                    updateState(errorObj, 'passwordError')
                    
                }
            }

        }).catch((error)=>{
            dispatch({type: STOP_PASSWORD_LOADING})
            handleError(error)
        })

    }
}

export function getUser(){
    return async (dispatch)=>{
        dispatch({type: START_PROFILE_USER_LOADING})
        let token = getToken();

        return axios.get(`${endpoint}user/data`, {
            headers:{"Authorization": `Bearer ${token}`,"content-type": "application/json"}
        })
        .then((response)=>{
            dispatch({type: STOP_PROFILE_USER_LOADING})
            let responseData = response.data
            if(responseData.status === 'success'){
                dispatch({type: PROFILE_USER, payload: responseData.result})
                let responseResult = responseData.result;
                popAlert("success", "Success", responseData.message)
                return responseResult;                
            }else{
                popAlert("danger", "Error", responseData.message)
            }

        }).catch((error)=>{
            dispatch({type: STOP_PROFILE_USER_LOADING})
            handleError(error)
        })

    }
}