import React from "react";
import CircularImage from 'assets/images/loaders/circular.gif';

export default function(){
    return(
        <div className="pageLoader">
            <center>
                <img alt='Loading...' src={CircularImage}  style={{height:"40px"}}/>
            </center>
        </div>
    );
}