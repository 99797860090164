import axios from 'axios';
import {endpoint} from 'config';
import {
    BEFORE_FETCH_PRODUCT,
    FETCH_PRODUCT,
    NETWORK_ERROR_FETCHING_PRODUCT
} from './../types/products.types';

export const beforeFetchProduct  = () => (
    { type: BEFORE_FETCH_PRODUCT }
)

export const fetchProduct = (id) => (
    async (dispatch) => {
        try{
            const response = await axios({
                method: 'get',
                url: `${endpoint}market/product/${id}`,
                headers: {
                    "content-type": "application/json",
                    "Accept" : "application/json",
                }
            });
            console.log('PRODUCT', response);
            dispatch({ type: FETCH_PRODUCT, payload: response });

        }catch(e){
            dispatch({ type: NETWORK_ERROR_FETCHING_PRODUCT, payload: e.response });
        }
    }
);