import React from "react";
import Breadcrumb from "components/breadcrumb/breadcrumb"

export default function title(props) {
	return (
		<>
			<Breadcrumb 
				title="Products" 
				links={{
					Dashboard: "/dashboard", 
					Businesses: "/merchant-businesses", 
					Business: `/merchant-business/${props.businessUniqueId}`, 
					Products:""
					}} 
			/>
			
			<div className="intro-y flex items-center h-10">
				<a href={`/create-product/${props.businessUniqueId}` } className="ml-auto flex text-theme-1">
					<button className="button text-white bg-theme-1 shadow-md mr-2">
						Create Product
					</button>
				</a>
			</div>
		</>
	);
}
