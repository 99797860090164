import { networkNotification, customNotification } from "components/notifications/Notifications";
import { getRequest, postRequest } from "helpers/httpRequest/request";
import { BUSINESS_CATEGORY, BUSINESS_CATEGORY_ERROR, BUSINESS_CATEGORY_LOADING, CREATE_BUSINESS, CREATE_BUSINESS_ERROR, CREATE_BUSINESS_LOADING } from "../types/createBusiness.type";

export function getBusinessCategory(){
    return (dispatch)=>{
        dispatch({type: BUSINESS_CATEGORY_LOADING});
        getRequest('v1', 'fetch/categories').then((data)=>{
            dispatch({type: BUSINESS_CATEGORY, payload: data.result})
        }).catch((error)=>{
            dispatch({type: BUSINESS_CATEGORY_ERROR})
            console.log('error', error)
        })
    }
}

export function createBusiness(data){
    return (dispatch)=>{
        dispatch({type: CREATE_BUSINESS_LOADING});
        postRequest('v2', 'create/business', data).then((data)=>{
            dispatch({type: CREATE_BUSINESS, payload: data.result})
            console.log("data", data)
            customNotification([data.message], 'Business Created', 'success')
            window.location.href = "/account/dashboard/businesses/view/"+data.result.pid

        }).catch((error)=>{
            dispatch({type: CREATE_BUSINESS_ERROR})
            
            error.response.data.message !== undefined ?
                networkNotification(error.response.data.message, error.status)
                :
                networkNotification([error.message], error.status)

        })
    }
}

