import React from 'react';


const Brand = () => {

    return(
        <div className="owl-carousel mb-5 owl-simple" data-toggle="owl"
            data-owl-options='{
                        "nav": false,
                        "dots": true,
                        "margin": 30,
                        "loop": false,
                        "responsive": {
                            "0": {
                                "items":2
                            },
                            "420": {
                                "items":3
                            },
                            "600": {
                                "items":4
                            },
                            "900": {
                                "items":5
                            },
                            "1024": {
                                "items":6
                            },
                            "1280": {
                                "items":6,
                                "nav": true,
                                "dots": false
                            }
                        }
                    }'>
            <a href="#" className="brand">
                <img src="/themetwo/assets/images/brands/1.png" alt="Brand Name" />
            </a>

            <a href="#" className="brand">
                <img src="/themetwo/assets/images/brands/2.png" alt="Brand Name" />
            </a>

            <a href="#" className="brand">
                <img src="/themetwo/assets/images/brands/3.png" alt="Brand Name" />
            </a>

            <a href="#" className="brand">
                <img src="/themetwo/assets/images/brands/4.png" alt="Brand Name" />
            </a>

            <a href="#" className="brand">
                <img src="/themetwo/assets/images/brands/5.png" alt="Brand Name" />
            </a>

            <a href="#" className="brand">
                <img src="/themetwo/assets/images/brands/6.png" alt="Brand Name" />
            </a>

            <a href="#" className="brand">
                <img src="/themetwo/assets/images/brands/7.png" alt="Brand Name" />
            </a>
        </div>

    );
}


export default Brand;