import React from "react";
import CircularLoader from 'assets/images/loaders/circular.gif';


export default function(){
    return(
        <div className="col-span-12 xl:col-span-4 mt-6">
            <center>
                <img alt='Loading...' src={CircularLoader}  style={{height:"40px"}}/>
            </center>
        </div>
    );
}