import React from "react";
import MobileMenu from "./../components/mobileMenu/mobileMenu";
import TopBar from "./../components/topNav/topBar";
import TopNav from "./../components/topNav/topNav";

import ThemeOneWrapper from '../themeWrappers/ThemeOneWrapper';


export default function userRoute(props) {
	let bodyTag = document.getElementsByTagName("body");
	bodyTag[0].setAttribute("className", "app");

	return (
		<ThemeOneWrapper>
			<MobileMenu />
			<TopBar />
			<TopNav /> 
			<props.component />
		</ThemeOneWrapper>
	);
}
