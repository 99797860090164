import React, {Component} from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Dropzone from 'react-dropzone';
import {DropzoneArea} from 'material-ui-dropzone';
import Button from '@material-ui/core/Button';

import * as Actions from './actions/edit-product.actions';
import { getLocalToken } from '../../../helpers/authentication/token';
import { EditProductLoading, ImageThumbnailLoading } from '../common/widgets/Loaders';
import ImageThumbnails from '../common/widgets/image-thumbnails/ImageThumbnails';
import Layout from '../common/Layout';
import {
	customNotification,
	networkNotification
} from '../../../components/notifications/Notifications';


class EditProduct extends Component{

    constructor(props){
        super(props);

        this.state = {
            name: '',
            amount: '',
            quantity: '',
            description: '',
            files: []
        };
    }

    async componentDidMount(){
        const { actions } = this.props;
        const { beforeFetchEditProduct, fetchEditProduct } = actions;

        const data = {
            pid: this.props.match.params.product_id
        };

        await beforeFetchEditProduct();
        await fetchEditProduct(getLocalToken(), data);

        let { dashboardEditProductReducer } = this.props;
        let { status_code, messages, product, images } = dashboardEditProductReducer;

        if(status_code === 200){
            this.setState({
                name: product.name,
                description: product.description,
                quantity: product.quantity,
                amount: product.amount,
                images: images
            });
            
        }else{
            networkNotification(messages, status_code);
        }

    }


 


    updateItem = async (event) => {
        event.preventDefault();

        const { actions } = this.props;
        const { beforeUpdateProduct, updateProduct } = actions;
        const { name, amount, quantity, description } = this.state;

        const payload = {
            pid: this.props.match.params.product_id,
            data: {
                name, amount, quantity, description
            }
        };

        await beforeUpdateProduct();
        await updateProduct(getLocalToken(), payload);

        let { dashboardEditProductReducer } = this.props;
        let { status_code, messages, product, images } = dashboardEditProductReducer;

        if(status_code === 200){
            this.setState({
                name: product.name,
                description: product.description,
                quantity: product.quantity,
                amount: product.amount,
                images: images
            });
        }
        networkNotification(messages, status_code);
    }


    updateImages = async() => {
        const { actions } = this.props;
        const { beforeUpdateImages, updateProductImages } = actions;

        let formData = new FormData();

        this.state.files.forEach((element, i) => {
            formData.append('images[]', element)
        });

        const payload = {
            pid: this.props.match.params.product_id,
            formData: formData
        };

        await beforeUpdateImages();
        await updateProductImages(getLocalToken(), payload);

        let { dashboardEditProductReducer } = this.props;
        let { status_code, messages, product, images } = dashboardEditProductReducer;

        this.setState({images: images});

        networkNotification(messages, status_code);
    }


    handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    handleImageChange = (files) => {
        console.log('FILES: ', files);
        this.setState({
          files: files
        });
    }


    getUpdateFromImagesThumbnail = (images) => {
        this.setState({images: images});
    }
 
    
    render = () => {

        let { loading, loading_update, loading_image_upload, status_code, product} = this.props.dashboardEditProductReducer;
        const businessId = this.props.match.params.business_id;

        const { name, amount, quantity, description, files } = this.state;
        const { images } = this.state;

        return(
            <Layout>
                
                
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/account/dashboard">Dashboard</a></li>
                        <li className="breadcrumb-item"><a href="/account/dashboard/businesses">Businesses</a></li>
                        {!loading && product
                        ?
                            <li className="breadcrumb-item"><a href={`/account/dashboard/businesses/view/${product.business.pid}`}>{product.business.name}</a></li>
                        :
                            <li className="breadcrumb-item"><a href={`/account/dashboard/businesses/view/${businessId}`}>Go Back</a></li>  
                        }
                        <li className="breadcrumb-item active" aria-current="page">{product ? product.name: `fetching...`}</li>
                    </ol>
                </nav>
                

 

                <div className="row">

                    {loading &&
                        <EditProductLoading />
                    }

                    {!loading && product && status_code &&
                        <div className="col-sm-12">

                            <div className="card">
                                <div className="card-body">

                                    
                                    <form onSubmit={this.updateItem}>
                                        <div className="row">

                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group">
                                                    <label for="exampleFormControlInput1">Name</label>
                                                    <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" disabled={loading_update} onChange={this.handleChange} name="name" value={name} />
                                                </div>
                                                <div className="form-group">
                                                    <label for="exampleFormControlSelect1">Quantity</label>
                                                    <select className="form-control" id="exampleFormControlSelect1" disabled={loading_update} onChange={this.handleChange} name="quantity" value={quantity}>
                                                        <option>0</option>
                                                        <option>1</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                        <option>4</option>
                                                        <option>5</option>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label for="exampleFormControlSelect2">Price</label>
                                                    <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" disabled={loading_update} onChange={this.handleChange} name="amount" value={amount} />
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-6">    
                                                <div className="form-group">
                                                    <label for="exampleFormControlTextarea1">Description</label>
                                                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="8" disabled={loading_update} onChange={this.handleChange} name="description" value={description} />
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-12"> 
                                                <button className="btn btn-primary my-2 my-sm-0 pull-right" type="submit" disabled={loading_update}>
                                                    {loading_update
                                                    ?
                                                        <>
                                                            <span className="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                                                            <span className="visually-hidden"> Updating...</span>
                                                        </>
                                                    :
                                                        <>
                                                            <i className="fa fa-pen"></i> Update Item 
                                                        </>
                                                    }
                                                </button>
                                            </div>
                                        </div>        
                                    </form>
                                    
                                </div>
                            </div>
                            
                        </div>
                    }


                   <div className="col-md-12 mt-5 mb-5">
                        <div className="card">
                            <div class="card-header">
                                <b>Manage Item Photos</b>
                            </div>
                            <div className="card-body pb-5">

                                <div className="row">
                                    <div className="col-md-5 mt-5">
                                        <div className="card">
                                            <div className="card-body">
                                                {!loading && status_code && product && 
                                                    <>
                                                        <DropzoneArea 
                                                            filesLimit={6}
                                                            onChange={this.handleImageChange.bind(this)} 
                                                        />
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <button className="btn btn-primary" onClick={this.updateImages} disabled={loading_image_upload || files.length < 1}>
                                                
                                                {loading_image_upload
                                                    ?
                                                        <>
                                                            <span className="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                                                            <span className="visually-hidden"> Uploading...</span>
                                                        </>
                                                    :
                                                        <>
                                                            <i className="fa fa-send"></i> Upload Selected Photos
                                                        </>
                                                }
                                            </button>
                                        </div>
                                    </div>

                                    <div className="col-sm-7 mt-5">
                                        {!loading && product
                                            ?
                                            <ImageThumbnails images={images} key={(Date.now())} getUpdateFromImagesThumbnail={this.getUpdateFromImagesThumbnail}/>
                                            :
                                            <div className="card">
                                                <div className="card-body pb-5">
                                                    <div className="row">
                                                        <ImageThumbnailLoading />
                                                    </div>
                                                </div>
                                            </div>        
                                        }
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>


                </div>
   
            </Layout>
        );
    }
}


const mapStateToProps = (state) => ({ dashboardEditProductReducer: state.dashboardEditProductReducer});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditProduct ));