import React from "react";

export default function breadcrumb(props) {
	let bread = props.links;
	let links = Object.values(bread);
	let titles = Object.keys(bread);
	return (
		<div className="intro-y flex items-center h-10">
			<h2 className="text-lg font-medium truncate mr-5">{props.title}</h2>

			<div className="breadcrumb ml-auto">
				{titles.length > 0 ? titles.map((value, index)=>
					<React.Fragment key={index}>
					{index !== 0 ? <i data-feather="chevron-right" className="breadcrumb__icon">  </i> : ''}
					<a href={`${links[index]}`}  className={links.length - 1 !== index ? "breadcrumb--active" : ''}>{value}</a>
					</React.Fragment>
				)
				:''}
					
			</div>
		</div>
	);
}
