import axios from "axios"
import {endpoint, endpointV2} from "config";

import {
    BEFORE_REGISTER_NEW_USER,
    REGISTER_NEW_USER,
    NETWORK_ERROR_REGISTERING_USER
} from "../types/register.types"

export const beforeRegister = () => (
    { type: BEFORE_REGISTER_NEW_USER, payload: true }
);

export const register = (data) => (
    async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpointV2}user/register`,
                headers: {
                    "content-type": "application/json",
                    "Accept" : "application/json",
                },
                data
            });
            dispatch({ type: REGISTER_NEW_USER, payload: response });
        }catch(e){
            dispatch({ type: NETWORK_ERROR_REGISTERING_USER, payload: e.response });
        }
    }
);
