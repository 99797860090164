import {
    BEFORE_LOGIN_FULL_PAGE,
    LOGIN_FULL_PAGE,
    NETWORK_ERROR_LOGGING_IN_FULL_PAGE,
    SHOWALERT,
    DISMISSALERT
} from '../types/login.types';

const initialState = {
    loading: false,
    status_code: null,
    messages: [],
    alertMessage: [],
	alertStatus: ''
}

export default function(state = initialState, action){
    switch(action.type){
        case BEFORE_LOGIN_FULL_PAGE: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case LOGIN_FULL_PAGE: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message,
                token: action.payload.data.result.token
            }); 
        }    
        case NETWORK_ERROR_LOGGING_IN_FULL_PAGE: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message,

            });
        }
        case SHOWALERT:
            let payload = action.payload;
            return  Object.assign({}, state, {
                alertStatus: payload.status, alertMessage:payload.message, loading: false
            })
        case DISMISSALERT:
            return  Object.assign({}, state, {
                alertStatus: ''
            });
        default:
            return state;         
    }
}