/**
 * Stores the address for search
 * @param {string} address | Suggested from google api
 * @param {Object} latLng  | lat lng object returned by google api
 */
export const setLocalAddress = (address, latLng) => {
    const payload = {
        address,
        lat_lng: latLng
    }
    
    localStorage.setItem('local_address', JSON.stringify(payload));
}

/**
 * get the current local address 
 * stored on the  user's browser.
 */
export const getLocalAddress = () => {
    if(localStorage.getItem('local_address')){
        return JSON.parse(localStorage.getItem('local_address'));
    }else{
        return null;
    }
}

export const clearLocalAddress = () => {
    localStorage.removeItem('local_address');
}
