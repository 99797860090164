import { STARTLOADING, SHOWALERT, DISMISSALERT, STOPLOADING} from "./../types/createStore.types";

let initialState= {
    loading:false,
    alert: {
        show:false,
        status:'',
        message:''
    }
}

export default function(state = initialState, action){
    switch (action.type){
        case STARTLOADING:
            return {...state, loading: true};
        case STOPLOADING:
            return {...state, loading: false};
        case SHOWALERT:
            let data = action.payload;
            return {...state, alert:{show: true, status: data.status,  message: data.message}  };
        case DISMISSALERT:
            return {...state, alert:{show: false, status: '', message: ''}};
        default:
            return state;
    }
}