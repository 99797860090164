import React from 'react'


export default function(props){

    return(
        
        <div className="intro-y box lg:mt-5" style={{display: props.displayProfile}}>
            <div className="flex items-center p-5 border-b border-gray-200">
                <h2 className="font-medium text-base mr-auto">
                    Personal Information
                </h2>
            </div>
            <div className="p-5">
                <div className="grid grid-cols-12 gap-5">
                    {/* <div className="col-span-12 xl:col-span-4">
                        <div className="border border-gray-200 rounded-md p-5">
                            <div className="w-40 h-40 relative image-fit cursor-pointer zoom-in mx-auto">
                                <img className="rounded-md" alt="Cluster" src="dist/images/profile-8.jpg" />
                                <div title="Remove this profile photo?" className="tooltip w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-theme-6 right-0 top-0 -mr-2 -mt-2"> <i data-feather="x" className="w-4 h-4"></i> </div>
                            </div>
                            <div className="w-40 mx-auto cursor-pointer relative mt-5">
                                <button type="button" className="button w-full bg-theme-1 text-white">Change Photo</button>
                                <input type="file" className="w-full h-full top-0 left-0 absolute opacity-0" />
                            </div>
                        </div>
                    </div> */}
                    
                    <div className="col-span-12 xl:col-span-12">
                        <form onSubmit={(e)=>props.updateProfile(e)} method="post">
                            
                            <div>
                                <label>First Name</label>
                                <input 
                                    name="firstname" 
                                    type="text" 
                                    className="input w-full border bg-gray-100 cursor-not-allowed mt-2" 
                                    placeholder="Input Firstname" 
                                    value={props.profileInfo.firstname}
                                    onChange={(e)=>props.updateState(e, 'profile')}
                                />
                            </div>

                            <div className="mt-3">
                                <label>Last Name</label>
                                <input 
                                    name="lastname" 
                                    type="text" 
                                    className="input w-full border bg-gray-100 cursor-not-allowed mt-2" 
                                    placeholder="Input Lastname" 
                                    value={props.profileInfo.lastname} 
                                    onChange={(e)=>props.updateState(e, 'profile')}
                                />
                            </div>

                            <div className="mt-3">
                                <label>Email</label>
                                <input type="email" className="input w-full border bg-gray-100 cursor-not-allowed mt-2" placeholder="Input Email" value={props.profileInfo.email} disabled />
                            </div>

                            <div className="mt-3">
                                <label>Phone Number</label>
                                <input 
                                    name="phoneNumber" 
                                    type="text" 
                                    className="input w-full border bg-gray-100 cursor-not-allowed mt-2" 
                                    placeholder="Input Phone Number" 
                                    value={props.profileInfo.phoneNumber}
                                    onChange={(e)=>props.updateState(e, 'profile')}
                                />
                            </div>  

                            <div className="flex justify-end mt-4">
                                {
                                props.loading ?
                                 <button className="button w-20 bg-theme-1 text-white ml-auto">
                                    <span className="fa fa-spinner fa-spin"></span>
                                </button>:
                                <button type="submit" className="button w-20 bg-theme-1 text-white ml-auto">Save</button>
                                }
                            </div>
                            
                        </form>
                    </div>

                    

                    
                </div>
            </div>
        </div>
                        
    )

}