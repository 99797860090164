import { 
    BEFORE_FETCH_PRODUCT,
    FETCH_PRODUCT,
    NETWORK_ERROR_FETCHING_PRODUCT
 } from "./../types/products.types"

let initialState = {
    product: null,
    loading: false,
    coordinates: null,
    status_code: null,
    messages: []
}

export default function productReducer(state = initialState, action){
    switch(action.type){
        case BEFORE_FETCH_PRODUCT: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case FETCH_PRODUCT:{
            return Object.assign({}, state, {
                loading: false,
                product: action.payload.data.result,
                coordinates: {
                    lat: action.payload.data.result.lat,
                    lng: action.payload.data.result.long
                },
                status_code: action.payload.status
            });
        }
        case NETWORK_ERROR_FETCHING_PRODUCT: {
            console.log('PAYLOAD:', action.payload);
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: [action.payload.statusText]
            });
        }    
        default:
            return state;
    }

}