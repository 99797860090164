import React from "react"

export default function alert(props){
    
    let errkey = 0;
    let theme = props.status === 'success' ? 9 : 6;
    return (props.status !== "" ? 
        <div className={`rounded-md items-center px-5 py-4 mb-2 border border-theme-${theme} text-theme-${theme}`}> 
            <svg onClick={props.dismissAlert} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x w-4 h-4 ml-auto">
                <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg> 
            { Array.isArray(props.message) ? 
            props.message.map((value)=>{
                errkey++
                return <p key={`err${errkey}`} className="md-3">{value}</p>
            })
            : props.message }	
        </div>
    :'')
    
    
}