import React from "react";
import profileImage from "assets/images/profile/profile.jpg";

export default function topBar() {
	return (
		<div className="top-bar">
			<div className="-intro-x breadcrumb mr-auto hidden sm:flex">
				<a href="/" className="">
					Cluster
				</a>
				<i data-feather="chevron-right" className="breadcrumb__icon"></i>
				<a href="/dashboard" className="breadcrumb--active">
					Dashboard
				</a>
			</div>

			<div className="intro-x dropdown w-8 h-8 relative">
				<div className="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in">
					<img alt="Cluster" src={profileImage} />
				</div>
				<div className="dropdown-box mt-10 absolute w-56 top-0 right-0 z-20">
					<div className="dropdown-box__content box bg-theme-38 text-white">
						<div className="p-4 border-b border-theme-40">
							<div className="font-medium">Guest</div>
						</div>
						<div className="p-2">
							<a
								href="/profile"
								className="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 rounded-md"
							>
								<i data-feather="user" className="w-4 h-4 mr-2"></i> Profile
							</a>
							
							<a
								href="/password-reset"
								className="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 rounded-md"
							>
								<i data-feather="lock" className="w-4 h-4 mr-2"></i> Reset
								Password
							</a>
							
						</div>
						<div className="p-2 border-t border-theme-40">
							<a
								href="/logout"
								className="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 rounded-md"
							>
								<i data-feather="toggle-right" className="w-4 h-4 mr-2"></i>
								Logout
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
