import React, { Component } from "react";
import { BrowserRouter, Route} from "react-router-dom";
import { connect } from "react-redux";

import Home from '../pages/theme-two/home/Home';
import Product from '../pages/theme-two/product/Product';
import Provider from '../pages/theme-two/provider/Provider';
import PrivacyPolicy from '../pages/theme-two/privacy-policy/PrivacyPolicy';


class ThemeTwoRoute extends Component {

	constructor(props){
		super(props);

		this.state = {
			lineAwesome: null,
			bootStrap: null,
			magnificPopup: null,
			stYle: null,
			skinDemo13: null,
			demo13: null,
		}
	}

	componentDidMount(){
		/*
		import('../aa/assets/vendor/line-awesome/line-awesome/line-awesome/css/line-awesome.min.css')
		.then(({lineAwesome}) => {
			this.setState({lineAwesome});
		});

		import('../aa/assets/css/bootstrap.min.css')
		.then(({bootStrap}) => {
			this.setState({bootStrap});
		});

		import('../aa/assets/css/plugins/magnific-popup/magnific-popup.css')
		.then(({magnificPopup}) => {
			this.setState({magnificPopup});
		});

		import('../aa/assets/css/style.css')
		.then(({stYle}) => {
			this.setState({stYle});
		});

		import('../aa/assets/css/skins/skin-demo-13.css')
		.then(({skinDemo13}) => {
			this.setState({skinDemo13});
		});

		import('../aa/assets/css/demos/demo-13.css')
		.then(({demo13}) => {
			this.setState({demo13});
		});
		*/
	}

	render() {

		//const { lineAwesome, bootStrap, magnificPopup, stYle, skinDemo13, demo13 } = this.state;

		return (
			<BrowserRouter>
					<Route exact={true} path="/" render={(props) => <Home />} />
					<Route
						exact={true}
						path="/product/:id"
						render={(props) => <Product />}
					/>
					<Route
						exact={true}
						path="/provider/:id"
						render={(props) => <Provider />}
					/>
					<Route exact={true} path="/privacy-policy" render={(props) => <PrivacyPolicy />} />
			</BrowserRouter>
		);
	}
}

export default connect(null, null)(ThemeTwoRoute);
