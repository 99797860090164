import React, { Component } from 'react';

import SkyLight from 'react-skylight';

class Footer extends Component{


    constructor(props){
        super(props);

        this.state = {
            image: '',
            description: ''
        }
    }

    openModal = (type) => {
        this.footerDialog.show();

        const types = ['google-play-store', 'ios-app-store', 'cluster-entrepreneurs'];
        const val = types.includes(type) ? type: 'google-play-store';

        const data = {
            'google-play-store': {
                'image': 'https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png',
                'title': 'Google Play Store.',
                'description': 'The Cluster App is coming soon to the google play store. All our mobile users on the Android Platform will be able to add Cluster to their App list and enjoy finding products and services conveniently.'
            },
            'ios-app-store': {
                'image': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTY6IYBmFM2gp0egxV0JeL0RW1MYUwJ4K8wsQ&usqp=CAU',
                'title': 'IOS App Store.',
                'description': 'The Cluster App is coming soon to the IOS App Store. All our mobile users on the Apple Iphone will be able to add Cluster to their App list and enjoy finding products and services conveniently.'
            },
            'cluster-entrepreneurs': {
                'image': 'https://thumbor.forbes.com/thumbor/fit-in/1200x0/filters%3Aformat%28jpg%29/https%3A%2F%2Fspecials-images.forbesimg.com%2Fimageserve%2F5d95d03767dd830006a295b6%2F0x0.jpg',
                'description': 'Cluster Entrepreneurs.'
            }
        }

        this.setState({
            image: data[val].image,
            description: data[val].description
        });
    }

    render(){
        const { layoutComponentState } = this.props;
        const { image, description } = this.state;

        return(
            <footer className="footer footer-2">



                <SkyLight 
                    dialogStyles={{marginTop: '0', top: '10%'}} 
                    closeButtonStyle={{
                        cursor: 'pointer',
                        position: 'absolute',
                        fontSize: '2.1em',
                        right: '10px',
                        top: '0',
                        zIndex: '1'
                    }}
                    hideOnOverlayClicked 
                    ref={ref => this.footerDialog = ref}
                >
                    
                    <div className="col-md-12 col-lg-12">
                        <div className="p-5 pl-5 pr-5 text-center">
                            <div className="soon-content-wrapper">
                                <img src={image} alt="Logo" className="soon-logo mx-auto" />
                                <h1 className="soon-title">Coming Soon</h1>
                                <hr className="mt-2 mb-3 mt-md-3" />
                                <p>{description}</p>
                            </div>
                        </div>
                    </div>
                    
                    
                </SkyLight>  


                <div className="footer-middle border-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-lg-6">
                                <div className="widget widget-about">
                                    <img src="/themeone/assets/images/logo/logo-black.png" className="footer-logo" alt="Footer Logo" width="105" height="25" />
                                    <p>
                                        Join Cluster's initiative to promote commerce and assist Entrepreneurs starting or owning small and medium sized businesses. 
                                        While you focus on creating quality products and services, Cluster will help bring customers to your door step, so you can thrive and be successful.    
                                    </p>

                                    {/*
                                    <div className="widget-about-info">
                                        <div className="row">
                                            <div className="col-sm-6 col-md-4">
                                                <span className="widget-about-title">Got Question? E-Mail us</span>
                                                <a href="mailto:info@cluster.live">info@cluster.live</a>
                                            </div>

                                            <div className="col-sm-6 col-md-8">
                                                <span className="widget-about-title">Payment Method</span>
                                                <figure className="footer-payments">
                                                    <img src="/themetwo/assets/images/payments.png" alt="Payment methods" width="272" height="20" />
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                    */}

                                </div>
                            </div>

                            <div className="col-sm-4 col-lg-2">
                                <div className="widget">
                                    <h4 className="widget-title">Upcoming</h4>

                                    <ul className="widget-list">
                                        <li><a onClick={() => this.openModal('google-play-store')} style={{cursor: 'pointer'}}>App on Google Play Store</a></li>
                                        <li><a onClick={() => this.openModal('ios-app-store')} style={{cursor: 'pointer'}}>App on IOS App Store</a></li>
                                {/*<li><a onClick={() => this.openModal('cluster-entrepreneurs')} style={{cursor: 'pointer'}}>Cluster Entrepreneurs</a></li> */}
                                    </ul>
                                </div>
                            </div>

                            <div className="col-sm-8 col-lg-4">
                                <div className="widget">
                                    <h4 className="widget-title">Contact Us</h4>

                                    <ul className="widget-list">
                                        <li><a href="mailto:adverts@cluster.live">Advert Placement: adverts@cluster.live</a></li>
                                        <li><a href="mailto:support@cluster.live">Help & Support: support@cluster.live</a></li>
                                        <li><a href="mailto:issues@cluster.live">Report an issue: issues@cluster.live</a></li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="container">
                        <p className="footer-copyright">Copyright © {new Date().getFullYear()} Cluster Software. All Rights Reserved.</p>
                        
                        <ul className="footer-menu">
                            {/*<li><a href="#">Terms Of Use</a></li>*/}
                            <li><a href="/privacy-policy">Privacy Policy</a></li>
                        </ul>

                        <div className="social-icons social-icons-color">
                            <span className="social-label">Social Media</span>
                            <a href="https://www.facebook.com/clusterliveapp" className="social-icon social-facebook" title="Facebook" target="_blank"><i className="icon-facebook-f"></i></a>
                            <a href="https://www.instagram.com/clusterliveapp" className="social-icon social-instagram" title="Instagram" target="_blank"><i className="icon-instagram"></i></a>
                            <a href="https://www.twitter.com/clusterliveapp" className="social-icon social-twitter" title="Twitter" target="_blank"><i className="icon-twitter"></i></a>
                            {/*
                            <a href="#" className="social-icon social-youtube" title="Youtube" target="_blank"><i className="icon-youtube"></i></a>
                            <a href="#" className="social-icon social-pinterest" title="Pinterest" target="_blank"><i className="icon-pinterest"></i></a>
                            */}
                        </div>

                    </div>
                </div>
            </footer>
        );
    }

}

export default Footer;