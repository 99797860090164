import{
    BEFORE_PERFORM_VERIFICATION,
    PERFORM_VERIFICATION,
    NETWORK_ERROR_PERFORMING_VERIFICATION
} from '../types/verify-email.types';


const initialState = {
    loading: false, 
    status_code: null,
    email: null,
    messages: [],
}

export default function(state = initialState, action){
    switch(action.type){
        case BEFORE_PERFORM_VERIFICATION:{
            return Object.assign({}, state, {
                loading: true
            });
        }
        case  PERFORM_VERIFICATION:{
            return Object.assign({}, state, {
                loading: false,
                email: action.payload.data.result.email,
                messages: action.payload.data.message,
                status_code: action.payload.status
            });
        }
        case NETWORK_ERROR_PERFORMING_VERIFICATION:{
            return Object.assign({}, state, {
                loading: false,
                messages: action.payload.data.message,
                status_code: action.payload.status
            }); 
        }                      
        default:
            return state;           
    }
}