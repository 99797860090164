import React from "react";
import SearchResult from "./searchResult";

export default function backgroundSection(props) {
	const { items, route, sort, Paginator } = props;
	const results = items;

	return (
		<div className="pos intro-y grid grid-cols-12 gap-5 mt-5">
			<div className="intro-y col-span-12 lg:col-span-12">

				<div className="lg:flex intro-y">
					<select name="sort" onChange={sort} className="input input--lg box w-full lg:w-auto mt-3 lg:mt-0 ml-auto">
						<option value={1}>Sort By</option>
						<option value={1}>Nearest</option>
						<option value={2}>Farthest</option>
						<option value={3}>Most Recent</option>
						<option value={4}>Oldest</option>
						<option value={5}>A to Z</option>
						<option value={6}>Z to A</option>
					</select>
				</div>

				<div className="intro-y grid grid-cols-12 gap-6 mt-5">
					{results.map((value) => {
						return <SearchResult key={value.id} route={route} item={value} />;
					})}
				</div>
			</div>
		</div>
	);
}
