import React from "react";
import OtherImage from "./otherImage";

export default function otherImages() {
	let img = [1, 2, 3, 4];
	return (
		<div className="grid grid-cols-12 gap-5 mt-5 pt-5 border-t border-theme-5">
			{img.map((value) => {
				return <OtherImage key={`otherimg${value}`} />;
			})}
		</div>
	);
}
