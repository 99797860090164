import axios from "axios";
import {MSTORE} from "./../types/viewStore.type";
import {endpoint} from "config"

export function getStoreDetails(id){
    return (dispatch)=>{
        axios.get(`${endpoint}market/business/${id}/items`)
        .then((response)=>{
            let responseData = response.data;
            dispatch({type: MSTORE, payload: responseData.result})
        })
        .catch((error)=>{
            console.log(error)
        })
    }

}