import React from "react";
import ReactNotification from 'react-notifications-component';
import MobileMenu from "./../components/mobileMenu/mobileMenu";
import SideNav from "./../components/sideNav/sideNav";
import TopBar from "./../components/sideNav/topBar";
import 'react-notifications-component/dist/theme.css';
import {getToken} from "helpers/authentication/token";
import ThemeOneWrapper from '../themeWrappers/ThemeOneWrapper';


/**
 * here we remove all the unwanted styles from other themes,
 * to properly display theme-one (Mid-One)
 */
const removeOtherStyles = () => {
	const themeTwostyles = [1, 2, 3, 4, 5, 6, 7, 8];
	themeTwostyles.forEach(function(style){
		document.getElementById(`theme-two-${style}`).remove();
	})
	
	const themeThreeStyles = [1];
	themeThreeStyles.forEach(function(style){
		document.getElementById(`theme-three-${style}`).remove();
	})
}


/**
 * This is the Application Frame of theme-one
 * @param {*} props 
 */
export default function userRoute(props) {
	let bodyTag = document.getElementsByTagName("body");
	bodyTag[0].setAttribute("className", "app");
	if(getToken() === null){
		window.location.href = "/login"
	}

	removeOtherStyles();

	return (
		<ThemeOneWrapper>
			<ReactNotification />
			<MobileMenu />
			<div className="flex">
				<SideNav />
				<div className="content">
					<TopBar />
					<props.component />
				</div>
			</div>
		</ThemeOneWrapper>
	);
}
