import {
    BEFORE_FETCH_STORE,
    FETCH_STORE,
    NETWORK_ERROR_FETCHING_STORE
} from '../types/provider.types';

let initialState = {
    loading: false,
    store : null,
    business: null,
    coordinates: null,
    status_code: null,
    messages: [],
    products: [],
    pagination: null,
}

export default function storeReducer(state = initialState, action){
    switch(action.type){
        case BEFORE_FETCH_STORE: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case FETCH_STORE:{
            return Object.assign({}, state, {
                loading: false,
                store: action.payload.data.result.business,
                business: action.payload.data.result.business,
                coordinates: {
                    lat: action.payload.data.result.business.lat,
                    lng: action.payload.data.result.business.long
                },
                status_code: action.payload.status,
                products: action.payload.data.result.products.data,
                pagination: action.payload.data.result.products
            });
        }
        case NETWORK_ERROR_FETCHING_STORE: {
            console.log('PAYLOAD:', action.payload);
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: [
                    action.payload.statusText
                ]
            });
        }    
        default:
            return state;
    }

}