import React, { Component } from 'react';
import Cluster from '../../../../../assets/images/logo/logoface.png';

class ProductsCollection extends Component{

    truncateText = (input, size = 25) => {
        if(!input || input === null){
            return '...';
        }
        return input.length > size ? `${input.substring(0, (size - 3))}...` : input;
    }

    render(){

        const { items, route } = this.props;
        const truncateElipsesStyle = { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' };

        return(
            <>

                <div className="row">

                    {items.map(
                        item => (
                        <div className="col-12 col-md-2 col-lg-2">
                            <div className="product text-center">
                                <figure className="product-media">
                                    
                                    <img className="product-label" style={{width: '35px'}} src={`${item.source.icon}`} />
                                    <a href={`/${route}/${item.public_url}`}>
                                        {item.images.length > 0
                                        ?
                                        //<div className="product-image" style={{backgroundImage: `url(${item.images[0] !== undefined ? `${item.images[0].s3_bucket_url}${item.images[0].url}` : Cluster})` }}></div>
                                        <div className="container-res">
                                            <img alt={item.name} src={item.images[0] !== undefined ? `${item.images[0].s3_bucket_url}${item.images[0].url}` : Cluster}  />
                                        </div>
                                        :
                                        <img alt="Cluster" src={Cluster} style={{opacity: 0.3}} className="product-image" />
                                        }
                                    </a>

                                    {/*    
                                    <div className="product-action-vertical">
                                        <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                        <a href="#" className="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a>
                                        <a href="popup/quickView.html" className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></a>
                                    </div>

                                    <div className="product-action">
                                        <a href={`/${route}/${item.public_url}`} className="btn-product btn-cart" title="View Details"><span>View Details</span></a>
                                    </div>
                                    */}
                                    
                                </figure>

                                <div className="product-body" style={truncateElipsesStyle}>
                                    <div className="product-title" style={truncateElipsesStyle}>
                                        <a href={`/${route}/${item.public_url}`} className="ml-0" style={truncateElipsesStyle}>{item.name}</a>
                                    </div>
                                    <h3 className="product-cat" style={truncateElipsesStyle}>
                                        <a href={`/provider/${item.business.public_url}`} style={truncateElipsesStyle}>{item.business.name}</a>
                                    </h3>
                                    <div className="product-price" style={{fontWeight: 'bold'}}>
                                        {route==='product' ? `₦${item.amount ? Number((item.amount).toFixed(1)).toLocaleString(): `0.00`}`: ``} 
                                    </div>
                                    <div className="ratings-container">
                                        <span className="ratings-text">
                                            {/* <i className="fa fa-eye" style={{color:'#fcb941', fontWeight: 'bold'}}></i> */}
                                             ( {item.views_count} Views )
                                        </span>
                                    </div>
                                </div>
                            </div>  
                        </div>
                        )
                    )} 


                </div>


            </>
        );
    }

}

export default ProductsCollection;