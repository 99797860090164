import React from "react";

export default function inputForm(props) {
	return (
		
		<div className="intro-y box">
			
			<div className="p-5" id="input">
					<div>
						<label>Name</label>
						<input 
							name = 'name'
							value = {props.state.name}
							onChange = {(e)=>props.updateState('name', e.target.value)}
							type="text" className="input w-full border mt-2" placeholder="Enter Name" 
						/>
					</div>
					<div className="mt-3">
						<label>Description</label>
						<input 
							name = 'description'
							value = {props.state.description}
							onChange={(e)=>props.updateState('description', e.target.value)}
							type="text" className="input w-full border mt-2" placeholder="Enter Description" 
						/>
					</div>
					<div className="mt-3">
						<label>Amount</label>
						<input 
							name = 'amount'
							value = {props.state.amount}
							onChange={(e)=>props.updateState('amount', e.target.value)}
							type="number" className="input w-full border mt-2" placeholder="Enter Amount" 
						/>
					</div>
					<div className="mt-3">
						<label>Quantity</label>
						<input 
							name = 'quantity'
							value = {props.state.quantity}
							onChange={(e)=>props.updateState('quantity', e.target.value)}
							type="number" className="input w-full border mt-2" placeholder="Enter Quantity" 
						/>
					</div>
					<div className="mt-3">
						<label>Category</label>
						<select 
							name="category" 
							onChange={(e)=>props.updateState("category", e.target.value)} 
							value = {props.state.category} 
							className="input w-full border mt-2" placeholder="Select Category" >
								<option>Select Category</option>
								{props.categories.length > 0 ? 
									props.categories.map((value)=>{ 
										return <option key={`cat${value.id}`} value={value.id} >{value.name}</option> 
									})
								 : ''}
						</select>
					</div>
					<div className="mt-3">
						<label>Slot</label>
						<select 
							name="slot_id" 
							onChange={(e)=>props.updateState("slot_id", e.target.value)} 
							value = {props.state.slot_id} 
							className="input w-full border mt-2" placeholder="Select Slot" >
								<option>Select Slot</option>
								{props.slots.length > 0 ? 
									props.slots.map((value)=>{ 
										return <option key={`slot${value.id}`} value={value.id} >{value.qty}</option> 
									})
								 : ''}
						</select>
						
					</div>
													
			</div>

		</div>
	);
}
