import React from "react";

export default function(props){
    return (
        <div className="w-24 h-24 relative image-fit mb-5 mr-5 cursor-pointer zoom-in">
            <img className="rounded-md" alt="cluster" src={props.image.s3_bucket_url+props.image.url} />
            <div className="tooltip w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-theme-6 right-0 top-0 -mr-2 -mt-2 tooltipstered"> 
                <svg onClick={()=>props.deleteImage(props.id, props.image.id, props.type)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x w-4 h-4">
                    <line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line>
                </svg> 
            </div>
        </div>
    )
}