import axios from 'axios';

import{
    BEFORE_CREATE_DASHBOARD_PRODUCT,
    CREATE_DASHBOARD_PRODUCT,
    NETWORK_ERROR_CREATING_DASHBOARD_PRODUCT
} from '../types/create-product.types';

import { endpointV2 } from '../../../../config'; 


export const beforeCreateProduct = () => (
    {type: BEFORE_CREATE_DASHBOARD_PRODUCT}
);

export const createProduct = (token, body) => (
    async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpointV2}create/product/${body.pid}`,
                headers: {
                    "Accept" : 'application/json',
                    "Content-Type": 'multipart/form-data',
                    "Authorization" : `Bearer ${token}`
                },
                data: body.formData
            });

            dispatch({ type: CREATE_DASHBOARD_PRODUCT, payload: response });

        }catch(e){
            dispatch({ type: NETWORK_ERROR_CREATING_DASHBOARD_PRODUCT, payload: e.response});
        }
    }
);