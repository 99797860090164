import React from "react";
import ProductImage from "./productImage";

export default function productImages(props) {
	return (
		<div className="intro-y box mt-5">
			<div className="flex items-center px-5 py-3 border-b border-gray-200">
				<h2 className="font-medium text-base mr-auto">Product Images</h2>
				<button
					data-carousel="announcement"
					data-target="prev"
					className="slick-navigator button px-2 border text-white relative flex items-center text-gray-700 mr-2"
				>
					<i data-feather="chevron-left" className="w-4 h-4"></i>
				</button>
				<button
					data-carousel="announcement"
					data-target="next"
					className="slick-navigator button px-2 border text-white relative flex items-center text-gray-700"
				>
					<i data-feather="chevron-right" className="w-4 h-4"></i>
				</button>
			</div>
			<div className="slick-carousel py-5" id="announcement">
				<ProductImage product={props.product} />
			</div>
		</div>
	);
}
