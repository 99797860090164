import React from 'react';

const Banner = (props) => {

    return(
        <div className="banner banner-overlay">
            <a href="#">
                <img src="/themetwo/assets/images/demos/demo-13/banners/banner-1.jpg" alt="Banner" />
            </a>

            <div className="banner-content">
                <h4 className="banner-subtitle text-white"><a href="#">Weekend Sale</a></h4>
                <h3 className="banner-title text-white"><a href="#">Lighting <br />& Accessories <br /><span>25% off</span></a></h3>
                <a href="#" className="banner-link">Shop Now <i className="icon-long-arrow-right"></i></a>
            </div>
        </div>

    );
}

export default Banner;