/**
 * This function inspects the Search Page URL
 * and pull specified parameters from it. If it
 * does not exist, it accepts a default value to
 * be set and returned.
 * 
 * @param {*} parameterName 
 * @param {*} defaultValue 
 */
export const getParameter = (parameterName, defaultValue) => {
    const urlString = window.location.href; 
    const url = new URL(urlString);
    const param = url.searchParams.get(parameterName);
    return param ? param : defaultValue;
}

/**
 * When setting parameters on the URL, we will decide to append or 
 * do a total redirect based on the Page. This is because the search 
 * appears on multiple pages, but the search only happens on the Home Screen.
 * Whenever we detect that we are not on the home screen we append params to window.location.origin.
 * If we are on the home screen we update the concerned attributes on window.location.href
 * @param {*} parameters 
 * @param {*} isSearchPage 
 */
export const setParameters = async (parameters = [], isSearchPage) => {
    const url = new URL(isSearchPage ? window.location.href : window.location.origin);
    parameters.forEach(function(parameter){
        url.searchParams.set(parameter.name, parameter.value);
    });
    window.location.href = url;
}

/**
 * The function is triggered anytime the button on the main
 * Search form is clicked. It acts to sync all the parameters
 * coming from the form on the URL, and somewhat reload so that
 * the new parameters will be used to initialize the Page.
 * @param {*} fields 
 * @param {*} isSearchPage 
 */
export const onSearchFormUpdated = async (fields, isSearchPage) => {
    setParameters(fields, isSearchPage);
}