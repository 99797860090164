import {
    BEFORE_FETCH_DASHBOARD_BUSINESS_PRODUCTS,
    FETCH_DASHBOARD_BUSINESS_PRODUCTS,
    NETWORK_ERROR_FETCHING_DASHBOARD_BUSINESS_PRODUCTS,

    BEFORE_DELETE_BUSINESS_PRODUCT,
    DELETE_BUSINESS_PRODUCT,
    NETWORK_ERROR_DELETING_BUSINESS_PRODUCT,
  
    DELETE_BUSINESS_LOADING,
    DELETE_BUSINESS,
    DELETE_BUSINESS_ERROR
} from '../types/view-business.types';


const initialState = {
    loading: false,
    business: null,
    products: [],
    status_code: null,
    messages: [],
    pagination: null,

    deleteBusinessLoading: false
};

const addLoaderToObjects = (objects) => {
    objects.forEach(function(object){
        object['loading'] = false;
    });
    return objects;
}

export default function(state = initialState, action){
    switch(action.type){
        case BEFORE_FETCH_DASHBOARD_BUSINESS_PRODUCTS: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case FETCH_DASHBOARD_BUSINESS_PRODUCTS: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message,
                business: action.payload.data.result.business,
                products: addLoaderToObjects(action.payload.data.result.products.data),
                pagination: action.payload.data.result.products,
            }); 
        }
        case NETWORK_ERROR_FETCHING_DASHBOARD_BUSINESS_PRODUCTS: {
            return Object.assign({}, state, {
                loading: false,
                messages: action.payload.data.message,
                status_code: action.payload.status,
            });
        }
        case BEFORE_DELETE_BUSINESS_PRODUCT: {
            const index = state.products.findIndex(
                product => product.pid === action.product_id);  
            return Object.assign({}, state, {
                products: [
                    ...state.products.slice(0, index),
                    {
                        ...state.products[index],
                        loading: true        
                    },
                    ...state.products.slice(index + 1)
                ]
            });
        }
        case DELETE_BUSINESS_PRODUCT: {
            const index = state.products.findIndex(
                product => product.pid === action.product_id);

            return Object.assign({}, state, {
                status_code: action.payload.status,
                messages: action.payload.data.message,
                products: [
                    ...state.products.slice(0, index),
                    ...state.products.slice(index + 1)
                ]
            }); 
        }
        case NETWORK_ERROR_DELETING_BUSINESS_PRODUCT: {
            const index = state.products.findIndex(
                product => product.pid === action.product_id);

            return Object.assign({}, state, {
                products: [
                    ...state.products.slice(0, index),
                    {
                        ...state.products[index],
                        loading: false       
                    },
                    ...state.products.slice(index + 1)
                ],
                messages: action.payload.data.message,
                status_code: action.payload.status,
            });
        }
        case DELETE_BUSINESS_LOADING:
            return {...state, deleteBusinessLoading: true}
        case DELETE_BUSINESS:
            return {...state, deleteBusinessLoading: false}
        case DELETE_BUSINESS_ERROR:
            return {...state, deleteBusinessLoading: false}
        default: {
            return state;    
        }     
    }
}