import axios from 'axios';
import {
    BEFORE_SET_IMAGES_TO_REDUCER,
    SET_IMAGES_TO_REDUCER,
    BEFORE_DELETE_WIDGET_THUMBNAIL_IMAGE,
    DELETE_WIDGET_THUMBNAIL_IMAGE,
    NETWORK_ERROR_DELETING_WIDGET_THUMBNAIL_IMAGE
} from '../types/image-thumbnails.types';

import { endpointV2 } from '../../../../../../config';


export const beforeSetImagesToReducer = () => (
    { type: BEFORE_SET_IMAGES_TO_REDUCER }
)


export const setImagesToReducer = (images = []) => (
    { type: SET_IMAGES_TO_REDUCER, payload: {images} }
)


export const beforeDeleteImage = (data) => (
    { type: BEFORE_DELETE_WIDGET_THUMBNAIL_IMAGE, payload: data}
)


export const deleteImage = (token, data) => (
    async (dispatch) => {
        try{
            const response = await axios({
                method: 'delete',
                url: `${endpointV2}delete/images/${data.image_id}`,
                headers: {
                    "content-type": 'application/json',
                    "Accept" : 'application/json',
                    "Authorization" : `Bearer ${token}`
                },
            });

            dispatch({ type: DELETE_WIDGET_THUMBNAIL_IMAGE, payload: response });
        }catch(e){
            dispatch({ type: NETWORK_ERROR_DELETING_WIDGET_THUMBNAIL_IMAGE, payload: e.response });
        }
    }
)