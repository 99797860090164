import {
    BEFORE_LOGOUT_EVENT,
    LOGOUT_EVENT,
    NETWORK_ERROR_WITH_LOGGING_OUT_EVENT
} from '../types/logout-event.types';

const initialState = {
    loading: false,
    messages: [],
    status_code: null
}

export default function(state = initialState, action){
    switch(action.type){
        case BEFORE_LOGOUT_EVENT: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case LOGOUT_EVENT: {
            console.log('SUCCESS HERE', action.payload);
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message
            }); 
        }
        case NETWORK_ERROR_WITH_LOGGING_OUT_EVENT: {
            console.log('LOG HERE', action.payload);
            return Object.assign({}, state, {
                loading: false,
                messages: action.payload.data.message,
                status_code: action.payload.status,
            });
        }
        default: {
            return state;    
        }     
    }
}