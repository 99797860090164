import React from "react";
import Cluster from '../../../../assets/images/logo/logoface.png';
import ProductCard from '../../../../components/commonCards/ProductCard';


export default function formSection(props) {

	const { item, route} = props;
	
	return (
		<div className="intro-y col-span-12 sm:col-span-3 md:col-span-3 xxl:col-span-2">
            <div className="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in">


                <a href={`/${route}/${item.id}`} className="w-full file__icon file__icon--image mx-auto">
                    <div className="file__icon--image__preview image-fit">
						
                        {item.images.length > 0
                        ?
                        <img alt={item.name} src={item.images[0] !== undefined ? `${item.images[0].s3_bucket_url}${item.images[0].url}` : Cluster} />
                        :
                        <img alt="Cluster" src={Cluster} style={{opacity: 0.3}} />
                        }
                    </div>
                </a>
				<div className="block font-medium mt-4 text-center truncate"><span className="px-1 py-0 rounded-full border border-theme-1 text-theme-1 mr-1">{item.distance.toFixed(2)} km</span> {item.name} </div> 
                <div className="block text-gray-600 text-xs mt-0 text-center truncate">{item.description}..</div> 
                <div className="text-gray-600 text-xs text-center mt-2">
                    <a href={`/${route}/${item.id}`} style={{borderRadius: 0}} className="button mr-2 mb-2 flex w-full items-center justify-center bg-theme-1 text-white">{route==='product' ? `₦ ${item.amount ? Number((item.amount).toFixed(1)).toLocaleString(): `0.00`} -  View Details`: `View Business`} </a> 
                </div>

            </div>
        </div>
		
        
	);
}

/*
export default function formSection(props) {

	const { item, route} = props;
	
	return (
		<a
			href={`/${route}/${item.id}`}
			className="intro-y block col-span-12 sm:col-span-4 lg:col-span-2 xxl:col-span-2"
		>	
			<div className="box rounded-md p-3 relative zoom-in">
				<div className="flex-none pos-image relative block">
					<div className="pos-image__preview image-fit">
						<img alt="Midone Tailwind HTML Admin Template" src={`${baseUrl}${item.image}`} />
					</div>
				</div>
				<div className="block font-medium text-center truncate mt-3">
					{item.name}
				</div>
			</div>
		</a>
	);
}
*/

/*
<div className="intro-y blog col-span-12 md:col-span-4 box" key={item.id}>
	<a href={`/${route}/${item.id}`}>
		<div className="blog__preview image-fit">
			<img alt={item.name} className="rounded-t-md" src={item.images[0] !== undefined ? `${item.images[0].s3_bucket_url}${item.images[0].url}` : Cluster} />
			<div className="absolute w-full flex items-center px-5 pt-6 z-10">
				<div className="w-10 h-10 flex-none image-fit">
					<img alt={item.name} className="rounded-full" src={Cluster} />
				</div>
				<div className="ml-3 text-white mr-auto">
					<button className="font-medium">{item.distance.toFixed(2)} km</button> 
					<div className="text-xs">{new Date(item.created_at).toDateString()}</div>
				</div>
				<div className="dropdown relative ml-3">
					<button className="blog__action dropdown-toggle w-8 h-8 flex items-center justify-center rounded-full"> <i data-feather="more-vertical" className="w-4 h-4 text-white"></i> </button>
					<div className="dropdown-box mt-8 absolute w-40 top-0 right-0 z-20">
						<div className="dropdown-box__content box p-2">
							<button  className="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md"> <i data-feather="edit-2" className="w-4 h-4 mr-2"></i> Edit Post </button>
							<button  className="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md"> <i data-feather="trash" className="w-4 h-4 mr-2"></i> Delete Post </button>
						</div>
					</div>
				</div>
			</div>
			<div className="absolute bottom-0 text-white px-5 pb-6 z-10"> <span className="blog__category px-2 py-1 rounded">Category</span> <button className="block font-medium text-xl mt-3">{item.name}</button> </div>
		</div>
	</a>
	<div className="p-5 text-gray-700">{item.description}</div>
	<div className="flex items-center px-5 py-3 border-t border-gray-200">
		<a href="#/" className="intro-x w-8 h-8 flex items-center justify-center rounded-full border border-gray-500 text-gray-600 mr-2 tooltip" title="Bookmark"> <i data-feather="bookmark" className="w-3 h-3"></i> </a>
		<div className="intro-x flex mr-2">
			
			<a href="#/" className="intro-x w-8 h-8 flex items-center justify-center rounded-full bg-theme-14 text-theme-10 ml-auto tooltip" title="Share"> <i data-feather="share-2" className="w-3 h-3"></i> </a>
			
			{item.images.map(
				image => (
					<div key={image.id} className="intro-x w-8 h-8 image-fit -ml-4">
						<img className="rounded-full border border-white zoom-in tooltip" src={image.s3_bucket_url !== undefined && image.url !== undefined ? `${image.s3_bucket_url}${image.url}` : Cluster} alt="Cluster" />
					</div>
					)
				)
			}

		</div>
		<a href="#/" className="intro-x w-8 h-8 flex items-center justify-center rounded-full bg-theme-14 text-theme-10 ml-auto tooltip" title="Share"> <i data-feather="share-2" className="w-3 h-3"></i> </a>
		<a href="#/" className="intro-x w-8 h-8 flex items-center justify-center rounded-full bg-theme-1 text-white ml-2 tooltip" title="Download PDF"> <i data-feather="share" className="w-3 h-3"></i> </a>
	</div>
</div>
*/
