import {
    BEFORE_REGISTER,
    REGISTER,   
    NETWORK_ERROR_REGISTERING
} from '../types/popup-register.types';

const initialState = {
    loading: false,
    messages: [],
    status_code: null,
    user: null
}

export default function(state = initialState, action){
    switch(action.type){
        case BEFORE_REGISTER: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case REGISTER: {
            console.log('SUCCESS HERE', action.payload);
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message,
                user: action.payload.data.result.user
            }); 
        }
        case NETWORK_ERROR_REGISTERING: {
            console.log('LOG HERE', action.payload);
            return Object.assign({}, state, {
                loading: false,
                messages: action.payload.data.message,
                status_code: action.payload.status,
            });
        }
        default: {
            return state;    
        }     
    }
}