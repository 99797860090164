import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { customNotification } from '../../../../components/notifications/Notifications';
import { getLocalToken, clearLocalToken } from '../../../../helpers/authentication/token';

import * as Actions from './actions/account-dropdown.actions';


class AccountDropdown extends Component{

    constructor(props){
        super(props);
    }

    logout = async () => {
        const { onLogoutSuccessful, actions } = this.props;
        const { beforeLogout, logout } = actions;

        await beforeLogout();
        await logout({token: getLocalToken()});
        
        const { status_code } = this.props.accountDropdownReducer;
        if(status_code){
            //tell parent component about the Logout event
            onLogoutSuccessful();
        }
    }


    render = () => {

        const { messages, loading } = this.props.accountDropdownReducer;

        return (
                        
            <div className="dropdown cart-dropdown">

                <a href="/account/dashboard" style={{cursor: 'pointer'}}  className="dropdown-toggle">
                    <i className="icon-user"></i>
                    <span className="cart-txt">Your Profile</span>
                </a>

                {/*
                <>
                    <a style={{cursor: 'pointer'}}  className="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
                        <i className="icon-user"></i>
                        <span className="cart-txt">Your Profile</span>
                    </a>

                    <div className="dropdown-menu dropdown-menu-right">
                        

                        <div className="dropdown-cart-products mb-1">
                        
                            <div className="product">
                                <div className="text-primary">
                                    <a className="product-title text-primary" href="/account/dashboard"><i className="icon-user"></i> Account Dashboard</a>
                                </div>
                            </div> 

                            <div className="product">    
                                <div className="text-primary">
                                    <a className="product-title">Did you know that with your Cluster account, you can create your own Store?</a>
                                </div>
                            </div>

                        </div>

                        <div className="dropdown-cart-action">
                            <button disabled={loading} onClick={this.logout} style={{cursor: 'pointer'}} className="btn btn-primary">
                                {loading && <i className="fa fa-spinner fa-spin"></i>} 
                                <span>Logout</span><i className="icon-long-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </>
                */}
            </div>

        );
    }
}

const mapStateToProps = (state) => ({ accountDropdownReducer: state.accountDropdownReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountDropdown);