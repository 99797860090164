import React from "react";

export default function title() {
	return (
		<div className="intro-y flex items-center h-10">
			<h2 className="text-lg font-medium truncate mr-5">General Report</h2>
			<a href="#reload" className="ml-auto flex text-theme-1">
				<i data-feather="refresh-ccw" className="w-4 h-4 mr-3"></i>
				Reload Data
			</a>
		</div>
	);
}
