import React from "react";
import Store from "./store";
import StorePagination from "./storePagination";
import ErrorBlock from "components/error/errorBlock"

export default function Stores(props) {
	let businesses = props.businesses
	return (
		<div className="col-span-12 mt-6">
			{/* <div class="intro-y flex flex-col-reverse sm:flex-row items-center">
				<div class="w-full sm:w-auto relative mr-auto mt-3 sm:mt-0">
					<h2 className="text-lg font-medium truncate mr-5">Stores</h2>
				</div>
				<div class="w-full sm:w-auto flex">
					<button class="button text-white bg-theme-1 shadow-md mr-2">
						Create Store
					</button>
				</div>
			</div> */}

			{businesses.length > 0 ?
				<>
					<div className="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
						<table className="table table-report sm:mt-2">
							<thead>
								<tr>
									<th className="whitespace-no-wrap">IMAGES</th>
									<th className="whitespace-no-wrap">BUSINESS</th>
									<th className="text-center whitespace-no-wrap">STATUS</th>
									<th className="text-center whitespace-no-wrap">ACTIONS</th>
								</tr>
							</thead>
							<tbody>
								{businesses.map((value) => {
									return <Store key={value.id} {...value} deleteBusiness = {props.deleteBusiness} />;
								})}
							</tbody>
						</table>
					</div>
					{/* <StorePagination /> */}
				</>
				:
				<ErrorBlock message="No business found" />
			}
		</div>
	);
}
