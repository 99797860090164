import {BUSINESS_IMAGES, PRODUCT_IMAGES, UPDATE_IMAGE_LOADING} from './../types/updateImage.type'

const initialState = {
    businessImages: [],
    productImages: [],
    loading: false
}
export default function(state = initialState, action){
    switch(action.type){
        case BUSINESS_IMAGES:
            return {...state, businessImages: action.payload};
        case PRODUCT_IMAGES:
            return {...state, productImages: action.payload};
        case UPDATE_IMAGE_LOADING:
            return {...state, loading: action.payload}
        default:
            return state
    }

}