import {
    BEFORE_LOGOUT,
    LOGOUT,
    NETWORK_ERROR_LOGGING_OUT
} from '../types/account-dropdown.types';

const initialState = {
    loading: false,
    messages: [],
    status_code: null
}

export default function(state = initialState, action){
    switch(action.type){
        case BEFORE_LOGOUT: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case LOGOUT: {
            console.log('SUCCESS HERE', action.payload);
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message
            }); 
        }
        case NETWORK_ERROR_LOGGING_OUT: {
            console.log('LOG HERE', action.payload);
            return Object.assign({}, state, {
                loading: false,
                messages: action.payload.data.message,
                status_code: action.payload.status,
            });
        }
        default: {
            return state;    
        }     
    }
}