import React from "react";

export default function topNav() {
	return (
		<nav className="top-nav">
			{/*
			<ul>
				<li>
                    <a href="top-menu-dashboard.html" class="top-menu top-menu--active">
                        <div className="top-menu__icon"> <i data-feather="home"></i> </div>
                        <div className="top-menu__title"> Dashboard </div>
                    </a>
                </li>
				
			</ul>
			*/}
		</nav>
	);
}
