import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { connect } from "react-redux";


class ThemeTwoWrapper extends Component {

	constructor(){
		super();



	}

	componentDidMount(){

		/*
		const style = document.createElement("link");
		style.href = "/themetwo/assets/vendor/line-awesome/line-awesome/line-awesome/css/line-awesome.min.css";
		style.rel = "stylesheet";
		style.type = "text/css";
		document.head.appendChild(style);

		const style3 = document.createElement("link");
		style3.href = "/themetwo/assets/css/bootstrap.min.css";
		style3.rel = "stylesheet";
		style3.type = "text/css";
		document.head.appendChild(style3);

		const style4 = document.createElement("link");
		style4.href = "/themetwo/assets/css/plugins/owl-carousel/owl.carousel.css";
		style4.rel = "stylesheet";
		style4.type = "text/css";
		document.head.appendChild(style4);
		//


		const style7 = document.createElement("link");
		style7.href = "/themetwo/assets/css/plugins/magnific-popup/magnific-popup.css";
		style7.rel = "stylesheet";
		style7.type = "text/css";
		document.head.appendChild(style7);

		const style8 = document.createElement("link");
		style8.href = "/themetwo/assets/css/plugins/jquery.countdown.css";
		style8.rel = "stylesheet";
		style8.type = "text/css";
		document.head.appendChild(style8);


		const style5 = document.createElement("link");
		style5.href = "/themetwo/assets/css/style.css";
		style5.rel = "stylesheet";
		style5.type = "text/css";
		style5.id = "base";
		document.head.appendChild(style5);

		const style2 = document.createElement("link");
		style2.href = "/themetwo/assets/css/skins/skin-demo-13.css";
		style2.rel = "stylesheet";
		style2.type = "text/css";
		document.head.appendChild(style2);

		const style9 = document.createElement("link");
		style9.href = "/themetwo/assets/css/demos/demo-13.css";
		style9.rel = "stylesheet";
		style9.type = "text/css";
		document.head.appendChild(style9);
		*/
		

		/*
		<script src="assets/js/jquery.min.js"></script>
		<script src="assets/js/bootstrap.bundle.min.js"></script>
		<script src="assets/js/jquery.hoverIntent.min.js"></script>
		<script src="assets/js/jquery.waypoints.min.js"></script>
		<script src="assets/js/superfish.min.js"></script>
		<script src="assets/js/owl.carousel.min.js"></script>
		<script src="assets/js/bootstrap-input-spinner.js"></script>
		<script src="assets/js/jquery.magnific-popup.min.js"></script>
		<script src="assets/js/jquery.plugin.min.js"></script>
		<script src="assets/js/jquery.countdown.min.js"></script>
		<!-- Main JS File -->
    	<script src="assets/js/main.js"></script>
    	<script src="assets/js/demos/demo-13.js"></script>
		*/



		/*
		const script1 = document.createElement("script");
		script1.src = "/themetwo/assets/js/jquery.min.js";
		script1.async = true;
		document.body.appendChild(script1);

		const script2 = document.createElement("script");
		script2.src = "/themetwo/assets/js/jquery.hoverIntent.min.js";
		script2.async = true;
		document.body.appendChild(script2);

		const script3 = document.createElement("script");
		script3.src = "/themetwo/assets/js/jquery.hoverIntent.min.js";
		script3.async = true;
		document.body.appendChild(script3);

		const script4 = document.createElement("script");
		script4.src = "/themetwo/assets/js/jquery.waypoints.min.js";
		script4.async = true;
		document.body.appendChild(script4);

		const script5 = document.createElement("script");
		script5.src = "/themetwo/assets/js/superfish.min.js";
		script5.async = true;
		document.body.appendChild(script5);

		const script6 = document.createElement("script");
		script6.src = "/themetwo/assets/js/owl.carousel.min.js";
		script6.async = true;
		document.body.appendChild(script6);

		const script7 = document.createElement("script");
		script7.src = "/themetwo/assets/js/bootstrap-input-spinner.js";
		script7.async = true;
		document.body.appendChild(script7);

		const script8 = document.createElement("script");
		script8.src = "/themetwo/assets/js/jquery.magnific-popup.min.js";
		script8.async = true;
		document.body.appendChild(script8);

		const script9 = document.createElement("script");
		script9.src = "/themetwo/assets/js/jquery.plugin.min.js";
		script9.async = true;
		document.body.appendChild(script9);

		const script10 = document.createElement("script");
		script10.src = "/themetwo/assets/js/jquery.countdown.min.js";
		script10.async = true;
		document.body.appendChild(script10);

		const script11 = document.createElement("script");
		script11.src = "/themetwo/assets/js/main.js";
		script11.async = true;
		document.body.appendChild(script11);

		const script12 = document.createElement("script");
		script12.src = "/themetwo/assets/js/demos/demo-13.js";
		script12.async = true;
		document.body.appendChild(script12);
		*/
		
		
	}

	render() {
		return (
			<>
				{this.props.children}
			</>
		);
	}
}

export default connect(null, null)(ThemeTwoWrapper);
