import axios from 'axios';

import {
    BEFORE_FETCH_DASHBOARD_BUSINESSES,
    FETCH_DASHBOARD_BUSINESSES,
    NETWORK_ERROR_FETCHING_DASHBOARD_BUSINESSES
} from '../types/businesses.types';

import { endpointV2 } from '../../../../config'; 


export const beforeFetchDashboardBusinesses = () => (
    { type: BEFORE_FETCH_DASHBOARD_BUSINESSES }
)

export const fetchDashboardBusinesses = (token, data) => (
    async (dispatch) => {
        try{
            const response = await axios({
                method: 'get',
                url: `${endpointV2}user/fetch/business?page=${data.page}`,
                headers: {
                    "content-type": 'application/json',
                    "Accept" : 'application/json',
                    "Authorization" : `Bearer ${token}`
                },
            });

            dispatch({ type: FETCH_DASHBOARD_BUSINESSES, payload: response });

        }catch(e){
            dispatch({ type: NETWORK_ERROR_FETCHING_DASHBOARD_BUSINESSES, payload: e.response});
        }
    }
)