import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import * as Actions from "./actions/viewStore.action";
import Description from "./components/description";
import Products from "./components/products";
import Breadcrumb from "components/breadcrumb/breadcrumb";
// import OtherImages from "./components/otherImages";
import ClusterLogo from 'assets/images/logo/logoface.png';
import Slider from "components/slider/slider";
import Header from "./components/header";
import GoogleMap from "./components/googleMap"

class Stores extends Component {
	componentDidMount(){
		let id = this.props.match.params.businessId;
		this.props.actions.getStoreDetails(id)
	}
	
	render() {
		if(this.props.storeReducer.store !== null){
			
			let {business, products} = this.props.storeReducer.store;
			let coordinates = {lat: business.lat, lng: business.long}
			return (
				<div className="content">
					<div className="col-span-12 mt-8">
						<Breadcrumb title="View Business" links={{Dashboard: "/dashboard", Busineses: "/merchant-businesses", "View Business": ""}} />
					</div>



					<div className="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6">

						
						<Header business={business} products={products} />

						<div className="col-span-12 lg:col-span-8 mt-0">
							<div className="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6 box mt-5">

									<div className="col-span-12 sm:col-span-6 lg:col-span-6">
										<div className="intro-y sm:p-0 lg:p-2 pl-10 pr-10 mt-12 sm:mt-5 mb-10">

											<Slider images={business.images} />

										</div>
									</div>


									<Description business={business} products={products} />
							</div>
						</div>


						<GoogleMap coordinates={coordinates} />

					
						
						<Products business={business} products={products} />

					</div>
				


				</div>
			);
		}else{
			return(<div>Loading...</div>)
		}
	}
}

const mapStateToProps = (state) => ({ storeReducer: state.merchantViewStoreReducer});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Stores));
