import React from "react";

export default function title(props) {
	return (
		<div className="intro-y flex items-center mt-8">
			<h2 className="text-lg font-medium mr-auto">
				{props.id !== '' ? "Edit "  : "Create " } Business
			</h2>

			<a href="/merchant-businesses" className="ml-auto flex text-theme-1">
				<button className="button text-white bg-theme-1 shadow-md mr-2">
					Back to Businesses
				</button>
			</a>
		</div>

	);
}
