import React, { Component } from 'react';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import * as Actions from './actions/image-thumbnails.actions';
import { getLocalToken } from '../../../../../helpers/authentication/token';
import {
	customNotification,
	networkNotification
} from '../../../../../components/notifications/Notifications';



class ImageThumbnails extends Component{

    

    componentDidMount(){
        const { images } = this.props;
        this.syncImagesToReducer(images);
    }

    
    syncImagesToReducer = async(images) => {
        const { actions } = this.props;
        const { beforeSetImagesToReducer, setImagesToReducer } = actions;
        await beforeSetImagesToReducer();
        setImagesToReducer(images);
    }

    onDelete = async (imageId) => {
        const { actions, getUpdateFromImagesThumbnail } = this.props;
        const { beforeDeleteImage, deleteImage } = actions;

        await beforeDeleteImage({ image_id: imageId });
        await deleteImage(getLocalToken(), { image_id: imageId });

        let { imageThumbnailsWidgetReducer } = this.props;
        let { status_code, messages, images } = imageThumbnailsWidgetReducer;

        getUpdateFromImagesThumbnail(images);

        networkNotification(messages, status_code);
    }

    render(){
        const { imageThumbnailsWidgetReducer } = this.props;
        const { images, loading_init, key } = imageThumbnailsWidgetReducer;

        return(
            <div className="card" key={key}>
                <div className="card-body pb-5">
                    <div className="row">

                        {images && images.map(
                            image => 
                                (
                                    <div className="col-md-3 mt-4">
                                        <button disabled={image.loading} onClick={() => this.onDelete(image.id)} className={`btn ${image.loading ? `btn-secondary`: `btn-primary`} p-0`} style={{borderRadius: '50%', width: '28px', height: '28px', position: 'absolute', top: '-14px', left: '-1px'}}>
                                            {image.loading
                                                ?
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                :
                                                <i className="fas fa-fw fa-close text-white"></i>
                                            }
                                        </button>

                                        <div style={{width: '100%', height: '150px', backgroundImage: `url(${image.s3_bucket_url}${image.url})`, backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat:'no-repeat', border: '1px solid #eee', opacity: image.loading ? 0.5 : 1}}>
                                            
                                        </div>
                                        
                                    </div>
                                )
                            )
                        }
                        
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({ imageThumbnailsWidgetReducer: state.imageThumbnailsWidgetReducer});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ImageThumbnails));