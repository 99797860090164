import React from 'react';

function readFile(id, file){
	const reader = new FileReader()
	reader.onabort = () => console.log('file reading was aborted')
	reader.onerror = () => console.log('file reading has failed')
	reader.onload = () => {
		const binaryStr = reader.result;
		let img =document.getElementById(`preview${id}`)	
		if(img !== null){	
			img.src = binaryStr;
		}
	}
	reader.readAsDataURL(file)
}

function removeFile(id, props){
	let fileData = props.images;
	fileData.splice(id, 1);
	props.updateState([props.name], fileData)
}

function calculateFileSize(size){
	if(size> 999){
		return Math.round(size/1000) + " KB"
	}if(size> 99999){
		return Math.round(size/100000) + " MB"
	}
	return size + " Byte";  
}

export function PreviewImage(props) {
    let index = props.index;
    let value = props.value;
    let prop = props.prop
    return (
        <div className="intro-y col-span-6 sm:col-span-4 md:col-span-3 xxl:col-span-2">
            <div className="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in">
                
                <img alt={value.size} id={`preview${index}`} src={readFile(index, value)} className="w-3/5 file__icon file__icon--file mx-auto" />
                
                <span className="block font-medium mt-4 text-center truncate">{value.name}</span> 
                <div className="text-gray-600 text-xs text-center">{calculateFileSize(value.size)}</div>
                <div className="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
                    <span className="dropdown-toggle w-5 h-5 block" onClick={()=>removeFile(index, prop)}> X </span>
                </div>
            </div>
        </div> 
    )
    
}