import React, { Component } from 'react';


class SpecialCollection extends Component{

    render(){
        return(
            <>

                <div className="heading heading-flex heading-border mb-3">
                    <div className="heading-left">
                        <h2 className="title">{this.props.category}</h2>
                    </div>

                    <div className="heading-right">
                        <ul className="nav nav-pills nav-border-anim justify-content-center" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="elec-new-link" data-toggle="tab" href="#elec-new-tab" role="tab" aria-controls="elec-new-tab" aria-selected="true">New</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="elec-featured-link" data-toggle="tab" href="#elec-featured-tab" role="tab" aria-controls="elec-featured-tab" aria-selected="false">Featured</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="elec-best-link" data-toggle="tab" href="#elec-best-tab" role="tab" aria-controls="elec-best-tab" aria-selected="false">Best Seller</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="tab-content tab-content-carousel">
                    <div className="tab-pane p-0 fade show active" id="elec-new-tab" role="tabpanel" aria-labelledby="elec-new-link">
                        <div className="owl-carousel owl-simple carousel-equal-height carousel-with-shadow" data-toggle="owl"
                            data-owl-options='{
                                            "nav": false,
                                            "dots": true,
                                            "margin": 20,
                                            "loop": false,
                                            "responsive": {
                                                "0": {
                                                    "items":2
                                                },
                                                "480": {
                                                    "items":2
                                                },
                                                "768": {
                                                    "items":3
                                                },
                                                "992": {
                                                    "items":4
                                                },
                                                "1280": {
                                                    "items":5,
                                                    "nav": true
                                                }
                                            }
                                        }'>
                            <div className="product">
                                <figure className="product-media">
                                    <span className="product-label label-new">New</span>
                                    <a href="product.html">
                                        <img src="/themetwo/assets/images/demos/demo-13/products/product-6.jpg" alt="Product image" className="product-image" />
                                    </a>

                                    <div className="product-action-vertical">
                                        <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                        <a href="#" className="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a>
                                        <a href="popup/quickView.html" className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></a>
                                    </div>

                                    <div className="product-action">
                                        <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                    </div>
                                </figure>

                                <div className="product-body">
                                    <div className="product-cat">
                                        <a href="#">Appliances</a>
                                    </div>
                                    <h3 className="product-title"><a href="product.html">Neato Robotics</a></h3>
                                    <div className="product-price">
                                        $399.00
                                    </div>
                                    <div className="ratings-container">
                                        <div className="ratings">
                                            <div className="ratings-val" style={{width: '80%'}}></div>
                                        </div>
                                        <span className="ratings-text">( 12 Reviews )</span>
                                    </div>
                                </div>
                            </div>


                            <div className="product">
                                <figure className="product-media">
                                    <span className="product-label label-new">New</span>
                                    <a href="product.html">
                                        <img src="/themetwo/assets/images/demos/demo-13/products/product-6.jpg" alt="Product image" className="product-image" />
                                    </a>

                                    <div className="product-action-vertical">
                                        <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                        <a href="#" className="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a>
                                        <a href="popup/quickView.html" className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></a>
                                    </div>

                                    <div className="product-action">
                                        <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                    </div>
                                </figure>

                                <div className="product-body">
                                    <div className="product-cat">
                                        <a href="#">Appliances</a>
                                    </div>
                                    <h3 className="product-title"><a href="product.html">Neato Robotics</a></h3>
                                    <div className="product-price">
                                        $399.00
                                    </div>
                                    <div className="ratings-container">
                                        <div className="ratings">
                                            <div className="ratings-val" style={{width: '80%'}}></div>
                                        </div>
                                        <span className="ratings-text">( 12 Reviews )</span>
                                    </div>
                                </div>
                            </div>


                            <div className="product">
                                <figure className="product-media">
                                    <span className="product-label label-new">New</span>
                                    <a href="product.html">
                                        <img src="/themetwo/assets/images/demos/demo-13/products/product-6.jpg" alt="Product image" className="product-image" />
                                    </a>

                                    <div className="product-action-vertical">
                                        <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                        <a href="#" className="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a>
                                        <a href="popup/quickView.html" className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></a>
                                    </div>

                                    <div className="product-action">
                                        <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                    </div>
                                </figure>

                                <div className="product-body">
                                    <div className="product-cat">
                                        <a href="#">Appliances</a>
                                    </div>
                                    <h3 className="product-title"><a href="product.html">Neato Robotics</a></h3>
                                    <div className="product-price">
                                        $399.00
                                    </div>
                                    <div className="ratings-container">
                                        <div className="ratings">
                                            <div className="ratings-val" style={{width: '80%'}}></div>
                                        </div>
                                        <span className="ratings-text">( 12 Reviews )</span>
                                    </div>
                                </div>
                            </div>


                            <div className="product">
                                <figure className="product-media">
                                    <span className="product-label label-top">Top</span>
                                    <a href="product.html">
                                        <img src="/themetwo/assets/images/demos/demo-13/products/product-7.jpg" alt="Product image" className="product-image" />
                                    </a>

                                    <div className="product-action-vertical">
                                        <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                        <a href="#" className="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a>
                                        <a href="popup/quickView.html" className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></a>
                                    </div>

                                    <div className="product-action">
                                        <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                    </div>
                                </figure>

                                <div className="product-body">
                                    <div className="product-cat">
                                        <a href="#">Laptops</a>
                                    </div>
                                    <h3 className="product-title"><a href="product.html">MacBook Pro 13" Display, i5</a></h3>
                                    <div className="product-price">
                                        $1,199.00
                                    </div>
                                    <div className="ratings-container">
                                        <div className="ratings">
                                            <div className="ratings-val" style={{width: '100%'}}></div>
                                        </div>
                                        <span className="ratings-text">( 4 Reviews )</span>
                                    </div>
                                </div>
                            </div>

                            <div className="product">
                                <figure className="product-media">
                                    <a href="product.html">
                                        <img src="/themetwo/assets/images/demos/demo-13/products/product-8.jpg" alt="Product image" className="product-image" />
                                    </a>

                                    <div className="product-action-vertical">
                                        <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                        <a href="#" className="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a>
                                        <a href="popup/quickView.html" className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></a>
                                    </div>

                                    <div className="product-action">
                                        <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                    </div>
                                </figure>

                                <div className="product-body">
                                    <div className="product-cat">
                                        <a href="#">Audio</a>
                                    </div>
                                    <h3 className="product-title"><a href="product.html">Bose - SoundLink Bluetooth Speaker</a></h3>
                                    <div className="product-price">
                                        $79.99
                                    </div>
                                    <div className="ratings-container">
                                        <div className="ratings">
                                            <div className="ratings-val" style={{width: '60%'}}></div>
                                        </div>
                                        <span className="ratings-text">( 6 Reviews )</span>
                                    </div>
                                </div>
                            </div>

                            <div className="product">
                                <figure className="product-media">
                                    <span className="product-label label-new">New</span>
                                    <a href="product.html">
                                        <img src="/themetwo/assets/images/demos/demo-13/products/product-9.jpg" alt="Product image" className="product-image" />
                                    </a>

                                    <div className="product-action-vertical">
                                        <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                        <a href="#" className="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a>
                                        <a href="popup/quickView.html" className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></a>
                                    </div>

                                    <div className="product-action">
                                        <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                    </div>
                                </figure>

                                <div className="product-body">
                                    <div className="product-cat">
                                        <a href="#">Tablets</a>
                                    </div>
                                    <h3 className="product-title"><a href="product.html">Apple - 11 Inch iPad Pro  with Wi-Fi 256GB </a></h3>
                                    <div className="product-price">
                                        $899.99
                                    </div>
                                    <div className="ratings-container">
                                        <div className="ratings">
                                            <div className="ratings-val" style={{width: '80%'}}></div>
                                        </div>
                                        <span className="ratings-text">( 4 Reviews )</span>
                                    </div>

                                    <div className="product-nav product-nav-dots">
                                        <a href="#" className="active" style={{background: '#edd2c8'}}><span className="sr-only">Color name</span></a>
                                        <a href="#" style={{background: '#eaeaec'}}><span className="sr-only">Color name</span></a>
                                        <a href="#" style={{background: '#333333'}}><span className="sr-only">Color name</span></a>
                                    </div>
                                </div>
                            </div>

                            <div className="product">
                                <figure className="product-media">
                                    <span className="product-label label-sale">Sale</span>
                                    <a href="product.html">
                                        <img src="/themetwo/assets/images/demos/demo-13/products/product-10.jpg" alt="Product image" className="product-image" />
                                    </a>

                                    <div className="product-action-vertical">
                                        <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                        <a href="#" className="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a>
                                        <a href="popup/quickView.html" className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></a>
                                    </div>

                                    <div className="product-action">
                                        <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                    </div>
                                </figure>

                                <div className="product-body">
                                    <div className="product-cat">
                                        <a href="#">Cell Phone</a>
                                    </div>
                                    <h3 className="product-title"><a href="product.html">Google - Pixel 3 XL 128GB</a></h3>
                                    <div className="product-price">
                                        <span className="new-price">$350.00</span>
                                        <span className="old-price">Was $410.00</span>
                                    </div>
                                    <div className="ratings-container">
                                        <div className="ratings">
                                            <div className="ratings-val" style={{width: '80%'}}></div>
                                        </div>
                                        <span className="ratings-text">( 10 Reviews )</span>
                                    </div>

                                    <div className="product-nav product-nav-dots">
                                        <a href="#" className="active" style={{background: '#333333'}}><span className="sr-only">Color name</span></a>
                                        <a href="#" style={{background: '#eaeaec'}}><span className="sr-only">Color name</span></a>
                                    </div>
                                </div>
                            </div>
                        
                        </div>

                    </div>
                    <div className="tab-pane p-0 fade" id="elec-featured-tab" role="tabpanel" aria-labelledby="elec-featured-link">
                        <div className="owl-carousel owl-simple carousel-equal-height carousel-with-shadow" data-toggle="owl"
                            data-owl-options='{
                                            "nav": false,
                                            "dots": true,
                                            "margin": 20,
                                            "loop": false,
                                            "responsive": {
                                                "0": {
                                                    "items":2
                                                },
                                                "480": {
                                                    "items":2
                                                },
                                                "768": {
                                                    "items":3
                                                },
                                                "992": {
                                                    "items":4
                                                },
                                                "1280": {
                                                    "items":5,
                                                    "nav": true
                                                }
                                            }
                                        }'>
                            <div className="product">
                                <figure className="product-media">
                                    <span className="product-label label-new">New</span>
                                    <a href="product.html">
                                        <img src="/themetwo/assets/images/demos/demo-13/products/product-9.jpg" alt="Product image" className="product-image" />
                                    </a>

                                    <div className="product-action-vertical">
                                        <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                        <a href="#" className="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a>
                                        <a href="popup/quickView.html" className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></a>
                                    </div>

                                    <div className="product-action">
                                        <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                    </div>
                                </figure>

                                <div className="product-body">
                                    <div className="product-cat">
                                        <a href="#">Tablets</a>
                                    </div>
                                    <h3 className="product-title"><a href="product.html">Apple - 11 Inch iPad Pro  with Wi-Fi 256GB </a></h3>
                                    <div className="product-price">
                                        $899.99
                                    </div>
                                    <div className="ratings-container">
                                        <div className="ratings">
                                            <div className="ratings-val" style={{width: '80%'}}></div>
                                        </div>
                                        <span className="ratings-text">( 4 Reviews )</span>
                                    </div>

                                    <div className="product-nav product-nav-dots">
                                        <a href="#" className="active" style={{background: '#edd2c8'}}><span className="sr-only">Color name</span></a>
                                        <a href="#" style={{background: '#eaeaec'}}><span className="sr-only">Color name</span></a>
                                        <a href="#" style={{background: '#333333'}}><span className="sr-only">Color name</span></a>
                                    </div>
                                </div>
                            </div>

                            <div className="product">
                                <figure className="product-media">
                                    <span className="product-label label-sale">Sale</span>
                                    <a href="product.html">
                                        <img src="/themetwo/assets/images/demos/demo-13/products/product-10.jpg" alt="Product image" className="product-image" />
                                    </a>

                                    <div className="product-action-vertical">
                                        <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                        <a href="#" className="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a>
                                        <a href="popup/quickView.html" className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></a>
                                    </div>

                                    <div className="product-action">
                                        <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                    </div>
                                </figure>

                                <div className="product-body">
                                    <div className="product-cat">
                                        <a href="#">Cell Phone</a>
                                    </div>
                                    <h3 className="product-title"><a href="product.html">Google - Pixel 3 XL 128GB</a></h3>
                                    <div className="product-price">
                                        $899.99
                                        <span className="new-price">$350.00</span>
                                        <span className="old-price">Was $410.00</span>
                                    </div>
                                    <div className="ratings-container">
                                        <div className="ratings">
                                            <div className="ratings-val" style={{width: '80%'}}></div>
                                        </div>
                                        <span className="ratings-text">( 10 Reviews )</span>
                                    </div>

                                    <div className="product-nav product-nav-dots">
                                        <a href="#" className="active" style={{background: '#333333'}}><span className="sr-only">Color name</span></a>
                                        <a href="#" style={{background: '#eaeaec'}}><span className="sr-only">Color name</span></a>
                                    </div>
                                </div>
                            </div>

                            <div className="product">
                                <figure className="product-media">
                                    <a href="product.html">
                                        <img src="/themetwo/assets/images/demos/demo-13/products/product-8.jpg" alt="Product image" className="product-image" />
                                    </a>

                                    <div className="product-action-vertical">
                                        <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                        <a href="#" className="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a>
                                        <a href="popup/quickView.html" className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></a>
                                    </div>

                                    <div className="product-action">
                                        <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                    </div>
                                </figure>

                                <div className="product-body">
                                    <div className="product-cat">
                                        <a href="#">Audio</a>
                                    </div>
                                    <h3 className="product-title"><a href="product.html">Bose - SoundLink Bluetooth Speaker</a></h3>
                                    <div className="product-price">
                                        $79.99
                                    </div>
                                    <div className="ratings-container">
                                        <div className="ratings">
                                            <div className="ratings-val" style={{width: '60%'}}></div>
                                        </div>
                                        <span className="ratings-text">( 6 Reviews )</span>
                                    </div>
                                </div>
                            </div>

                            <div className="product">
                                <figure className="product-media">
                                    <span className="product-label label-top">Top</span>
                                    <a href="product.html">
                                        <img src="/themetwo/assets/images/demos/demo-13/products/product-7.jpg" alt="Product image" className="product-image" />
                                    </a>

                                    <div className="product-action-vertical">
                                        <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                        <a href="#" className="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a>
                                        <a href="popup/quickView.html" className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></a>
                                    </div>

                                    <div className="product-action">
                                        <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                    </div>
                                </figure>

                                <div className="product-body">
                                    <div className="product-cat">
                                        <a href="#">Laptops</a>
                                    </div>
                                    <h3 className="product-title"><a href="product.html">MacBook Pro 13" Display, i5</a></h3>
                                    <div className="product-price">
                                        $1,199.00
                                    </div>
                                    <div className="ratings-container">
                                        <div className="ratings">
                                            <div className="ratings-val" style={{width: '100%'}}></div>
                                        </div>
                                        <span className="ratings-text">( 4 Reviews )</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane p-0 fade" id="elec-best-tab" role="tabpanel" aria-labelledby="elec-best-link">
                        <div className="owl-carousel owl-simple carousel-equal-height carousel-with-shadow" data-toggle="owl"
                            data-owl-options='{
                                            "nav": false,
                                            "dots": true,
                                            "margin": 20,
                                            "loop": false,
                                            "responsive": {
                                                "0": {
                                                    "items":2
                                                },
                                                "480": {
                                                    "items":2
                                                },
                                                "768": {
                                                    "items":3
                                                },
                                                "992": {
                                                    "items":4
                                                },
                                                "1280": {
                                                    "items":5,
                                                    "nav": true
                                                }
                                            }
                                        }'>
                            <div className="product">
                                <figure className="product-media">
                                    <span className="product-label label-top">Top</span>
                                    <a href="product.html">
                                        <img src="/themetwo/assets/images/demos/demo-13/products/product-7.jpg" alt="Product image" className="product-image" />
                                    </a>

                                    <div className="product-action-vertical">
                                        <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                        <a href="#" className="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a>
                                        <a href="popup/quickView.html" className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></a>
                                    </div>

                                    <div className="product-action">
                                        <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                    </div>
                                </figure>

                                <div className="product-body">
                                    <div className="product-cat">
                                        <a href="#">Laptops</a>
                                    </div>
                                    <h3 className="product-title"><a href="product.html">MacBook Pro 13" Display, i5</a></h3>
                                    <div className="product-price">
                                        $1,199.00
                                    </div>
                                    <div className="ratings-container">
                                        <div className="ratings">
                                            <div className="ratings-val" style={{width: '100%'}}></div>
                                        </div>
                                        <span className="ratings-text">( 4 Reviews )</span>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

            </>
        );
    }

}

export default SpecialCollection;