import React from "react";
import defaultImage from "./../images/default.jpg";

export default function product(props) {
	let imageData = props.business.images[0];
	let image =  imageData !== undefined ? imageData.s3_bucket_url + imageData.url : defaultImage
	return (
		<div className="relative flex items-center">
			<div className="w-12 h-12 flex-none image-fit">
				<img
					alt="Store "
					className="rounded-full"
					src={image}
				/>
			</div>
			<div className="ml-4 mr-auto">
				<a href={`/merchant-business/${props.business.unique_id}`} className="font-medium">
					{props.business.name}
				</a>
				<div className="text-gray-600 mr-5 sm:mr-5">
					{props.business.description}
				</div>
			</div>
		</div>
	);
}
