import axios from 'axios';
import {endpoint, endpointV2} from '../../../../config';

import {
    BEFORE_LOGIN_FULL_PAGE,
    LOGIN_FULL_PAGE,
    SHOWALERT,
    DISMISSALERT,
    NETWORK_ERROR_LOGGING_IN_FULL_PAGE 
} from '../types/login.types';
import {setToken} from "helpers/authentication/token";
import {popAlert} from "helpers/alert/popUpAlert";
import {encryptUserData} from "helpers/encryption/userEncrypt"


export const beforeLogin = () => (
    { type: BEFORE_LOGIN_FULL_PAGE }
);

export const dismissAlert = ()=>{
    return {type: DISMISSALERT}

}

export const login = (body) => (
    async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpointV2}user/login`,
                data:body,
                headers: {
                    "content-type": "application/json",
                    "Accept" : "application/json",
                },
            });

            dispatch({ type: LOGIN_FULL_PAGE, payload: response });
             
        }catch(e){
            //console.log('ERROR', e);
            //dispatch({ type: SHOWALERT, payload: {status: "error", message:e.message } });
            dispatch({ type: NETWORK_ERROR_LOGGING_IN_FULL_PAGE, payload: e.response });
        }
    }
)