import React , { Component } from 'react';

import LogoutEvent from '../../../components/logoutEvent/LogoutEvent';

class Header extends Component {

    render(){
        return(
            
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        
                <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                    <i className="fa fa-bars"></i>
                </button>

           


                <ul className="navbar-nav ml-auto">

             
                    <div className="topbar-divider d-none d-sm-block"></div>


                    <li className="nav-item dropdown no-arrow">
                        <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            
                            <h5>
                                <span class="badge badge-primary p-2">
                                    <i className="fas fa-user mr-1"></i>
                                    Account 
                                    <i className="fas fa-chevron-down fa-sm fa-fw"></i>
                                </span>
                            </h5>
                            <span className="ml-2 d-none d-lg-inline text-gray-600 small"></span>
                        </a>

                        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                            aria-labelledby="userDropdown">
                            <a className="dropdown-item" href="/account/dashboard">
                                <i className="fas fa-tachometer-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                Dashboard
                            </a>
                            <a className="dropdown-item" href="/account/dashboard/businesses">
                                <i className="fas fa-briefcase fa-sm fa-fw mr-2 text-gray-400"></i>
                                Businesses
                            </a>
                            <a className="dropdown-item" href="/account/dashboard/settings">
                                <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                                Account Settings
                            </a>
                            
                            <div className="dropdown-divider"></div>

                            <LogoutEvent title="Logout" className="dropdown-item" icon="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" redirectTo="/login"/>
                        </div>
                    </li>

                </ul>

            </nav>


        );
    }
}

export default Header;