import axios from "axios";
import {endpoint} from "config";
import {getToken} from "helpers/authentication/token";
import {handleError} from "helpers/error/error";
import {
    BUSINESS_STAT, 
    BUSINESS_STAT_STOP_LOADING,
    BUSINESS_STAT_START_LOADING,
    BUSINESS_START_LOADING, 
    BUSINESS_STOP_LOADING, 
    MERCHANT_BUSINESSES
} from "./../types/stores.types"


export function fetchBusinesses(){
    return (dispatch)=>{
        dispatch({type: BUSINESS_START_LOADING})
        let token = getToken();
        axios.get(`${endpoint}user/fetch/business`, {headers:{"Authorization": `Bearer ${token}`}})
        .then((data)=>{
            let dataResponse = data.data;
            if(dataResponse.status === 'success'){
                dispatch({type: MERCHANT_BUSINESSES, payload: dataResponse.result});
            }else{
                handleError(dataResponse)
            }
            dispatch({type: BUSINESS_STOP_LOADING})

        }).catch((error)=>{
            handleError(error)
            dispatch({type: BUSINESS_STOP_LOADING})
        })
    }
}


export function fetchStoreDashboard(){
    return (dispatch)=>{
        dispatch({type: BUSINESS_STAT_START_LOADING})
        let token = getToken();
        axios.get(`${endpoint}user/fetch/business-dashboard`, {headers:{"Authorization": `Bearer ${token}`}})
        .then((data)=>{
            let responseData = data.data;
            if(responseData.status === "success"){
                dispatch({type:BUSINESS_STAT, payload: responseData.result})
            }else{
                handleError(data)
            }
            dispatch({type: BUSINESS_STAT_STOP_LOADING})

        }).catch((error)=>{
            handleError(error)
            dispatch({type: BUSINESS_STAT_STOP_LOADING})
        })
    } 
}

export function deleteBusiness(businessUniqueId){
    return(dispatch)=>{
        let confirm = window.confirm('Do you wish to continue')
        if(confirm){
            dispatch({type: BUSINESS_START_LOADING})
            let token = getToken();
            axios.get(`${endpoint}delete/business/${businessUniqueId}`, {headers:{Authorization: `Bearer ${token}`}})
            .then((data)=>{ 
                let responseData = data.data;
                if(responseData.status === 'success'){
                    fetchBusinesses(businessUniqueId);
                }
                dispatch({type: BUSINESS_STOP_LOADING})
            }).catch((error)=>{
                dispatch({type: BUSINESS_STOP_LOADING})
                handleError(error)
            })
        }

    }
}