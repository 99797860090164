import {MSTORE} from "./../types/viewStore.type"
let initialState = {
    store : null,
}
export default function storeReducer(state = initialState, action){
    switch(action.type){
        case MSTORE:
            return {...state, store:action.payload};
        default:
            return state;
    }
}