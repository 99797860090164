import React, {Component} from 'react';
import { Pagination } from 'react-laravel-paginex';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import * as Actions from './actions/businesses.actions';
import Layout from '../common/Layout';
import { TwinBubbles, BusinessesLoading } from '../common/widgets/Loaders';
import { getLocalToken } from '../../../helpers/authentication/token';

class Businesses extends Component{


    async componentDidMount(){
        const { actions } = this.props;
        const { beforeFetchDashboardBusinesses, fetchDashboardBusinesses } = actions;
        const data = {
            page: this.getParameter('page', 1)
        };

        await beforeFetchDashboardBusinesses();
        fetchDashboardBusinesses(getLocalToken(), data);

    }

    handlePageChange = (data) => {
        this.setParameter('page', data.page);
    }

    setParameter = (parameterName, value) => {
        const url = new URL(window.location.href);
        url.searchParams.set(parameterName, value);
        window.location.href = url;
    }

    getParameter = (parameterName, defaultValue) => {
        const urlString = window.location.href; 
        const url = new URL(urlString);
        const param = url.searchParams.get(parameterName);
        return param ? param : defaultValue;
    }

    render = () => {

        const { dashboardBusinessesReducer } = this.props;
        const { businesses, status_code, loading, pagination } = dashboardBusinessesReducer;
        
        const truncateElipsesStyle = { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' };

        return(
            <Layout>
                
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/account/dashboard">Dashboard</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Businesses</li>
                    </ol>
                </nav>


                <div className="row mb-3">
                    <div className="col-sm-12">
                        <a href="/account/dashboard/business/create" className="btn btn-primary btn-sm my-2 my-sm-0 pull-right" ><i className="fas fa-fw fa-plus"></i> Create Business</a>
                    </div>
                </div>

                
 

                <div className="row mb-3">

                    {loading &&
                        <BusinessesLoading />
                    }

                    {!loading && status_code && businesses.length < 1 &&
                        <div className="col-sm-12 ">
                            <div className="card text-center p-5">
                                <div className="pt-5 pb-5 text-primary">
                                    You do not have any businesses, use the "Create Business" button above to add a new business.
                                </div>
                            </div>
                        </div>
                    }

                    {businesses.map(
                        biz =>
                            <div className="col-sm-6 mb-3">
                                <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title" style={truncateElipsesStyle}>{biz.name}</h5>

                                    {biz.description ?
                                        <p className="card-text" style={truncateElipsesStyle}>{biz.description}</p>
                                    :
                                        <p className="card-text text-secondary" style={truncateElipsesStyle}><i>{'No description for preview.'}</i></p>
                                    }
                                    <a href={`/account/dashboard/businesses/view/${biz.pid}`} className="btn btn-primary btn-sm">View Business</a>
                                </div>
                                </div>
                            </div>
                        )
                    }

                </div>

                
                {pagination && !loading && status_code && 
                    <Pagination 
                        buttonIcons={true} 
                        nextButtonIcon="fa fa-chevron-right" 
                        prevButtonIcon="fa fa-chevron-left" 
                        changePage={this.handlePageChange} 
                        data={pagination} 
                    />
                }

            </Layout>
        );
    }
}


const mapStateToProps = (state) => ({ dashboardBusinessesReducer: state.dashboardBusinessesReducer});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Businesses));