import { BUSINESS_CATEGORY_LOADING, BUSINESS_CATEGORY, BUSINESS_CATEGORY_ERROR, CREATE_BUSINESS_LOADING, CREATE_BUSINESS, CREATE_BUSINESS_ERROR  } from "../types/createBusiness.type";

let initialState = {
    businessCategoryLoading: false,
    businessCategory: [],
    createBusinessLoading: false,
    createBusiness: []
}

export default function(state = initialState, action ){
    switch(action.type){
        case BUSINESS_CATEGORY_LOADING:
            return {...state, businessCategoryLoading: true} 
        case BUSINESS_CATEGORY:
            return {...state, businessCategory: action.payload, businessCategoryLoading: false}
        case BUSINESS_CATEGORY_ERROR:
            return {...state, businessCategoryLoading: false}
        case CREATE_BUSINESS_LOADING:
            return {...state, createBusinessLoading: true}
        case CREATE_BUSINESS:
            return {...state, createBusiness: action.payload, createBusinessLoading: false}
        case CREATE_BUSINESS_ERROR:
            return {...state, createBusinessLoading: false}
        default:
            return state
    }
}