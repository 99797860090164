import React from "react";
import { Link } from "react-router-dom";
import Alert from "components/alert/alert";
import ButtonLoader from "components/loaders/buttonLoader";

function togglePassword(){
	let passInput = document.getElementById("registerPass");
	let passAttribute = passInput.getAttribute("type")
	if(passAttribute === 'password'){
		passInput.setAttribute("type", "text")
		document.getElementById("togglePassText").innerHTML = "hide"
	}else{
		passInput.setAttribute("type", "password")
		document.getElementById("togglePassText").innerHTML = "show"
	}
}

export default function formSection(props) {
	return (

		<>
			<div className="flex xl:hidden justify-center mb-5 mt-5">
				<img
					alt="Cluster"
					className="h-10"
					src=""
				/>
			</div>
			<div className="xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
				<div className="my-auto mx-auto xl:ml-20 bg-white xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
					<h2 className="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
						Sign Up
					</h2>
					<div className="intro-x mt-2 text-gray-500 xl:hidden text-center">
						Sign up to find businesses or 
						setup your service business so
						people can find and contact you.
					</div>
					<Alert dismissAlert={props.dismissAlert} status={props.reducer.alertStatus} message={props.reducer.alertMessage} />
					<div className="intro-x mt-8">
						<input
							type="text"
							name="firstname"
							className="intro-x login__input input input--lg border border-gray-300 block"
							placeholder="First Name"
							onChange={(e)=>props.updateFormState(e)}
						/>
						<input
							type="text"
							name="lastname"
							className="intro-x login__input input input--lg border border-gray-300 block mt-4"
							placeholder="Last Name"
							onChange={(e)=>props.updateFormState(e)}
						/>
						<input
							type="email"
							name="email"
							className="intro-x login__input input input--lg border border-gray-300 block mt-4"
							placeholder="Email"
							onChange={(e)=>props.updateFormState(e)}
						/>
						<input
							type="number"
							name="phone"
							className="intro-x login__input input input--lg border border-gray-300 block mt-4"
							placeholder="Phone Number"
							onChange={(e)=>props.updateFormState(e)}
						/>
						<input
							id="registerPass"
							name="password"
							type="password"
							className="intro-x login__input input input--lg border border-gray-300 block mt-4"
							placeholder="Password"
							onChange={(e)=>props.updateFormState(e)}
						/>
						<span onClick={togglePassword} id="togglePassText" className="togglePass">Show</span>
						
					</div>
					<div className="intro-x flex items-center text-gray-700 mt-4 text-xs sm:text-sm">
						<input
							type="checkbox"
							className="input border mr-2"
							id="remember-me"
							onChange={(e)=>props.updateFormState(e)}
						/>
						<label className="cursor-pointer select-none" >
							I agree to cluster
						</label>
						<a className="text-theme-1 ml-1" href="#privacy">
							Privacy Policy
						</a>
						.
					</div>
					<div className="intro-x mt-5 xl:mt-8 text-center xl:text-left">
					{
						props.reducer.loading ?
							<ButtonLoader />
						:
						<button onClick={props.register} className="button button--lg w-full xl:w-32 text-white bg-theme-1 xl:mr-3">
							Register
						</button>
					}
						<a href="/login" className="button inline-block button--lg w-full xl:w-32 text-gray-700 border border-gray-300 mt-3 xl:mt-0">
							Sign in
						</a>
					</div>
				</div>
			</div>
		</>		
	);
}
