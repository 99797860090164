import axios from 'axios';

import{
    BEFORE_PERFORM_VERIFICATION,
    PERFORM_VERIFICATION,
    NETWORK_ERROR_PERFORMING_VERIFICATION
} from '../types/verify-email.types';

import {
    endpoint
} from '../../../../config';



export const beforePerformVerification = () => (
    { type: BEFORE_PERFORM_VERIFICATION }
)

export const performVerification = (data) => (
    async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpoint}user/verify/via-email`,
                headers: {
                    "content-type": "application/json",
                    "Accept" : "application/json",
                },
                data
            });
            dispatch({ type: PERFORM_VERIFICATION, payload: response });
        }catch(e){
            dispatch({ type: NETWORK_ERROR_PERFORMING_VERIFICATION, payload: e.response });
        }
    }
)