import React from "react";

export default function product(props) {
	
	let statusTheme = props.active === 1 ? "text-theme-9" : "text-theme-6"
	return (
		<tr className="intro-x">
			<td className="w-40">
				<div className="flex">
					
						{props.images.map((data, index)=>{
							let imgPosition = index !== 0 ? "-ml-5" : ""
							return(
							<div key={`proImage${index}`} className={`w-10 h-10 image-fit zoom-in ${imgPosition}`}>
								<img
									alt="cluster product"
									className="tooltip rounded-full"
									src={data.s3_bucket_url + data.url}
									title="Uploaded at 6 August 2022"
								/>
							</div>
							)
						})}
						
					
				</div>
			</td>
			<td>
				<a href="#product" className="font-medium whitespace-no-wrap">
					{props.name}
				</a>
				<div className="text-gray-600 text-xs whitespace-no-wrap">
					{props.category}
				</div>
			</td>
			<td className="w-40">
				<div className={`flex items-center justify-center ${statusTheme}` }>
					<i data-feather="check-square" className="w-4 h-4 mr-2"></i>
					{props.active === 1 ? "Active" : "Inactive"}
				</div>
			</td>
			<td className="table-report__action w-56">
				<div className="flex justify-center items-center">
					<a className="flex items-center mr-3" href={`/edit-product/${props.businessUniqueId}/${props.id}`}>
						<i data-feather="check-square" className="w-4 h-4 mr-1"></i>
						Edit
					</a>
					<a 
						className="flex items-center text-theme-6" 
						href="#delete" 
						onClick={()=>props.deleteProduct(props.businessUniqueId, props.id)}
					>
						<i data-feather="trash-2" className="w-4 h-4 mr-1"></i>
						Delete
					</a>
				</div>
			</td>
		</tr>
	);
}
