import React, {Component} from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Dropzone from 'react-dropzone';
import {DropzoneArea} from 'material-ui-dropzone';
import Button from '@material-ui/core/Button';

import * as Actions from './actions/settings.actions';
import { getLocalToken } from '../../../helpers/authentication/token';
import { SettingsLoading } from '../common/widgets/Loaders';
import Layout from '../common/Layout';
import {
	customNotification,
	networkNotification
} from '../../../components/notifications/Notifications';


class Settings extends Component{

    constructor(props){
        super(props);

        this.state = {
            password: '',
            confirm_password: ''
        };
    }

    async componentDidMount(){
        const { actions } = this.props;
        const { beforeFetchUserSettings, fetchUserSettings } = actions;

        await beforeFetchUserSettings();
        await fetchUserSettings(getLocalToken());
    }


    updatePassword = async (event) => {
        event.preventDefault();

        const { actions } = this.props;
        const { beforeUpdatePassword, updatePassword } = actions;
        const { password, confirm_password } = this.state;

        const data = {
            password, confirm_password
        };

        await beforeUpdatePassword();
        await updatePassword(getLocalToken(), data);

        let { dashboardUserSettingsReducer } = this.props;
        let { status_code_password, messages, user} = dashboardUserSettingsReducer;

        if(status_code_password == 200){
            this.setState({
                password: '',
                confirm_password: ''
            });
        }
        networkNotification(messages, status_code_password);
    }



    handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    render = () => {

        let { loading, loading_password, status_code, user} = this.props.dashboardUserSettingsReducer;

        const { password, confirm_password } = this.state;

        return(
            <Layout>
                
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/account/dashboard">Dashboard</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Account Settings</li>
                    </ol>
                </nav>
                


                <div className="row">

                    {loading &&
                        <SettingsLoading />
                    }

                    {!loading && user && status_code &&
                        <div className="col-sm-12">

                            <div className="card">
                                <div className="card-body">

                                    
                                    <form onSubmit={this.updatePassword}>
                                        <div className="row">

                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <input type="email" className="form-control" id="exampleFormControlInput1"  disabled={true}  value={user.email} />
                                                </div>

                                                

                                                <div className="form-group">
                                                    <label for="exampleFormControlSelect2">Password</label>
                                                    <input type="password" className="form-control" id="exampleFormControlInput1" placeholder="Enter New Password" disabled={loading_password} onChange={this.handleChange} name="password" value={password} />
                                                </div>

                                                <div className="form-group">
                                                    <label for="exampleFormControlSelect2">Confirm Password</label>
                                                    <input type="password" className="form-control" id="exampleFormControlInput1" placeholder="Confirm New Password" disabled={loading_password} onChange={this.handleChange} name="confirm_password" value={confirm_password} />
                                                </div>

                                                <button className="btn btn-primary my-2 my-sm-0 pull-right" type="submit" disabled={loading_password}>
                                                    {loading_password
                                                    ?
                                                        <>
                                                            <span className="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                                                            <span className="visually-hidden"> Updating...</span>
                                                        </>
                                                    :
                                                        <>
                                                            <i className="fa fa-lock"></i> Update Password
                                                        </>
                                                    }
                                                </button>


                                            </div>

                                        </div>        
                                    </form>
                                    
                                </div>
                            </div>
                            
                        </div>
                    }


                </div>
   
            </Layout>
        );
    }
}


const mapStateToProps = (state) => ({ dashboardUserSettingsReducer: state.dashboardUserSettingsReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings));