import React, {Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from "../create-business/actions/createBusiness.action";
import AddressAutoComplete from "./components/googleAutoAddress";
import {
	geocodeByAddress,
	getLatLng,
} from 'react-places-autocomplete';
import {DropzoneArea} from "material-ui-dropzone"

import Layout from '../common/Layout';

class CreateBusiness extends Component{
    constructor(props){
        super(props)

        this.state = {
            name:'',
            category_id:'',
            address:'',
            description:'',
            images: [],
            lat:'',
            long:''
        }

        this.handleSelect = this.handleSelect.bind(this);
        this.setAddress = this.setAddress.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
    }

    componentDidMount(){
        this.props.actions.getBusinessCategory()   
    }

    setFormValue(event){
        this.setState({[event.target.name]: event.target.value})
    }

    setAddress(data){
        this.setState({address: data})
    }

    createBusiness(event){
        event.preventDefault();
        let formData= new FormData()
    
        formData.append('name', this.state.name)
        formData.append('category_id', this.state.category_id)
        formData.append('address', this.state.address)
        formData.append('description', this.state.description)
        this.state.images.forEach((element, i) => {
            formData.append('images[]', element)
        });
        formData.append('lat', this.state.lat)
        formData.append('long', this.state.long)
        this.props.actions.createBusiness(formData)   
    }

    handleImageChange = (files) => {
        console.log('FILES: ', files);
        this.setState({
          images: files
        });
    }


    handleSelect  = async function (address, props) {
        try{
            
            const results = await geocodeByAddress(address);
            const latLng = await getLatLng(results[0]);
            const formattedAddress = await results[0].formatted_address;
            this.setState({lat: latLng.lat, long: latLng.lng, address: formattedAddress})
          
            // window.document.getElementById("storeLat").value = latLng.lat;
            // window.document.getElementById("storeLng").value = latLng.lng;
            // props.handleChange("lat", latLng.lat);
            // props.handleChange("long", latLng.lng);
            // props.handleChange("address", address);

        }catch(e){
            console.error('Error', e);
            //also show notification in error
        }
    }

    
    render = () => {
        const reducer = this.props.reducers;
        return(
            <Layout>
                
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/account/dashboard">Dashboard</a></li>
                        <li className="breadcrumb-item"><a href="/account/dashboard/businesses">Businesses</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Create Business</li>
                    </ol>
                </nav>

 

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">

                                
                                <form onSubmit={(event)=>this.createBusiness(event)} autoComplete="off">
                                    <div className="row">

                                        <div className="col-sm-12 col-md-6">
                                            <div className="form-group">
                                                <label>Business Name</label>
                                                <input onChange={(event)=>this.setFormValue(event)} value={this.state.name} type="text" name="name" className="form-control" id="exampleFormControlInput1" placeholder="" />
                                            </div>
                                            <div className="form-group">
                                                <label>Business Category</label>
                                                <select onChange={(event)=>this.setFormValue(event)} value={this.state.category_id} name="category_id" className="form-control" id="exampleFormControlSelect1">
                                                    <option key={`cat0`} value="">Select a category</option>
                                                    {reducer.businessCategory.length > 0 ? 
                                                        reducer.businessCategory.map((data)=>{
                                                            return (<option key={`cat${data.id}`} value={data.id}>{data.name}</option>)
                                                        })
                                                        : 
                                                        <option>No options found</option>
                                                    }
                                                    
                                                
                                                </select>
                                            </div>
                                            <div className="form-group">
                                             <label >Business Address</label>
                                                <AddressAutoComplete 
                                                    address={this.state.address} 
                                                    handleChange={this.setAddress} 
                                                    handleSelect={this.handleSelect}
                                                    // containerClassName = "form-control"
                                                    inputClassName = "form-control"
                                                />
                                                
                                                {/* <input onChange={(event)=>this.setFormValue(event)} value={this.state.address} name="address" type="text" className="form-control" id="exampleFormControlInput1" placeholder="" /> */}
                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-6">    
                                            <div className="form-group">
                                                <label>Business Description</label>
                                                <textarea onChange={(event)=>this.setFormValue(event)} value={this.state.description} name="description" className="form-control" id="exampleFormControlTextarea1" rows="8"></textarea>
                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-12">    
                                            <div className="form-group">
                                                <DropzoneArea onChange={this.handleImageChange.bind(this)} />
                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-12" style={{marginTop: "10px"}}> 
                                            <button className="btn btn-primary my-2 my-sm-0 pull-right" type="submit">{reducer.createBusinessLoading ?  <i className="fa fa-spin fa-spinner"></i> :  <i className="fas fa-fw fa-briefcase"></i>}  Create Business </button>
                                        </div>
                                    </div>        
                                </form>
                                   
                            </div>
                        </div>
                    </div>
                </div>

                
   
            </Layout>
        );
    }
}

const mapStateToProps = function(state){
    return {reducers: state.createBusinessReducer}
}

const mapActionsToProps = function(dispatch){
    return {actions: bindActionCreators(actions, dispatch) }
}

export default connect(mapStateToProps, mapActionsToProps)(CreateBusiness);