import axios from 'axios';
import {
    BEFORE_FETCH_QUICK_VIEW,
    FETCH_QUICK_VIEW,
    NETWORK_ERROR_FETCHING_QUICK_VIEW
} from '../types/google-map.types';

import {
    endpoint
} from '../../../config';



export const beforeFetchQuickView = () => (
    { type: BEFORE_FETCH_QUICK_VIEW}
);

export const fetchQuickView = (businessId) => (
    async (dispatch) => {
        try{
            const response = await axios({
                method: 'get',
                url: `${endpoint}quick-view/business/${businessId}`,
                headers: {
                    "content-type": 'application/json',
                    "Accept" : 'application/json',
                },
            });

            dispatch({ type: FETCH_QUICK_VIEW, payload: response });

        }catch(e){
            dispatch({ type: NETWORK_ERROR_FETCHING_QUICK_VIEW, payload: e.response});
        }
    }
);