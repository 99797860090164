import React from "react";
import Product from "./product";

export default function products(props) {
	return (
		<div className="intro-y box lg:mt-5">
			<div className="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200">
				<h2 className="font-medium text-base mr-auto">Products</h2>
			</div>
			
			{props.products.map((value, index)=>{
				return (
					<div className="p-5" key={`products${index}`}>
						<Product  product={value}/>
					</div>
				)
			})}
				
			
		</div>
	);
}
