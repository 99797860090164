import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Slider from "react-slick";

import * as Actions from "./actions/products.actions";
import GoogleMap from "components/googleMap/googleMap";
import GoogleMapSingle from "components/googleMap/googleMapSingle";
import Description from "./components/description";
import Stores from "./components/stores";
import ProductImages from "./components/productImages";
import OtherImages from "./components/otherImages";
import ClusterLogo from '../../../assets/images/logo/logoface.png';
import {
	getLocalAddress
} from '../../../helpers/location/LocalAddress';

class Products extends Component {

	componentDidMount = async() => {
		const { beforeFetchProduct, fetchProduct } = this.props.actions;
		
		await beforeFetchProduct();
		fetchProduct(this.props.match.params.id);
	}

	render() {

		const { product, coordinates, loading } = this.props.productReducers;
		const userAddress = getLocalAddress() ? getLocalAddress().address : 'My Location';

		return (
			
			<div className="content">

				<div className="grid grid-cols-12 gap-6">
				{loading || !product
				?
					<div className="col-span-12 flex justify-center mt-5 pt-20">
						<i className="fa fa-spin fa-spinner" style={{fontSize: '40px'}}></i>
					</div>
				:
					<div className="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6">

						<div className="col-span-12 lg:col-span-12 mt-8">
							<>
								<a href="http://" className="ml-auto text-theme-1 truncate">Search</a>  
								&nbsp;
								&#8594;
								&nbsp;
								<a href="http://" className="ml-auto text-theme-1 truncate">Product</a> 
							</>
						</div>

						<div className="col-span-12 lg:col-span-8 mt-0">
							<div className="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6 box mt-5">

									<div className="col-span-12 sm:col-span-6 lg:col-span-6">
										<div className="intro-y sm:p-0 lg:p-2 pl-10 pr-10 mt-12 sm:mt-5 mb-10">

											<Slider 
												{...{
													dots: true,
													infinite: true,
													speed: 500,
													slidesToShow: 1,
													slidesToScroll: 1
												}}
											>
												{product.images.length > 0
												?
													product.images.map(	
													image => 
														(<div>
															<div style={{width: '100%', paddingTop: '100%', position: 'relative'}}>
																<div  style={{position: 'absolute', top: '0', left: '0', right: '0', bottom: '0',  backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center', backgroundImage: `url("${image.s3_bucket_url}${image.url}")`, }}>
																</div>
															</div>
														</div>)
													)
												:
													<div>
														<div style={{width: '100%', paddingTop: '100%', position: 'relative'}}>
															<div  style={{position: 'absolute', top: '0', left: '0', right: '0', bottom: '0',  backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center', backgroundImage: `url("${ClusterLogo}")`, }}>
															</div>
														</div>
													</div>			
												}

											</Slider>

										</div>
									</div>


									<div className="col-span-12 sm:col-span-6 lg:col-span-6 mt-8 pl-5 pr-5">
										<div>
											<img src={product.source.icon}/>
										</div>
										<div className="block font-medium text-base mt-0">
											{product.name}
										</div> 
										<div className="text-gray-700 mt-2 mb-2">
											{product.description}
										</div>

										<div className="flex">
											<div>
												<div className="text-theme-20 text-lg xl:text-xl font-bold">₦{product.amount}</div>
												<div className="text-gray-600">price</div>
											</div>
											<div className="w-px h-12 border border-r border-dashed border-gray-300 mx-4 xl:mx-6"></div>
											<div>
												<div className="text-gray-600 text-lg xl:text-xl font-medium">{product.views_count}</div>
												<div className="text-gray-600">Views</div>
											</div>
										</div>
										
										<div className="flex items-center  border-t border-dashed border-gray-300  mt-5"></div>

										<div className="block font-medium text-base mt-0 pt-2">
											Service Provider
										</div> 

										<div className="flex items-center  border-t border-dashed border-gray-300 mt-2"></div>

										<div className="block font-medium text-base mt-0 pt-2">
											<span className="text-theme-20"><i className="fa fa-institution"></i> {product.business.name}</span>
										</div> 

										<div className="text-gray-700 mt-2">
											{product.business.description}
										</div>

										<div className="flex items-center  border-t border-dashed border-gray-300 mt-2"></div>
										
										<div className="block font-medium text-base mt-0 pt-2">
											Contact Details
										</div> 

										<div className="mt-3 mb-5">
											<div className="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-1 box mt-5">
												<div className="col-span-12 sm:col-span-6 lg:col-span-6">
													<a href={`/business/${product.business.unique_id}`} style={{borderRadius: 0}} className="button mr-2 mb-2 flex w-full items-center justify-center bg-theme-1 text-white"> <i className="mr-2 fa fa-institution"></i> View Provider </a> 
												</div>

												<div className="col-span-12 sm:col-span-6 lg:col-span-6">
													<button onClick={()=> alert('phone-number')} style={{borderRadius: 0}} className="button mr-2 mb-2 flex w-full items-center justify-center bg-theme-1 text-white"> <i className="mr-2 fa fa-phone"></i> Reveal Telephone </button> 
												</div>

											</div>			
										</div>

									</div>

							</div>
						</div>


						<div className="col-span-12 sm:col-span-6 lg:col-span-4 mt-0">
							
							<div className="intro-y box p-5 mt-5">
								
								{coordinates.lat !== null && coordinates.lng !== null
								?
									<>					
										<GoogleMapSingle coordinates={coordinates} mapHeightPercent={100} />
										<div className="mt-4">
											<div className="flex flex-col lg:flex-row items-center p-0">
												<div className="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0">
													<div className="text-gray-600 text-xs">Need help finding service provider?</div> 
												</div>

												<div className="flex mt-4 lg:mt-0">
												<a href={`https://www.google.com/maps?saddr=${userAddress}&daddr=${coordinates.lat},${coordinates.lng}`} target="_blank" className="button button--sm text-white bg-theme-1 mr-2">Get Directions</a>
												</div>
											</div>
										</div>
									</>
								:
									<>					
										<div className="col-span-12 flex justify-center mt-5 p-20">
											<i className="fa fa-spin fa-spinner" style={{fontSize: '40px'}}></i>
										</div>
										<div className="text-gray-600 text-xs">Initializing maps...</div>
									</>
								}

							</div>
						</div>
					
					</div>
				}
				</div>

			</div>
		);
	}
}

const mapStateToProps = (state) => ({ productReducers: state.productReducers });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Products));
