import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { customNotification, networkNotification } from '../notifications/Notifications';
import { getLocalToken, clearLocalToken } from '../../helpers/authentication/token';

import * as Actions from './actions/logout-event.actions';


class LogoutEvent extends Component{

    constructor(props){
        super(props);

        this.logout.bind(this);
    }

    logout = async (event) => {
        event.preventDefault();
        const { actions, redirectTo } = this.props;
        const { beforeLogout, logout } = actions;

        await beforeLogout();
        await logout({token: getLocalToken()});
        
        const { status_code, messages } = this.props.logoutEventReducer;
        if(status_code){
            networkNotification(messages, status_code);
            clearLocalToken();
            window.location.href = redirectTo ? redirectTo : '/login';
        }
    }


    render = () => {

        const { messages, loading } = this.props.logoutEventReducer;
        const className = this.props.className ? this.props.className : '';
        const icon = this.props.icon ? this.props.icon : '';
        const title = this.props.title ? this.props.title : 'Logout';

        return (        
            <a onClick={this.logout} style={{cursor: 'pointer'}}  className={className}>
                <i className={icon}></i>
                {title}
            </a>
        );
    }
}

const mapStateToProps = (state) => ({ logoutEventReducer: state.logoutEventReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogoutEvent);