import React from "react";
import Product from "./product";
import ProductPagination from "./productPagination";
import ErrorBlock from "components/error/errorBlock";

export default function products(props) {
	
	return (
		props.products.length > 0 ?
		<div className="col-span-12 mt-6">
			{/* <div className="intro-y block sm:flex items-center h-10">
				<h2 className="text-lg font-medium truncate mr-5">Products</h2>
			</div> */}
			<div className="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
				<table className="table table-report sm:mt-2">
					<thead>
						<tr>
							<th className="whitespace-no-wrap">IMAGES</th>
							<th className="whitespace-no-wrap">PRODUCT NAME</th>
							<th className="text-center whitespace-no-wrap">STATUS</th>
							<th className="text-center whitespace-no-wrap">ACTIONS</th>
						</tr>
					</thead>
					<tbody>
						{props.products.map((value, index) => {
							return <Product 
										key={`product${index}`} 
										{...value} 
										businessUniqueId = {props.businessUniqueId} 
										deleteProduct = {props.deleteProduct}
									/>;
						})}
					</tbody>
				</table>
			</div>
			<ProductPagination />
		</div>

		: <ErrorBlock message="No product found" />
					
	);
}
