import axios from 'axios';
import { endpointV2 } from '../../../config';

import {
    BEFORE_LOGOUT_EVENT,
    LOGOUT_EVENT,
    NETWORK_ERROR_WITH_LOGGING_OUT_EVENT
} from '../types/logout-event.types';

export const beforeLogout = () => (
    { type: BEFORE_LOGOUT_EVENT }
)

export const logout = (body) => (
    async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpointV2}user/logout`,
                data: body,
                headers: {
                    "content-type": 'application/json',
                    "Accept" : 'application/json',
                },
            });

            dispatch({ type: LOGOUT_EVENT, payload: response });

        }catch(e){
            dispatch({ type: NETWORK_ERROR_WITH_LOGGING_OUT_EVENT, payload: e.response});
        }
    }
)