import {
    BUSINESS_STAT, 
    MERCHANT_BUSINESSES,  
    BUSINESS_START_LOADING, 
    BUSINESS_STOP_LOADING,
    BUSINESS_STAT_START_LOADING,
    BUSINESS_STAT_STOP_LOADING,
} from "./../types/stores.types"
let initialState = {
    businessStat :{},
    businesses: [],
    loading: false,
    businessStatloading: false
}

export default function(state = initialState, action){
    switch(action.type){
        case BUSINESS_STAT:
            return {...state, businessStat: action.payload};
        case BUSINESS_STAT_START_LOADING:
            return {...state, businessStatloading: true}
        case BUSINESS_STAT_STOP_LOADING:
            return {...state, businessStatloading: false}
        case MERCHANT_BUSINESSES:
            return {...state, businesses: action.payload};
        case BUSINESS_START_LOADING:
            return {...state, loading: true}
        case BUSINESS_STOP_LOADING:
            return {...state, loading: false}
        default:
            return state;
    }
}