import React , { Component } from 'react';

import LogoutEvent from '../../../components/logoutEvent/LogoutEvent';

class SideNav extends Component {

    render(){
        return(
                            
            <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
                            
                <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/account/dashboard">
                    <div className="sidebar-brand-icon rotate-n-15">
                        <i className="fas fa-laugh-wink"></i>
                    </div>
                    <div className="sidebar-brand-text mx-3">Cluster</div>
                </a>

                
                <hr className="sidebar-divider my-0" />

                
                <li className="nav-item">
                    <a className="nav-link" href="/account/dashboard">
                        <i className="fas fa-fw fa-tachometer-alt"></i>
                        <span>Dashboard</span></a>
                </li>

                
                <hr className="sidebar-divider" />

                
                <div className="sidebar-heading">
                    Business
                </div>

                <li className="nav-item">
                    <a className="nav-link" href="/account/dashboard/businesses">
                        <i className="fas fa-fw fa-cog"></i>
                        <span>Business List</span>
                    </a>
                </li>


                <li className="nav-item">
                    <a className="nav-link" href="/account/dashboard/business/create">
                        <i className="fas fa-fw fa-cog"></i>
                        <span>Create Business</span>
                    </a>
                </li>

                
                <hr className="sidebar-divider" />

                
                <div className="sidebar-heading">
                    Account
                </div>

                <li className="nav-item">
                    <a className="nav-link" href="/account/dashboard/settings">
                        <i className="fas fa-fw fa-cog"></i>
                        <span>Settings</span>
                    </a>
                </li>

                <li className="nav-item">
                    <LogoutEvent title="Logout" className="nav-link" icon="fas fa-sign-out-alt" redirectTo="/login"/>
                </li>             

                <hr className="sidebar-divider d-none d-md-block" />

                <div className="text-center d-none d-md-inline">
                    <button className="rounded-circle border-0" id="sidebarToggle"></button>
                </div>

            </ul>


        );
    }
}

export default SideNav;