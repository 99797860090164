import axios from "axios";
import {STARTLOADING, STOPLOADING, SHOWALERT, DISMISSALERT} from "./../types/createProduct.types";
import {endpoint} from "config"
import {getToken} from "helpers/authentication/token";
import {popAlert} from "helpers/alert/popUpAlert";
import {handleError} from "helpers/error/error";
import {fetchSubcategory, fetchBuscategory} from "helpers/category/actions/category.action";
import {fetchSlots} from "helpers/slot/actions/slot.action";
import {deleteImage, uploadImage, setImages} from "helpers/images/actions/updateImage.action";


export function dismissAlert(){
    return {type: DISMISSALERT};
}

export function showAlert(status, message){
    return {type: SHOWALERT, payload:{status: status, message: message}};
}

export function startLoading(){
    return {type: STARTLOADING}
}

export function stopLoading(){
    return {type: STOPLOADING}
}

export const getSubcategory = fetchSubcategory;
export const getBuscategory = fetchBuscategory;
export const getSlots = fetchSlots;


export function createUpdateProduct(data, businessId, productId){
    return (dispatch)=>{
        let token = getToken();
        dispatch(startLoading())

        let url = (productId === '') ? 'create/product' : `update/product/${productId}`;
        let successMessage = (productId === '') ? 'Product created successfully' : `Product edited successfully`;
                
        axios.post(`${endpoint}${url}`, data, {headers:{"Authorization": `Bearer ${token}`}})
        .then((response)=>{

            dispatch(stopLoading())
            let responseData = response.data
            if(responseData.status === 'success'){
                popAlert("success", "Success", successMessage)
                window.location.href = `/merchant-products/${businessId}`; 
            }else{
                let message = responseData.message;
                popAlert("danger", "Error", message) 
            }

        }).catch((error)=>{
            dispatch(stopLoading())
            handleError(error)    
        })
    }
}

export function getProduct(productId){
    return async dispatch=>{
        try{
            let token = getToken()
            let data = await axios.get(`${endpoint}product/${productId}`, {headers:{Authorization: `Bearer ${token}`} });
            let responseData = data.data;
            if(responseData.status === 'success'){
                return responseData.result;
            }else{
                popAlert('danger', 'Error', responseData.message)
            }
        }catch(error){
            handleError(error)
        }
    }
}


export const deleteProductImage = deleteImage;

export const uploadProductImages = uploadImage;

export const setProductImages = setImages;