import React from 'react';
import Dropzone from 'react-dropzone';

function appendFile(files, props){
	let fileData = props.images
	for(let x = 0; x< files.length; x++){
		fileData.push(files[x])
	}
	props.updateState([props.name], fileData)
	
}


export function FileZone(props) {
    return (
        <div className="p-5" id="multiple-file-upload">
				<Dropzone className="dropzone border-gray-200 border-dashed" onDrop={(acceptedFiles) => appendFile(acceptedFiles, props.prop)}>
					{({getRootProps, getInputProps}) => (
						<section>
							<div className="dropzone border-gray-200 border-dashed" {...getRootProps()}>
								<input  {...getInputProps()} />
								<div className="dz-message">
								<p className="text-lg font-medium">Drop files here or click to select files</p>
								</div>
							</div>
						</section>
					)}
				</Dropzone>
				
			</div>
    )
    
}