import React from "react";
import defaultImage from "assets/images/placeholders/200x200.jpg";

export default function store(props) {
	let image = props.images.length > 0 ? props.images[0].s3_bucket_url+props.images[0].url : defaultImage
	
	let statusText =  props.active === 1 ? "text-theme-9" : "text-theme-6";
	return (
		<tr className="intro-x">
			<td className="w-40">
				<div className="flex">
					<div className="w-10 h-10 flex-none image-fit rounded-md overflow-hidden">
						<img alt={props.name} src={image} />
					</div>
				</div>
			</td>
			<td>
				<a href ={`/merchant-business/${props.unique_id} `} className="font-medium whitespace-no-wrap">
					{props.name}
				</a>
				<div className="text-gray-600 text-xs whitespace-no-wrap">
					{props.category ? props.category : "category"}
				</div>
			</td>
			<td className="w-40">
				<div className={`flex items-center justify-center ${statusText}`}>
					{/* <i data-feather="check-square" className="w-4 h-4 mr-2"></i> */}
					{props.active === 1 ? "Active" : "Inactive"}
				</div>
			</td>
			<td className="table-report__action w-56">
				<div className="flex justify-center items-center">
					{/* <a className="flex items-center mr-3" href={`/merchant-products/${props.unique_id}`}>
						<i data-feather="check-square" className="w-4 h-4 mr-1"></i>
						Products
					</a> */}
					<a className="flex items-center mr-3" href={`/edit-store/${props.unique_id}`}>
						<i data-feather="check-square" className="w-4 h-4 mr-1"></i>
						Edit
					</a>
					<a 
						className="flex items-center text-theme-6" 
						href="#delete"
						onClick={()=>props.deleteBusiness(props.unique_id)}
					>
						<i data-feather="trash-2" className="w-4 h-4 mr-1"></i>
						Delete
					</a>
				</div>
			</td>
		</tr>
	);
}
