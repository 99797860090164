import React  from "react"
import ClusterLogo from 'assets/images/logo/logoface.png';

export default function(props){
    return(
        <div className="col-span-12 lg:col-span-12">
            <div className="intro-y box px-5 pt-5 mt-5">
                <div className="flex flex-col lg:flex-row border-b border-gray-200 pb-5 -mx-5">
                    <div className="flex flex-1 px-5 items-center justify-center lg:justify-start">
                        <div className="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative">
                            <img alt="Cluster" className="rounded-full" src={ClusterLogo} />
                        </div>
                        <div className="ml-5">
                            <div className="w-full truncate sm:whitespace-normal whitespace-normal font-medium text-lg">{props.business.name}</div>
                            <div className="text-gray-600">Service Provider</div>
                        </div>
                    </div>
                    <div className="flex mt-6 lg:mt-0 items-center lg:items-start flex-1 flex-col justify-center text-gray-600 px-5 border-l border-r border-gray-200 border-t lg:border-t-0 pt-5 lg:pt-0">
                        <div className="truncate sm:whitespace-normal flex items-center"> <i data-feather="mail" className="w-4 h-4 mr-2 fa fa-facebook"></i> Facebook </div>
                        <div className="truncate sm:whitespace-normal flex items-center mt-3"> <i data-feather="instagram" className="w-4 h-4 mr-2 fa fa-instagram"></i> Instagram  </div>
                        <div className="truncate sm:whitespace-normal flex items-center mt-3"> <i data-feather="twitter" className="w-4 h-4 mr-2 fa fa-twitter"></i> Twitter </div>
                    </div>
                    <div className="mt-6 lg:mt-0 flex-1 flex items-center justify-center px-5 border-t lg:border-0 border-gray-200 pt-5 lg:pt-0">
                        
                        <div className="text-center rounded-md w-20 py-3">
                            <div className="font-semibold text-theme-1 text-lg">{props.business.views_count}</div>
                            <div className="text-gray-600">Views</div>
                        </div>
                        
                        <div className="text-center rounded-md w-40 py-3">
                            <div className="font-semibold text-theme-1 text-lg">{props.products.length}</div>
                            <div className="text-gray-600">Available Services</div>
                        </div>

                    </div>
                </div>
                <div className="nav-tabs flex flex-col sm:flex-row justify-center lg:justify-start">
                    <a data-toggle="tab" data-target="#profile" href="javascript:;" className={`py-4 sm:mr-8 flex items-center active ${props.business.active === 1 ? 'text-theme-1' : 'text-gray-600' }`}> <i className="w-4 h-4 mr-2 fa fa-star" data-feather="user"></i> Verified </a>
                </div>
            </div>
        </div>

    )
}