import React from "react";
// import ProductImage from "./productImage";
import Slider from "components/slider/slider";


export default function productImages(props) {
	return (
		<div className="col-span-12 sm:col-span-6 lg:col-span-6">
			<div className="intro-y sm:p-0 lg:p-2 pl-10 pr-10 mt-12 sm:mt-5 mb-10">

				<Slider images = {props.images} />

			</div>
		</div>
	);
}
