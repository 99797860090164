export const EDIT_GET_BUSINESS = "EDIT_GET_BUSINESS"
export const EDIT_GET_BUSINESS_LOADING = "EDIT_GET_BUSINESS_LOADING"
export const EDIT_GET_BUSINESS_ERROR = "EDIT_GET_BUSINESS_LOADING"

export const EDIT_BUSINESS = "EDIT_BUSINESS"
export const EDIT_BUSINESS_LOADING = "EDIT_BUSINESS_LOADING"
export const EDIT_BUSINESS_ERROR = "EDIT_BUSINESS_ERROR"

export const EDIT_BUSINESS_CATEGORY = "EDIT_BUSINESS_CATEGORY"
export const EDIT_BUSINESS_CATEGORY_ERROR = "EDIT_BUSINESS_CATEGORY_ERROR"
export const EDIT_BUSINESS_CATEGORY_LOADING = "EDIT_BUSINESS_CATEGORY_LOADING"

export const EDIT_BUSINESS_IMAGES = "EDIT_BUSINESS_IMAGES"

export const EDIT_BUSINESS_ADD_IMAGE_LOADING = "EDIT_BUSINESS_ADD_IMAGE_LOADING"
export const EDIT_BUSINESS_ADD_IMAGE_ERROR = "EDIT_BUSINESS_ADD_IMAGE_ERROR"

export const EDIT_BUSINESS_DELETE_IMAGE_LOADING = "EDIT_BUSINESS_DELETE_IMAGE_LOADING"
export const EDIT_BUSINESS_DELETE_IMAGE_ERROR = "EDIT_BUSINESS_DELETE_IMAGE_ERROR"